import React from 'react';
import styled, { css } from 'styled-components';
import { Typography, media, sizes } from '../../components';
import { theme } from '../../const';

const PostBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 2rem;

  max-width: 77rem;
  margin: auto;
  padding: 4rem 0 5rem;

  ${media.tablet`
    grid-row-gap: 1.5rem;
    max-width: 52.2rem;
    padding: 3rem 0;
  `}

  ${media.mobile`
    grid-row-gap: 1rem;
    max-width: initial;
    padding: 2rem 0 3rem;
  `}
`;

const PostTitle = styled(Typography)`
  margin-bottom: 1rem;

  @media (max-width: ${sizes.tablet}px) {
    margin-bottom: 0;

    font-size: ${theme.font.size20};
    font-weight: ${theme.font.weightBold};
    line-height: ${theme.font.lineHeight24};
  }

  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size18};
    line-height: ${theme.font.lineHeight22};
  }
`;

const PostContent = styled(Typography)`
  & > a {
    color: ${theme.colors.blue};
    font-weight: ${theme.font.weightBold};
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${media.mobile`
    ${(props) =>
      props.isMoreContent &&
      css`
        margin-top: 0.5rem;
      `}
  `}
`;

const PostImg = styled.img`
  width: 100%;
  margin: 1rem 0;

  ${media.tablet`
    margin: 0;
  `}
`;

const PostItems = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 1rem;
  margin-top: -1rem;

  ${media.tablet`
    margin-top: -0.5rem;
  `}

  ${media.mobile`
    grid-row-gap: 0.5rem;
    margin: 0;
  `}
`;

export const Post = ({ POST = [] }) => {
  return (
    <PostBase>
      {POST.map(({ title, content, img, items }, i) => (
        <React.Fragment key={i}>
          {title && (
            <PostTitle variant={'40/48'} weight={theme.font.weight600}>
              {title}
            </PostTitle>
          )}
          {content && (
            <PostContent
              variant={'20/28'}
              isMoreContent={i !== 0 && POST[i - 1].content}
            >
              {content}
            </PostContent>
          )}
          {img && <PostImg src={img} alt={'Post'} />}
          {items && (
            <PostItems>
              {items.map((item, i) => (
                <React.Fragment key={i + 'item'}>
                  <Typography variant={'20/28'} listItem>
                    {item}
                  </Typography>
                </React.Fragment>
              ))}
            </PostItems>
          )}
        </React.Fragment>
      ))}
    </PostBase>
  );
};
