import styled from 'styled-components';
import { Typography } from '../../../components';

const AdvancedSubcontentBase = styled(Typography)`
  justify-self: left;
  margin-top: 2rem;
`;

export const AdvancedSubcontent = ({ SUBCONTENT }) => {
  if (!SUBCONTENT) return null;

  return (
    <AdvancedSubcontentBase variant={'20/28'} required>
      {SUBCONTENT}
    </AdvancedSubcontentBase>
  );
};
