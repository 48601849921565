import { useCallback } from 'react';
import ReactDOM from 'react-dom';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import styled from 'styled-components';

const ImageViewerBase = styled.div`
  display: grid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.7);
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s;
  z-index: 9900;

  &[data-active='true'] {
    pointer-events: all;
    opacity: 1;
  }
`;

const FullScreenImage = styled.div`
  width: 100vw;
  height: 100vh;

  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
`;

const imageViewerEl = () => document.getElementById('image-viewer');
const fullScreenImageEl = () => document.getElementById('full-screen-image');

export const fullScreenImg = (img) => {
  fullScreenImageEl().style.backgroundImage = `url(${img})`;
  imageViewerEl().dataset.active = true;
};

export const ImageViewer = () => {
  const handleOnClick = useCallback((resetTransform) => {
    imageViewerEl().dataset.active = false;
    resetTransform();
  }, []);

  return ReactDOM.createPortal(
    <ImageViewerBase id={'image-viewer'} data-active={false}>
      <TransformWrapper pos>
        {({ resetTransform }) => (
          <TransformComponent>
            <FullScreenImage
              id={'full-screen-image'}
              onClick={() => handleOnClick(resetTransform)}
            />
          </TransformComponent>
        )}
      </TransformWrapper>
    </ImageViewerBase>,
    document.body
  );
};
