import styled, { css } from 'styled-components';
import { theme } from '../../const';

const containedButtonCSS = css`
  &[data-variant='contained'] {
    &[data-color='blue'] {
      color: ${theme.colors.white};
      background-color: ${theme.colors.blue};
      box-shadow: ${theme.shadows[2]};

      &:hover,
      &[data-active='true'] {
        box-shadow: ${theme.shadows[3]};
      }
    }
  }
`;

const outlinedButtonCSS = css`
  &[data-variant='outlined'] {
    &[data-color='blue'] {
      color: ${theme.colors.blue};
      border: 0.2rem solid ${theme.colors.blue};

      &:hover,
      &[data-active='true'] {
        color: ${theme.colors.white};
        background-color: ${theme.colors.blue};
      }
    }
  }
`;

const textButtonCSS = css`
  &[data-variant='text'] {
    &[data-color='gray'] {
      color: ${theme.colors.gray};

      &:hover,
      &[data-active='true'] {
        color: ${theme.colors.blue};
        background-color: ${theme.colors.blue200};
        font-weight: ${theme.font.weightBold};
      }
    }

    &[data-color='gray-2'] {
      color: ${theme.colors.gray};

      &:hover,
      &[data-active='true'] {
        color: ${theme.colors.blue};
      }
    }
  }
`;

export const ButtonBaseCSS = css`
  display: grid;
  align-items: center;
  justify-content: center;

  transition: color 0.3s, background-color 0.3s, box-shadow 0.3s, border 0.3s;
  user-select: none;
  cursor: pointer;

  ${containedButtonCSS}
  ${outlinedButtonCSS}
  ${textButtonCSS}

  ${(props) =>
    props.size === 'medium' &&
    css`
      height: 4rem;
      padding: 0 3.3rem;
      font-size: ${theme.font.size14};
      font-weight: ${theme.font.weight600};
      line-height: ${theme.font.lineHeight16};
      letter-spacing: ${theme.font.letterSpacing007};

      &:not([data-variant='text']) {
        border-radius: 0.5rem;
      }
    `}

  ${(props) =>
    props.size === 'big' &&
    css`
      padding: 0 3rem;
      font-size: ${theme.font.size16};
      font-weight: ${theme.font.weight600};
      line-height: ${theme.font.lineHeight20};
      letter-spacing: ${theme.font.letterSpacing008};
    `}
    

  &[data-only-text="true"] {
    padding: 0;
    background-color: transparent;
  }

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      cursor: none;
    `}
`;

const ButtonBase = styled.div`
  ${ButtonBaseCSS}
`;

export const Button = ({
  variant = 'contained',
  color = 'blue',
  size = 'medium',
  isActive,
  disabled,
  onlyText,
  children,
  ...props
}) => {
  return (
    <ButtonBase
      data-variant={variant}
      data-color={color}
      size={size}
      data-active={isActive}
      disabled={disabled}
      data-only-text={onlyText}
      {...props}
    >
      {children}
    </ButtonBase>
  );
};
