import styled, { css } from 'styled-components';
import { ToolBox } from '../../components';
import { Typography, media } from '../../../components';
import { theme } from '../../../const';

const ConstraintsBase = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;

  ${media.tablet`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, max-content);
    grid-row-gap: 2rem;

    ${(props) =>
      props.page === 'personal-website' &&
      css`
        & > *:nth-child(n + 3) {
          margin-top: 1rem;
        }
      `}
  `}

  ${media.mobile`
    ${(props) =>
      props.page === 'personal-website' &&
      css`
        & > *:nth-child(n + 3) {
          margin-top: 0;
        }
      `}
  `}
`;

const ConstraintsTitle = styled(Typography)`
  grid-column: 1 / 4;

  ${media.tablet`
    grid-column: 1;
    margin-bottom: 1rem;
  `}

  ${media.mobile`
    margin-bottom: 0rem;
  `}
`;

export const Constraints = ({ PAGE, TITLE, TOOLS }) => {
  return (
    <ConstraintsBase page={PAGE}>
      <ConstraintsTitle
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </ConstraintsTitle>
      {TOOLS.map(({ iconName, iconColor, title, content }, i) => (
        <ToolBox
          key={i}
          iconName={iconName}
          iconColor={iconColor}
          title={title}
          content={content}
          tabletColumnMode
        />
      ))}
    </ConstraintsBase>
  );
};
