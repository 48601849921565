import { windowResizeChanger } from '../utils';
import { theme } from '.';
import { importImg } from '../images';

export const CUSTOMERAPP = () => ({
  PAGE: 'customer-app',
  PATHS: [
    { path: '/portfolio', text: 'Portfolio' },
    { path: '/portfolio/customerapp', text: 'Wing Customer app redesign' },
  ],
  HEADER: {
    TITLE: 'Wing Customer app redesign',
    SUBTITLE:
      'Strived to find the best solution that helps Wing enhance the usability of its delivery management system, based on the new business model.',
    IMAGES: [importImg('CustomerAppHeader.jpg')],
  },
  OVERVIEW: {
    TITLE: 'Overview',
    PROJECT_TIMELINE: 'Project Timeline',
    YEARS: windowResizeChanger({
      desktop: 'May 2019 - July 2019',
      tablet: (
        <>
          Start: <b>May 2019</b>
          <br />
          Finish: <b>July 2019</b>
        </>
      ),
      mobile: 'May 2019 - July 2019',
    }),
    MY_ROLE_TITLE: 'My role',
    MY_ROLE: 'UX designer\nVisual designer',
    CONTEXT_TITLE: 'Context',
    CONTEXT1:
      'In 2019, the company business model was changed. Before we provided delivery service to customers in partnership with multip courier companies. Then we started selling the company system to courier companies.',
    CONTEXT2:
      'This change resulted in a high level of customization for each customer. The customization work affected our customer’s performance that resulted in frustration and disappointment from their side. I strived to solve this problem by working closely with the development, support, and marketing teams.',
    CONTEXT3:
      'I designed a new concept of the business model and updated our customer app based on that. I designed a clickable prototype and shared it with the leadership team.',
  },
  PROBLEM: {
    SECTION: 'problem',
    SET_BACKGROUND: true,
    TITLE: 'Problem',
    TOOLS: [
      {
        column: windowResizeChanger({ desktop: '1fr 1fr', mobile: '1fr' }),
        tools: [
          {
            iconName: 'icon-one-box',
            iconColor: theme.colors.red,
            title: 'Too much customization',
            content:
              'Although the system had a huge number of features, they were not organized and packaged based on the customer requirement resulting in a huge amount of customization work for each new client.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-two-box',
            iconColor: theme.colors.red,
            title: 'Delay during system set up',
            content:
              'Because of the huge amount of customization work, the system setup is delayed for days or even for weeks for some customers. The customers who requested white-labeling waited even longer.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
        ],
      },
    ],
  },
  CONSTRAINTS: {
    TITLE: 'Constraints',
    TOOLS: [
      {
        iconName: 'icon-search-box',
        iconColor: theme.colors.green,
        title: 'Limited research',
        content:
          'I wanted to take some to learn more about the issues. But I had to rely on stakeholder interviews only. Especially, talking to the support helped a lot in this project.',
      },
      {
        iconName: 'icon-tv-box',
        iconColor: theme.colors.red,
        title: 'Consider the whole system',
        content:
          'Even though I was working on the app, I had to consider the whole system of the company. I was supposed to redesign the whole system after the customer app.',
      },
      {
        iconName: 'icon-love-box',
        iconColor: theme.colors.blue,
        title: 'Hard to empathize',
        content:
          'Without doing proper research and learning about the customer on a deeper level, it was hard to truly understand their expectations and needs.',
      },
    ],
  },
  AUDIENCE: {
    TITLE: 'Target audience',
    PERSONS: [
      {
        avatar: importImg('CustomerAppMuhammad.jpg'),
        name: 'Muhammad',
        subname: windowResizeChanger({
          desktop: 'Courier company owner | United Arab Emirates',
          mobile: 'Courier company owner\nUnited Arab Emirates',
        }),
        content:
          'He runs a courier company and they do around 100 deliveries a day. It was easy to do 10 deliveries a day with phone calls in the beginning, but when the business started going bigger, he faced difficulties. He wants to automate the business but he has no intention to invest a big amount of money in developing a system. He is looking for ready-to-use solutions.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Wants to manage his business with a delivery management system.',
            'He needs a ready-to-use solution, he does not want to develop his own.',
            'He wants a simple, cheap, and reliable system that could handle the shipment amounts they have.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'Tired of doing things with phone calls.',
            'Frustrated because his business is increasing but he is not capable of managing it with old methods.',
          ],
        },
      },
      {
        avatar: importImg('CustomerAppJonathan.jpg'),
        name: 'Jonathan',
        subname: 'Entrepreneur | United Arab Emirates',
        content:
          'Jonathan runs a small online shop on Instagram. He sells custom-designed sneakers. Daily he needs to deliver around 3-5 sneakers to his buyers. He is looking for a reliable, fast, and trusted courier company. He tried a couple of couriers in the past, but they failed his expectations. They delivered late, a couple of times the sneaker boxes were damaged and etc.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Wants to do business with a reliable courier company.',
            'Needs to be sure that the company delivers the shipments on time and without any damages.',
            'Wants to be able to afford service, if available he wants to have options of delivery types based on time and money.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'Frustrated because of unreliable courier companies.',
            'Disappointed because several times he had to return sold items as they were delivered with damaged boxes.',
          ],
        },
      },
    ],
  },
  PROCESS: {
    TITLE: windowResizeChanger({
      desktop: 'Process',
      tablet: 'Design Process',
    }),
    TOOLS: [
      {
        iconName: 'icon-search-box',
        iconColor: theme.colors.green,
        title: 'Research',
        content:
          'I ran stakeholder interviews with the team leaders & members.',
      },
      {
        iconName: 'icon-lamp-box',
        iconColor: theme.colors.red,
        title: 'Brainstorm',
        content:
          'I brainstormed to discover the ideal solutions to the issues.',
      },
      {
        iconName: 'icon-design-box',
        iconColor: theme.colors.blue,
        title: 'Design',
        content: 'I designed a high-fi mockup with prototype.',
      },
      {
        iconName: 'icon-test-box',
        iconColor: theme.colors.yellow,
        title: 'Approval',
        content:
          'I constantly shared my designs with the team to get feedback.',
      },
    ],
  },
  RESEARCH: {
    SECTION: 'research',
    SET_BACKGROUND: true,
    TITLE: 'Research',
    CONTENTS: [
      'I ran stakeholder interviews with different members of the team. First, I wanted to know what were issues our customers were facing. So I approached the support team. Besides, I wanted to know what difficulties the development team was facing and I talked to the developers as well. In addition, to find out the business needs I talked to the product manager.',
    ],
    TOOLS: [
      { column: '1fr', title: 'Feedback from the support team.' },
      {
        column: windowResizeChanger({ desktop: '1fr 1fr', mobile: '1fr' }),
        tools: [
          {
            iconName: 'icon-one-box',
            iconColor: theme.colors.red,
            content:
              'Delivery companies expressed frustration because of setup delays. Besides they complained about the difficulty of the system once they started using it. They informed us there were features that they didn’t need.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-two-box',
            iconColor: theme.colors.red,
            content:
              'Some of our daily customers were having a hard time trying to track their orders. They could not understand how to track their shipment through our app and had to rely on our website to do that.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
        ],
      },
      { column: '1fr', title: 'Feedback from the development team.' },
      {
        column: windowResizeChanger({ desktop: '1fr 1fr', mobile: '1fr' }),
        tools: [
          {
            iconName: 'icon-three-box',
            iconColor: theme.colors.red,
            content:
              'Developers had to custom set up the system each for a new customer. Because the current model is dedicated to shipment delivery only. They had to customize it for different types of delivery services.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-four-box',
            iconColor: theme.colors.red,
            content:
              'The amount of work varied based on the delivery type provided by the company. The delay will be short for a courier company. But if the system was customized for a food delivery company, the delay will be a lot longer.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
        ],
      },
      { column: '1fr', title: 'Feedback from the development team.' },
      {
        column: windowResizeChanger({ desktop: '1fr 1fr', mobile: '1fr' }),
        tools: [
          {
            iconName: 'icon-five-box',
            iconColor: theme.colors.red,
            content:
              'I was supposed to design the app in a way that its model should be very close to the system’s model. The solution I find should be applied to the system as well.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-six-box',
            iconColor: theme.colors.red,
            content:
              'There won’t be a location limit in the future. The new system is planned to be sold worldwide so I should that fact into consideration as well.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-seven-box',
            iconColor: theme.colors.red,
            content:
              'The system should be able to handle any type of delivery service. The solution I find should satisfy the needs of shipment, food, and any other delivery companies.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-eight-box',
            iconColor: theme.colors.red,
            content:
              'The solutions I find should put minimal pressure on the developers. The team was busy with the increasing amount of clients, so I should try to find a solution that required minimal effort from the developers in the team.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
        ],
      },
    ],
  },
  BRAINSTORMING: {
    SECTION: 'brainstorming',
    TITLE: 'Brainstorming',
    CONTENTS: [
      'I brainstormed on the issues to find solutions. My colleagues joined me in the sessions, especially the marketing manager participated very actively and gave a couple of very good suggestions:',
    ],
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr 1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.green,
              title: 'Separate flow for each delivery type',
              content: (
                <>
                  <span>
                    In the current model, local and international deliveries are
                    done in the same flow. The app defines delivery type based
                    on the address provided by the users.
                  </span>
                  <span>
                    I decided to separate local delivery from international
                    delivery and make them two separate flows. This helps us to
                    avoid a lot of customization work in the future as each
                    delivery type will have a separate flow.
                  </span>
                </>
              ),
              tabletRowMode: true,
            },
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.green,
              title: 'Add tracking feature',
              content: (
                <>
                  <span>
                    Tracking shipments was possible only on our website which
                    pushed our app users towards the website resulted
                    in frustration among the users.
                  </span>
                  <span>
                    We decided it would be better to have a tracking feature
                    inside the app. The feature allows the users to track any
                    shipments by typing in the shipment ID.
                  </span>
                </>
              ),
              tabletRowMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr',
          tools: [
            {
              iconName: 'icon-three-box',
              iconColor: theme.colors.green,
              title: 'Add Price calculator feature',
              content: (
                <>
                  <span>
                    The price Calculator feature was also available only on our
                    website. The users were forced to visit the website if they
                    wanted to see the prices before making orders.
                  </span>
                  <span>
                    We decided to add this feature to the app. Besides, based on
                    the information provided to the prices, the users can
                    continue to make an order if they wish by just clicking a
                    button, without starting the process over.
                  </span>
                </>
              ),
              tabletRowMode: true,
            },
            {
              iconName: 'icon-four-box',
              iconColor: theme.colors.green,
              title: 'Sign up not required for new users unless…',
              content: (
                <>
                  <span>
                    The current app asks users to sign up when they start using
                    the app.
                  </span>
                  <span>
                    Based on marketing purposes, we decided to move the sign-up
                    process in the order creating flow. If users do not wish to
                    make any orders, they still can use the app for checking
                    prices or tracking orders.
                  </span>
                </>
              ),
              tabletRowMode: true,
            },
          ],
        },
      ],
      mobile: [
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.green,
              title: 'Separate flow for each delivery type',
              content: (
                <>
                  <span>
                    In the current model, local and international deliveries are
                    done in the same flow. The app defines delivery type based
                    on the address provided by the users.
                  </span>
                  <span>
                    I decided to separate local delivery from international
                    delivery and make them two separate flows. This helps us to
                    avoid a lot of customization work in the future as each
                    delivery type will have a separate flow.
                  </span>
                </>
              ),
              tabletRowMode: true,
              mobileColumnMode: true,
            },
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.green,
              title: 'Add tracking feature',
              content: (
                <>
                  <span>
                    Tracking shipments was possible only on our website which
                    pushed our app users towards the website resulted
                    in frustration among the users.
                  </span>
                  <span>
                    We decided it would be better to have a tracking feature
                    inside the app. The feature allows the users to track any
                    shipments by typing in the shipment ID.
                  </span>
                </>
              ),
              tabletRowMode: true,
              mobileColumnMode: true,
            },
            {
              iconName: 'icon-three-box',
              iconColor: theme.colors.green,
              title: 'Add Price calculator feature',
              content: (
                <>
                  <span>
                    The price Calculator feature was also available only on our
                    website. The users were forced to visit the website if they
                    wanted to see the prices before making orders.
                  </span>
                  <span>
                    We decided to add this feature to the app. Besides, based on
                    the information provided to the prices, the users can
                    continue to make an order if they wish by just clicking a
                    button, without starting the process over.
                  </span>
                </>
              ),
              tabletRowMode: true,
              mobileColumnMode: true,
            },
            {
              iconName: 'icon-four-box',
              iconColor: theme.colors.green,
              title: 'Sign up not required for new users unless…',
              content: (
                <>
                  <span>
                    The current app asks users to sign up when they start using
                    the app.
                  </span>
                  <span>
                    Based on marketing purposes, we decided to move the sign-up
                    process in the order creating flow. If users do not wish to
                    make any orders, they still can use the app for checking
                    prices or tracking orders.
                  </span>
                </>
              ),
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
      ],
    }),
  },
  DESIGN: {
    SECTION: 'design',
    SET_BACKGROUND: true,
    TITLE: 'Design',
    CONTENTS_ALIGN: 'center',
    CONTENTS: [
      'After we made up our mind about the solutions, I designed a hi-fi mockup to demonstrate them visually.',
    ],
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr 1fr 1fr 1fr',
          tools: [
            {
              content:
                'Separating deliveries into separate flows rescues developers from additional work. We planned that we would package delivery types and add them to the home page as a separate flow.',
            },
            {
              content: 'Users can get quotes before making an order.',
            },
            {
              content:
                'Users can track any shipment by just typing in the shipment ID and getting all the needed info.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('CustomerAppPhone1.jpg'),
            importImg('CustomerAppPhone2.jpg'),
            importImg('CustomerAppPhone3.jpg'),
            importImg('CustomerAppPhone4.jpg'),
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          tools: [
            {
              content:
                'Just indicate from city to country, shipment weight, and unit type to get a quote.',
            },
            {
              content:
                'Select any courier service that satisfies your needs and click on the book button',
            },
            {
              content:
                'Half is done, now provide the exact addresses. That is it, ordering is very easy now.',
            },
            {
              content: 'Type in or select the address on the map.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('CustomerAppPhone5.jpg'),
            importImg('CustomerAppPhone6.jpg'),
            importImg('CustomerAppPhone7.jpg'),
            importImg('CustomerAppPhone8.jpg'),
          ],
        },
        {
          column: '1fr 1fr',
          tools: [
            {
              content:
                'I joined Service type and couriers into one step which were two separate steps before. The reason was simple, to make the flow shorter and decrease the time on task.',
            },
            {
              content:
                'Users are asked to sign in or sign up when they submit their orders. When they provide information while creating orders, we register it to make the sign-up process easier and faster.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('CustomerAppPhone9.jpg'),
            importImg('CustomerAppPhone10.jpg'),
            importImg('CustomerAppPhone11.jpg'),
            importImg('CustomerAppPhone12.jpg'),
          ],
        },
      ],
      mobile: [
        {
          column: '1fr',
          tools: [
            {
              content:
                'Separating deliveries into separate flows rescues developers from additional work. We planned that we would package delivery types and add them to the home page as a separate flow.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('CustomerAppPhone1.jpg'),
            importImg('CustomerAppPhone2.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content: 'Users can get quotes before making an order.',
            },
            {
              content:
                'Users can track any shipment by just typing in the shipment ID and getting all the needed info.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('CustomerAppPhone3.jpg'),
            importImg('CustomerAppPhone4.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'Just indicate from city to country, shipment weight, and unit type to get a quote.',
            },
            {
              content:
                'Select any courier service that satisfies your needs and click on the book button',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('CustomerAppPhone5.jpg'),
            importImg('CustomerAppPhone6.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'Half is done, now provide the exact addresses. That is it, ordering is very easy now.',
            },
            {
              content: 'Type in or select the address on the map.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('CustomerAppPhone7.jpg'),
            importImg('CustomerAppPhone8.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'I joined Service type and couriers into one step which were two separate steps before. The reason was simple, to make the flow shorter and decrease the time on task.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('CustomerAppPhone9.jpg'),
            importImg('CustomerAppPhone10.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'Users are asked to sign in or sign up when they submit their orders. When they provide information while creating orders, we register it to make the sign-up process easier and faster.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('CustomerAppPhone11.jpg'),
            importImg('CustomerAppPhone12.jpg'),
          ],
        },
      ],
    }),
  },
  TEST: {
    SECTION: 'test',
    TITLE: 'Test',
    CONTENTS: [
      'I tried to share my designs with the team to get feedback while I was designing the app. It helped me to avoid big mistakes and fix issues on the go.',
      'I hoped to test the new app with our customers, but later the whole project was shut down. I didn’t have a chance to test the app with real users.',
    ],
    CONTENTS_VARIANT: '24/28',
  },
  FINALLOOK: {
    TITLE: 'The final look',
    IMG: importImg('CustomerAppFinalLook.jpg'),
  },
  EXPERIENCE: {
    TITLE: windowResizeChanger({
      desktop: 'The experience:',
      tablet: 'Benefits from this experience:',
    }),
    TOOLS: [
      {
        iconName: 'icon-one-box',
        title: 'The system redesign',
        content:
          'The new concept was very welcomed by the team. They liked the concept and wanted to start redesigning the system based on this concept.',
      },
      {
        iconName: 'icon-two-box',
        title: 'Understanding business requirements',
        content:
          'This project helped to look at things from a business perspective. Business requirements limited my creativity a lot but taught me to look deeper into issues.',
      },
      {
        iconName: 'icon-three-box',
        title: 'Opportunities within challenges',
        content:
          'I faced a lot of constraints during the design process. I was supposed to consider the whole system, also different customers and their needs. Finding an optimal solution was difficult but the result was rewarding.',
      },
      {
        iconName: 'icon-four-box',
        title: 'Expectations',
        content:
          'I hoped that this project opens up a challenging direction for our company. As we were supposed to work on the app and adjust it to satisfy the needs of other delivery companies, especially food delivery companies.',
      },
    ],
    NOTE: (
      <>
        <span>Note</span>
        <span>: </span>soon after I finished the app, it was announced that Wing
        will be shut down by Amazon. Even though this concept was welcomed by
        the team, it never ended up being implemented. Wing still exists outside
        Amazon, with the name Shipox, but they have not implemented the new
        version as well.
      </>
    ),
  },
});
