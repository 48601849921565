import { windowResizeChanger } from '../utils';
import { theme } from '.';
import { importImg } from '../images';

export const MEDYEARPRO = () => ({
  PAGE: 'medyear-pro',
  PATHS: [
    { path: '/portfolio', text: 'Portfolio' },
    { path: '/portfolio/medyearpro', text: 'Medyear Pro web system redesign' },
  ],
  HEADER: {
    TITLE: 'Medyear Pro web system redesign',
    SUBTITLE:
      'Redesigned the web system for healthcare professionals to make the interaction with their patients easy, simple, and more effective.',
    IMAGES: [
      importImg('MedyearProHeader1.jpg'),
      importImg('MedyearProHeader2.jpg'),
    ],
  },
  OVERVIEW: {
    TITLE: 'Overview',
    PROJECT_TIMELINE: 'Project Timeline',
    YEARS: windowResizeChanger({
      desktop: 'May 2020 - Aug 2020',
      tablet: (
        <>
          Start: <b>May 2020</b>
          <br />
          Finish: <b>Aug 2020</b>
        </>
      ),
      mobile: 'May 2020 - Aug 2020',
    }),
    MY_ROLE_TITLE: 'My role',
    MY_ROLE: 'UX designer\nVisual designer',
    CONTEXT_TITLE: 'Context',
    CONTEXT1:
      'Medyear Pro is a web based system that helps healthcare professionals manage digital interactions between them and their patients. The system has been in the market for more than 4 years and was struggling to meet its end users because of ongoing updates.',
    CONTEXT2:
      'I redesigned the Medyear brand and its assets, so Medyear Pro was required to be updated based on the new brand guidelines. However, aftar through analyses of the system and its structre we decided to update the whole system, not just the look.',
    CONTEXT3:
      'I redesigned the system structure and the layout while working closely iwth UX manager, the co-founder, engeneering manager and the developers.',
  },
  PROBLEM: {
    TITLE: 'Problem',
    SUBTITLE:
      'The system navigation was designed to handle 3-4 main sections. We could not add new sections to the navigation because there was not enough space. If we tried to add new sections under the current ones as subsections, we would cause confusion that would result in very bad usability.',
  },
  CONSTRAINTS: {
    TITLE: 'Constraints',
    TOOLS: [
      {
        iconName: 'icon-search-box',
        iconColor: theme.colors.green,
        title: 'Limited research',
        content:
          'I could not run proper UX techniques such as user interviews, usability testing and etc. to get insights about the system. I had to rely on stakeholder interviews only.',
      },
      {
        iconName: 'icon-love-box',
        iconColor: theme.colors.red,
        title: 'Hard to empathize',
        content:
          'It was hard to really understand how healthcare professionals could use the system in their context. I tried to observe my day while working as he is a doctor.',
      },
      {
        iconName: 'icon-concentrate-box',
        iconColor: theme.colors.blue,
        title: 'Hard to concentrate',
        content:
          'I became a dad while working on the project. It was my first baby, so it was really hard concentrate on the work working remotely with a newbord baby around.',
      },
    ],
  },
  AUDIENCE: {
    TITLE: 'Target audience',
    PERSONS: [
      {
        avatar: importImg('MedyearProJason.jpg'),
        name: 'Dr. Jason Brown',
        subname: 'Clinical Immunology | United States',
        content:
          'Dr. Jason is a clinical immunology in Queens hospital, NY. He has a lot of patients who approach him with questions related to Allergies. Talking to people over phone is what he hates the most. He gets lots of calls during the day. He cannot give prescriptions over the phone and he cannot totally understand the situation if he doesn’t see the patient.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Wants to be able to chat with patients through some kind of trusted digital messanger, or system.',
            'He needs to get visual proof of patient’s conditions. Just sending images through whatsapp does not work.',
            'Wants to be able to provide prescriptions digitally if the situation requires that.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'Tired of talking to the patient’s over the phone.',
            'Upset becase people keep asking him for prescriptions over the phone which he does not want to do.',
          ],
        },
      },
      {
        avatar: importImg('MedyearProSamantha.jpg'),
        name: 'Dr. Samantha Aniston',
        subname: 'Physician | United States',
        content:
          'Dr. Samantha is physician in the local hospital. She interacts with lots of patients daily. She needs to see patient’s medical history to be able to diagnose things properly and also be aware of past medical isssues. She gets the hisotry in paper format most of the time. There were several times when she lost the papers in the office.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Wants to be able to exchange document digitally.',
            'She needs to get access to the medical history of her patients whenever she needs.',
            'SWants to have an opportunity to share the documents with her coworker to get their suggestions.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'Upset to lose medical history documents of the patients.',
            'Tired of waiting for the documents to be provided if a patient is coming from other hospitals.',
          ],
        },
      },
    ],
  },
  PROCESS: {
    TITLE: windowResizeChanger({
      desktop: 'Process',
      tablet: 'Design Process',
    }),
    TOOLS: [
      {
        iconName: 'icon-search-box',
        iconColor: theme.colors.green,
        title: 'Research',
        content: 'I ran stakeholder interviews with the team leaders.',
      },
      {
        iconName: 'icon-lamp-box',
        iconColor: theme.colors.red,
        title: 'Brainstorm',
        content: 'I brainstormed to come up with the best solutions.',
      },
      {
        iconName: 'icon-design-box',
        iconColor: theme.colors.blue,
        title: 'Design',
        content: 'I designed a high-fi mockup with prototype.',
      },
      {
        iconName: 'icon-test-box',
        iconColor: theme.colors.yellow,
        title: 'Test',
        content: 'We tested the system design with healthcare professionaals.',
      },
    ],
  },
  RESEARCH: {
    TITLE: 'Research',
    CONTENT:
      'During the discussions about the system, I tried to find as much details as possible. Those details would help me while designing the new update. We decided to do the following:',
    TOOLS: [
      {
        iconName: 'icon-one-box',
        content:
          'We will update the system based on the new company guidelines.',
      },
      {
        iconName: 'icon-two-box',
        content:
          'I need to come up with new navigation system that is simple and easy to understand.',
      },
      {
        iconName: 'icon-three-box',
        content:
          'The system will be developed for web devices only, no need to make it responsive.',
      },
      {
        iconName: 'icon-four-box',
        content:
          'The home section should be a dashboard that shows multiple type of information.',
      },
      {
        iconName: 'icon-five-box',
        content:
          'New sections could be added in future so new navigation should be able to easily handle that.',
      },
      {
        iconName: 'icon-six-box',
        content:
          'The layout should be simple so that healthcare professionals can get used to it very fast.',
      },
      {
        iconName: 'icon-seven-box',
        content:
          'The system should provide different languages and it should be in a visible area.',
      },
    ],
  },
  BRAINSTORMING: {
    SECTION: 'brainstorming',
    TITLE: 'Brainstorming & Design',
    CONTENTS: [
      'Once I received the requirements, I started working on the project. I was busy with other tasks as well, besides I had extra pressure to finish this project sooner. I had to gor for hi-fi prototype directly which was very risky. I had to make decisions on the go and apply them to the end-looking design directly.',
    ],
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr 1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.green,
              title: 'Navigation',
              content:
                'In order to make the main navigation visible and to be able manage the increasing number of sections I decided to use sidebar navigation. It is collapsable that helps healthcare professionals concentrate on the content when it is needed.',
              tabletRowMode: true,
            },
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.green,
              title: 'Grid layout',
              content:
                'Even though the system is dedicated for web devices only, I wanted to address different sizes of those devices. From small laptop screen to huge displays. I believed that the option would be to use grid layout that can adjust to the size of the device.',
              tabletRowMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('MedyearProBrainstorming1.jpg'),
            importImg('MedyearProBrainstorming2.jpg'),
            importImg('MedyearProBrainstorming3.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-three-box',
              iconColor: theme.colors.green,
              title: 'Dashboard',
              content:
                'The dashboard shows preview information of chats, new emails, upcoming video calls, connection requests, patients list and the popular topics posted by other healthcare professionals. Each card has a button that leads to the particular section.',
            },
          ],
        },
        {
          column: '1fr',
          images: [importImg('MedyearProBrainstorming4.jpg')],
        },
        {
          column: '1fr 1fr',
          tools: [
            {
              iconName: 'icon-four-box',
              iconColor: theme.colors.green,
              title: 'Grid layout',
              content:
                'I decided to occupy the right top corner for language optoins. It is very easy to navigate and visible all the time that helps healthcare professionals to switch between different languages is required.',
              tabletRowMode: true,
            },
            {
              iconName: 'icon-five-box',
              iconColor: theme.colors.green,
              title: 'Components',
              content:
                'I tried to use generalised component library in project to avoid a lot of extra work in future. This decision helped me a lot while designed the patient side of the system.',
              tabletRowMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('MedyearProBrainstorming5.jpg'),
            importImg('MedyearProBrainstorming6.jpg'),
          ],
        },
      ],
      mobile: [
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.green,
              title: 'Navigation',
              content:
                'In order to make the main navigation visible and to be able manage the increasing number of sections I decided to use sidebar navigation. It is collapsable that helps healthcare professionals concentrate on the content when it is needed.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('MedyearProBrainstorming1.jpg'),
            importImg('MedyearProBrainstorming2.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.green,
              title: 'Grid layout',
              content:
                'Even though the system is dedicated for web devices only, I wanted to address different sizes of those devices. From small laptop screen to huge displays. I believed that the option would be to use grid layout that can adjust to the size of the device.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr',
          images: [importImg('MedyearProBrainstorming3.jpg')],
        },
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-three-box',
              iconColor: theme.colors.green,
              title: 'Dashboard',
              content:
                'The dashboard shows preview information of chats, new emails, upcoming video calls, connection requests, patients list and the popular topics posted by other healthcare professionals. Each card has a button that leads to the particular section.',
            },
          ],
        },
        {
          column: '1fr',
          images: [importImg('MedyearProBrainstorming4.jpg')],
        },
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-four-box',
              iconColor: theme.colors.green,
              title: 'Grid layout',
              content:
                'I decided to occupy the right top corner for language optoins. It is very easy to navigate and visible all the time that helps healthcare professionals to switch between different languages is required.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr',
          images: [importImg('MedyearProBrainstorming5.jpg')],
        },
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-five-box',
              iconColor: theme.colors.green,
              title: 'Components',
              content:
                'I tried to use generalised component library in project to avoid a lot of extra work in future. This decision helped me a lot while designed the patient side of the system.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr',
          images: [importImg('MedyearProBrainstorming6.jpg')],
        },
      ],
    }),
  },
  TEST: {
    SET_BACKGROUND: true,
    TITLE: 'Test',
    CONTENTS: [
      'I could not run a proper usability testing with the ends, but we managed to get feedback from two healthcare professionals. Overall impression was good but there were things to improve.',
      'I had to share the designs with the team constantly to get feedback and fix issues on the go. It took longer to finish the project, but it compensated the rist I took by desining everything on the go. I was asked by the team to improve the design in these areas:',
    ],
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr 1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.red,
              title: 'Patient details',
              content:
                'A doctor named Paul Fu stated that it would be better if he could see patient gender and age in the patient table on the dashboard. We listened to him and updated the table with requested information piece.',
              tabletRowMode: true,
            },
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.red,
              title: 'No filter button, add View All button',
              content:
                'I was requested to remove Filter icon from the cards as there won’t that feature. Also I was asked to add a “View All” button instead of just cliking on the card.',
              tabletRowMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('MedyearProTest1.jpg'),
            importImg('MedyearProTest2.jpg'),
          ],
        },
      ],
      mobile: [
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.red,
              title: 'Patient details',
              content:
                'A doctor named Paul Fu stated that it would be better if he could see patient gender and age in the patient table on the dashboard. We listened to him and updated the table with requested information piece.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr',
          images: [importImg('MedyearProTest1.jpg')],
        },
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.red,
              title: 'No filter button, add View All button',
              content:
                'I was requested to remove Filter icon from the cards as there won’t that feature. Also I was asked to add a “View All” button instead of just cliking on the card.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr',
          images: [importImg('MedyearProTest2.jpg')],
        },
      ],
    }),
    SUBCONTENT:
      'The system is being updated constantly with new features, removing unnecesssary elements or sections and etc.',
  },
  FINALLOOK: {
    TITLE: 'The final look',
    IMG: importImg('MedyearProFinalLook.jpg'),
  },
  EXPERIENCE: {
    TITLE: windowResizeChanger({
      desktop: 'What I learned from this experience:',
      tablet: 'Benefits from this experience:',
      mobile: 'Benefits from this experience:',
    }),
    TOOLS: [
      {
        iconName: 'icon-one-box',
        title: 'Research is necessary',
        content:
          'I had a very hard time without a proper research. It would make my job a lot easier if I had data from healthcare professionals directly.',
      },
      {
        iconName: 'icon-two-box',
        title: 'Components library',
        content:
          'The library of components I designed for this project saved me days of work during the design process of the patient side of the system.',
      },
      {
        iconName: 'icon-three-box',
        title: 'Observing my doctor dad',
        content:
          'Observing my dad during his work, helped me to understand the work life of healthcare professionals. I designed the system based on the knowledge I collected from those observation sesssions.',
      },
      {
        iconName: 'icon-four-box',
        title: 'Usability testing is important',
        content:
          'I cannot state that the system is very good from UX prespective unless I can test it with healthcare professionals. I would love to test it with my dad at least, but he does not speak English.',
      },
    ],
  },
});
