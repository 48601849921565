import { windowResizeChanger } from '../utils';
import { theme } from '.';
import { importImg } from '../images';

export const DRIVERAPP = () => ({
  PAGE: 'driver-app',
  PATHS: [
    { path: '/portfolio', text: 'Portfolio' },
    { path: '/portfolio/driverapp', text: 'Wing driver app redesign' },
  ],
  HEADER: {
    TITLE: 'Wing driver app redesign',
    SUBTITLE:
      'Reimagined how drivers perform deliveries with a different approach that contributed to a rise in efficiency and a drop in delivery time.',
    IMAGES: [importImg('DriverAppHeader.jpg')],
  },
  OVERVIEW: {
    TITLE: 'Overview',
    PROJECT_TIMELINE: 'Project Timeline',
    YEARS: windowResizeChanger({
      desktop: 'Jan 2019 - Mar 2019',
      tablet: (
        <>
          Start: <b>Jan 2019</b>
          <br />
          Finish: <b>Mar 2019</b>
        </>
      ),
      mobile: 'Jan 2019 - Mar 2019',
    }),
    MY_ROLE_TITLE: 'My role',
    MY_ROLE: 'UX designer\nVisual designer',
    CONTEXT_TITLE: 'Context',
    CONTEXT1:
      'Wing Driver app was developed to help the drivers complete the deliveries fast & efficiently. When we started working in partnership with other courier companies, we noticed issues that had a huge impact on the drivers’ performance.',
    CONTEXT2:
      'At the beginning of 2019, we decided to rethink the way the app works and make changes to improve the drivers’ efficiency, decrease delivery time and have a higher performance rate.',
    CONTEXT3:
      'I redesigned the app working closely with the product manager, operational manager, support, and the development team.',
  },
  PROBLEM: {
    TITLE: 'Problem',
    SUBTITLE:
      'The orders assigned to the drivers were not sorted based on the closest address. This resulted in the drivers doing sorting tasks manually which caused delays in deliveries.',
  },
  CONSTRAINTS: {
    TITLE: 'Constraints',
    TOOLS: [
      {
        iconName: 'icon-search-box',
        iconColor: theme.colors.green,
        title: 'Limited research',
        content:
          'I could not perform research with proper methods like usability testing. I had to rely on feedback from the support team and interviews with the product manager.',
      },
      {
        iconName: 'icon-love-box',
        iconColor: theme.colors.red,
        title: 'Difficult to empathize',
        content:
          'It was difficult to see the pain points our drivers face with the app. Even though I had been observing the drivers for a while, I had never gone out to deliver shipments with them to see how they actually work.',
      },
      {
        iconName: 'icon-calendar-box',
        iconColor: theme.colors.blue,
        title: 'Busy schedule',
        content:
          'During the design process, I have been distracted by a variety of design-related tasks that made it very difficult to concentrate on the problem at hand.',
      },
    ],
  },
  AUDIENCE: {
    TITLE: 'Target audience',
    COLUMN_MODE: true,
    PERSONS: [
      {
        avatar: importImg('DriverAppOsman.jpg'),
        name: 'Osman',
        subname: (
          <>
            <span>Driver at Mara</span>
            <span>United Arab Emirates</span>
          </>
        ),
        content:
          'Osman works for Mara courier company and does around 25 deliveries a day. He gets paid per shipment delivered, so he tries hard to make as many deliveries as he can. He rides a bike and takes up to 8 parcels per round. He spends around 20 minutes sorting shipments in each round. There were times he had to return to a neighborhood because he got the shipment address wrong while sorting.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Wants to deliver more shipments to make more money.',
            'Wants to avoid sorting shipments manually which slows him down every day.',
            'He wants the shipments were sorted out for him without extra effort.',
            'Also wants to have routing done for him so that he does not have to worry about navigating for each delivery.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'He is frustrated to get the addresses wrong and returning to the same neighborhood to complete deliveries.',
            'He is tired of doing sorting things with every shipment he is assigned to deliver on a daily basis.',
          ],
        },
      },
    ],
  },
  PROCESS: {
    TITLE: windowResizeChanger({
      desktop: 'Process',
      tablet: 'Design Process',
    }),
    TOOLS: [
      {
        iconName: 'icon-search-box',
        iconColor: theme.colors.green,
        title: 'Research',
        content:
          'I ran stakeholder interviews with the product manager and the support team manager.',
      },
      {
        iconName: 'icon-lamp-box',
        iconColor: theme.colors.red,
        title: 'Brainstorm',
        content: 'We brainstormed with the team to find the best solutions.',
      },
      {
        iconName: 'icon-design-box',
        iconColor: theme.colors.blue,
        title: 'Design',
        content: 'I designed a high-fi mockup with prototype.',
      },
      {
        iconName: 'icon-test-box',
        iconColor: theme.colors.yellow,
        title: 'Test',
        content: 'I tested the app with the drivers to get feedback.',
      },
    ],
  },
  RESEARCH: {
    SECTION: 'research',
    SET_BACKGROUND: true,
    TITLE: 'Research',
    CONTENTS: [
      'In order to learn about the issues of the driver app, I approached the support team manager who had been working closely with the courier companies and with their drivers.',
      'Besides, I also talked to the operational manager who spent most of his time in our warehouse with our drivers.',
      'Moreover, my product manager helped me a lot to understand the core problems and the purposes of the business related to the app.',
    ],
    TOOLS: [
      {
        column: '1fr',
        contents: windowResizeChanger({
          desktop: 'Feedback from the support team.',
          tablet: 'I made a list of main issues related to the driver app:',
        }),
      },
      {
        column: windowResizeChanger({ desktop: '1fr 1fr', mobile: '1fr' }),
        tools: [
          {
            iconName: 'icon-one-box',
            iconColor: theme.colors.red,
            title: 'Sorting',
            content:
              'The drivers need to sort the shipments before going out for deliveries. They need to make a list of neighborhoods to go to and decide the shortest routes to take.',
          },
          {
            iconName: 'icon-two-box',
            iconColor: theme.colors.red,
            title: 'Transfer orders',
            content:
              'Think about the possibility of transferring orders from one driver to another one in case of emergency. The app should handle the situations when they arise.',
          },
        ],
      },
      {
        column: windowResizeChanger({ desktop: '1fr 1fr', mobile: '1fr' }),
        tools: [
          {
            iconName: 'icon-three-box',
            iconColor: theme.colors.red,
            title: 'Calling the customer asking for location',
            content:
              'We identified that the drivers usually call the customers and ask them to send their location through WhatsApp. This resulted in frustrated customers raising complaints.',
          },
          {
            iconName: 'icon-four-box',
            iconColor: theme.colors.red,
            title: 'Performance dashboard',
            content:
              'It was required to design a dashboard that would provide information related to the driver’s performance in a specific period of time.',
          },
        ],
      },
    ],
  },
  BRAINSTORMING: {
    SECTION: 'brainstorming',
    TITLE: 'Brainstorming',
    CONTENTS: [
      'I with the operational and the product managers brainstormed the solutions to tackle the issues listed above. We came up with these solutions to make the app easier, more efficient, and user-friendly.',
    ],
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr 1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.green,
              title: 'Shipments sorted based on neighborhood',
              content:
                'The shipments will be sorted out in the system based on the addresses (neighborhoods). The system automatically calculates the road and defines the closest neighborhood to perform the deliveries.',
              tabletRowMode: true,
            },
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.green,
              title: 'Transfer orders',
              content:
                'We created a new flow for transferring orders. Drivers can receive transfer requests under the Incoming tab and create a transfer request under the Outgoing tab.',
              tabletRowMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('DriverAppPhone1.jpg'),
            importImg('DriverAppPhone2.jpg'),
            importImg('DriverAppPhone3.jpg'),
            importImg('DriverAppPhone4.jpg'),
          ],
        },
        {
          column: '1fr 1fr',
          tools: [
            {
              iconName: 'icon-three-box',
              iconColor: theme.colors.green,
              title: 'Calling the customer asking for location',
              content:
                'We decided to hide the customer phone number in the order details page and put a “Request location update” button that helps the drivers to get the updated location if the current location does not work.',
              tabletRowMode: true,
            },
            {
              iconName: 'icon-four-box',
              iconColor: theme.colors.green,
              title: 'Performance dashboard',
              content:
                'We agreed to design a dashboard that showed the Overall success rate of the driver, the Total shipments he has delivered, the Pending CODs, his rating in his company, and Wing’s leaderboard.',
              tabletRowMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('DriverAppPhone5.jpg'),
            importImg('DriverAppPhone6.jpg'),
            importImg('DriverAppPhone7.jpg'),
            importImg('DriverAppPhone8.jpg'),
          ],
        },
      ],
      mobile: [
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.green,
              title: 'Shipments sorted based on neighborhood',
              content:
                'The shipments will be sorted out in the system based on the addresses (neighborhoods). The system automatically calculates the road and defines the closest neighborhood to perform the deliveries.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone1.jpg'),
            importImg('DriverAppPhone2.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.green,
              title: 'Transfer orders',
              content:
                'We created a new flow for transferring orders. Drivers can receive transfer requests under the Incoming tab and create a transfer request under the Outgoing tab.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone3.jpg'),
            importImg('DriverAppPhone4.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-three-box',
              iconColor: theme.colors.green,
              title: 'Calling the customer asking for location',
              content:
                'We decided to hide the customer phone number in the order details page and put a “Request location update” button that helps the drivers to get the updated location if the current location does not work.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone5.jpg'),
            importImg('DriverAppPhone6.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              iconName: 'icon-four-box',
              iconColor: theme.colors.green,
              title: 'Performance dashboard',
              content:
                'We agreed to design a dashboard that showed the Overall success rate of the driver, the Total shipments he has delivered, the Pending CODs, his rating in his company, and Wing’s leaderboard.',
              tabletRowMode: true,
              mobileColumnMode: true,
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone7.jpg'),
            importImg('DriverAppPhone8.jpg'),
          ],
        },
      ],
    }),
  },
  DESIGN: {
    SECTION: 'design',
    SET_BACKGROUND: true,
    TITLE: 'Design',
    CONTENTS: [
      'After we made up our minds about the solutions, I designed a hi-fi mockup to demonstrate them visually.',
    ],
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr 1fr',
          tools: [
            {
              content:
                'Shipments are sorted out based on their neighborhood address. They click on the card to see the details or they can start the delivery by just clicking on the start button.',
            },
            {
              content:
                'In order to navigate to the delivery address, the drivers need to click on the Navigate button. If the address is incorrect, they can click on the request location update button to get the updated address.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('DriverAppPhone9.jpg'),
            importImg('DriverAppPhone10.jpg'),
            importImg('DriverAppPhone11.jpg'),
            importImg('DriverAppPhone12.jpg'),
          ],
        },
        {
          column: '1fr 1fr',
          tools: [
            {
              content:
                'The drivers have access to the dashboard where they check their performance which consists of multiple data figures. Besides, they can the amount of assigned shipment and completed deliveries.',
            },
            {
              content:
                'To see transferring orders, they have to go inside the Transfer orders page. Under the Incoming tab, they can see transfer requests from other drivers and on the details page, they cancel or accept.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('DriverAppPhone13.jpg'),
            importImg('DriverAppPhone14.jpg'),
            importImg('DriverAppPhone15.jpg'),
            importImg('DriverAppPhone16.jpg'),
          ],
        },
        {
          column: '1fr 1fr',
          tools: [
            {
              content:
                'To create a new transfer, the drivers need to go to the Outgoing tab and click on the button “Create new transfer” button to create a new transfer request.',
            },
            {
              content:
                'They have to scen the shipments that need to be transferred and after that choose the driver they want to transfer the orders to. Once they chose the driver, they will have to click on the transfer button.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('DriverAppPhone17.jpg'),
            importImg('DriverAppPhone18.jpg'),
            importImg('DriverAppPhone19.jpg'),
            importImg('DriverAppPhone20.jpg'),
          ],
        },
      ],
      mobile: [
        {
          column: '1fr',
          tools: [
            {
              content:
                'Shipments are sorted out based on their neighborhood address. They click on the card to see the details or they can start the delivery by just clicking on the start button.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone9.jpg'),
            importImg('DriverAppPhone10.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'In order to navigate to the delivery address, the drivers need to click on the Navigate button. If the address is incorrect, they can click on the request location update button to get the updated address.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone11.jpg'),
            importImg('DriverAppPhone12.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'The drivers have access to the dashboard where they check their performance which consists of multiple data figures. Besides, they can the amount of assigned shipment and completed deliveries.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone13.jpg'),
            importImg('DriverAppPhone14.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'To see transferring orders, they have to go inside the Transfer orders page. Under the Incoming tab, they can see transfer requests from other drivers and on the details page, they cancel or accept.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone15.jpg'),
            importImg('DriverAppPhone16.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'To create a new transfer, the drivers need to go to the Outgoing tab and click on the button “Create new transfer” button to create a new transfer request.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone17.jpg'),
            importImg('DriverAppPhone18.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'They have to scen the shipments that need to be transferred and after that choose the driver they want to transfer the orders to. Once they chose the driver, they will have to click on the transfer button.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('DriverAppPhone19.jpg'),
            importImg('DriverAppPhone20.jpg'),
          ],
        },
      ],
    }),
  },
  TEST: {
    SECTION: 'test',
    TITLE: 'Test',
    CONTENTS: [
      'I could not perform proper usability testing with the drivers because of the budget limit, language barrier, and tight deadlines of the drivers.',
      'But I managed to visit warehouses and talk to the drivers before they went out for deliveries.',
      'I showed them the new concept design and watched them use it a little bit. Their feedback was positive and they noticed how easy it was to start the delivery right away.',
      'It was very hard to rely on the feedback I received but I was positive that the new update would have a positive impact on the drivers’ performance.',
    ],
    CONTENTS_VARIANT: '24/28',
  },
  FINALLOOK: {
    SET_BACKGROUND: true,
    TITLE: 'The final look',
    IMG: importImg('DriverAppFinalLook.jpg'),
  },
  EXPERIENCE: {
    SET_BACKGROUND: false,
    TITLE: windowResizeChanger({
      desktop: 'Results & benefits:',
      tablet: 'Benefits from this experience:',
    }),
    TOOLS: [
      {
        iconName: 'icon-one-box',
        title: 'Helping around 10,000 drivers',
        content:
          'Since this update, the driver app has not been changed and has successfully been helping around 10,000 drivers across the world complete deliveries.',
      },
      {
        iconName: 'icon-two-box',
        title: 'Testing the design',
        content:
          'I wanted to get feedback from the drivers about the new update. The overall reaction was positive, however, some drivers wanted to test it in live mode to see the benefits.',
      },
      {
        iconName: 'icon-three-box',
        title: 'Unexpected issue during the field study',
        content:
          'I was informed about a technical issue that slowed the drivers down. I contacted the Android developer in the team and got the issue resolved. Apparently, this issue has been bothering the drivers for months and no one paid attention to the complaints risen by the drivers.',
      },
      {
        iconName: 'icon-four-box',
        title: 'Empathize with the drivers',
        content:
          'During the field study and the discussions with the operation manager, I looked at the drivers’ life from a totally new perspective. I realized how important it is to go out and observe the drivers discover the issues they face in their daily work.',
      },
    ],
  },
  NOTWORK: {
    SECTION: 'not-work',
    SET_BACKGROUND: true,
    TITLE: 'Did not work',
    CONTENTS: [
      'I suggested auto routing feature that shows all the addresses in a neighborhood the shipments need to be delivered to.',
      'The team liked the feature but they had to reject it because of the the amount of technical work required to implement it.',
    ],
    TOOLS: [
      {
        column: '1fr 1fr',
        images: [
          importImg('DriverAppPhone21.jpg'),
          importImg('DriverAppPhone22.jpg'),
        ],
      },
    ],
  },
});
