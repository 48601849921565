import { windowResizeChanger } from '../utils';
import { theme } from '.';
import { importImg } from '../images';

export const MEDYPASS = () => ({
  PAGE: 'medypass',
  PATHS: [
    { path: '/portfolio', text: 'Portfolio' },
    { path: '/portfolio/medypass', text: 'Usability testing for Medypass' },
  ],
  HEADER: {
    TITLE: 'Usability testing for Medypass',
    SUBTITLE:
      'Discovered how our innovative sharing features were affecting our users and made changes that resulted in a better user experience.',
    IMAGES: [importImg('MedypassHeader.png')],
  },
  OVERVIEW: {
    TITLE: 'Overview',
    PROJECT_TIMELINE: 'Project Timeline',
    YEARS: windowResizeChanger({
      desktop: 'May 2021 - Jul 2021',
      tablet: (
        <>
          Start: <b>May 2021</b>
          <br />
          Finish: <b>Jul 2021</b>
        </>
      ),
      mobile: 'May 2021 - Jul 2021',
    }),
    MY_ROLE_TITLE: 'My role',
    MY_ROLE: 'UX researcher\nUX designer\nVisual designer',
    CONTEXT_TITLE: 'Context',
    CONTEXT1:
      'Medypass app was developed during the pandemic in 2020 and released in a hurry because of the need for an opportunity to be able to share C19 records. I designed the MVP version based on the stakeholder requirements and brainstorming sessions with the team.',
    CONTEXT2:
      'I was curious about the app’s usability, so in 2021 I organized a series of usability testings in order to find out how the app was affecting our users.',
    CONTEXT3:
      'Within two months, I conducted 3 rounds of usability testing and discovered a number of issues that were acted on and we updated the app based on the feedback we received.',
  },
  PROBLEM: {
    TITLE: 'Problem',
    SUBTITLE:
      'It was very difficult to get feedback from real users as the app did not have any active users for a while. We had to test the app with the people from our target audience.',
  },
  CONSTRAINTS: {
    TITLE: 'Constraints',
    TOOLS: [
      {
        iconName: 'icon-dollar-box',
        iconColor: theme.colors.green,
        title: 'Limited budget',
        content:
          'The budget for this project was very limited. I did not have the freedom to apply the costly methods and instead had to analyze free or cheap alternatives.',
      },
      {
        iconName: 'icon-marker-box',
        iconColor: theme.colors.red,
        title: 'Distance',
        content:
          'The app is developed for US citizens, I’m located in Uzbekistan so it was impossible to run Lab usability testing sessions. Instead, I relied on remote usability testing.',
      },
      {
        iconName: 'icon-calendar-box',
        iconColor: theme.colors.blue,
        title: 'Busy schedule',
        content:
          'I’m the only designer in the team and I have a lot of work with the other projects of the company. I had to plan and schedule the sessions with tight deadlines.',
      },
    ],
  },
  AUDIENCE: {
    TITLE: 'Target audience',
    PERSONS: [
      {
        avatar: importImg('MedypassJames.jpg'),
        name: 'James',
        subname: 'Business consultant | United States',
        content:
          'James runs a business consultancy agency in Ohio. He often travels between states to meet clients and does a couple of international travels a month. After the pandemic, he needs to carry C19 and vaccination passes all the time. A couple of times he could not get on board because he forgot his passes at home. He is tired of those papers and forgetting them.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Wants to keep all of his C19 and vaccine passes and related documents in one place, better if in an app.',
            'He needs to show his records at the airports and other places where they are required.',
            'He is tired of carrying records with him. It happened to him that he forgot some of the important records at home.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'Tired of carrying a lot of records with him.',
            'Frustrated because he sometimes forgets important records at home.',
          ],
        },
      },
      {
        avatar: importImg('MedypassSusan.jpg'),
        name: 'Susan',
        subname: 'Travek agent | United Arab Emirates',
        content:
          'Susan is a very outgoing woman who works for a local travel agency. She usually visits restaurants with her friends and sometimes she has business lunches with her clients. Most of the good restaurants she likes ask for C19 or Vaccine status. There were cases she could not get into because she did not have her records with her. She hates carrying records with her.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'She wants to keep her records with her in digital format.',
            'She needs an app or something similar that can save her records and whenever needed she could bring it up.',
            'She wants to keep her records in a secure place. She does not want to showcase her medical records to others.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'She hates carrying her medical records in paper format.',
            'Frustrated because sometimes she is not let into her favorite restaurants without C19 or vaccine record proof.',
          ],
        },
      },
    ],
  },
  PROCESS: {
    TITLE: windowResizeChanger({
      desktop: 'Process',
      tablet: 'Design Process',
    }),
    TOOLS: [
      {
        iconName: 'icon-search-box',
        iconColor: theme.colors.green,
        title: 'Plan',
        content: 'We planned the sessions with my UX manager.',
      },
      {
        iconName: 'icon-test-box',
        iconColor: theme.colors.red,
        title: 'Test',
        content: 'I ran the sessions with the people from our target audience.',
      },
      {
        iconName: 'icon-lamp-box',
        iconColor: theme.colors.blue,
        title: 'Brainstorm',
        content: 'We disccussed the findings and decided what to update.',
      },
      {
        iconName: 'icon-design-box',
        iconColor: theme.colors.yellow,
        title: 'Design',
        content: 'I made the design changes to update the app.',
      },
    ],
  },
  PLAN: {
    TITLE: 'Plan',
    CONTENT1:
      'We planned to run testing sessions with Uzbek people, in Tashkent. It was supposed to be a pilot session to get ready for the main testing with the actual target audience members. Besides, at that time we could not wait until we were to able test American users.',
    CONTENT2:
      'We decided that we test people from the 20-30 age group and I was supposed to find the participants. I approached a local company with the request, and they happily agreed to help as usability testing was something new for them and they wanted to see how it is run.',
    CONTENT3:
      'I decided to run the sessions in their office, our usability session turned into contextual testing which provided far better results than we expected. With the team, we decided to test the following sections of the app which were the most important areas:',
    ITEM1: 'Sign up and phone number verification',
    ITEM2: 'ID or Passport validation process',
    ITEM3: 'Connecting to providers to download patient records.',
    ITEM4: 'Wireless and QR code sharing features.',
    ITEM5: 'Find record details from the home page.',
  },
  TEST: {
    TITLE: 'Test',
    SUBTITLE:
      'I managed to conduct testing sessions with 4 users only. However, I discovered a lot of usability issues in the app. The main problems were related to Connecting to providers and with our Wireless sharing feature.',
    CONNECTING_TITLE: 'Connecting to providers',
    CONNECTING_IMG: {
      normal: importImg('MedypassConnecting.jpg'),
      fullscreen: importImg('MedypassConnecting.png'),
    },
    WIRELESS_TITLE: 'Wireless sharing feature',
    WIRELESS_IMG: {
      normal: importImg('MedypassWireless.jpg'),
      fullscreen: importImg('MedypassWireless.png'),
    },
    DISCOVERED_TITLE: 'What I discovered',
    CONNECTING_TITLE1: 'Connecting to providers is not clear',
    CONNECTING1:
      'Users got confused on the home page where they were supposed to connect to their providers. Even though the CTA highlighted, they seemed to miss it anyway.',
    WIRELESS_TITLE2: 'Wireless Sharing is not clear',
    WIRELESS2:
      'Users seemed to not understand how the feature worked and why it was there. They kept asking about it and they could not find information to learn more about it.',
    PHONE_IMG1: importImg('MedypassPhone1.jpg'),
    PHONE_IMG2: importImg('MedypassPhone2.jpg'),
    PHONE_IMG3: importImg('MedypassPhone3.jpg'),
    PHONE_IMG4: importImg('MedypassPhone4.jpg'),
  },
  BRAINSTORMING: {
    TITLE: 'Brainstorming',
    CONTENT:
      'I shared my findings with my team. Based on the feedback I received, we brainstormed and came up with a number of solutions to the discovered issues.',
    REMOVE_TITLE: 'Remove sharing features',
    REMOVE:
      'We decided to remove sharing feature buttons from the home page in the starting point. We decided to keep only the download button.',
    PROVIDE_TITLE: 'Provide info about Wireless Sharing',
    PROVIDE:
      'We decided to add an info icon that leads users to the info page about the Wireless Sharing feature. This way users may find more about the feature if they desire it.',
  },
  DESIGN: {
    TITLE: 'Design',
    SUBTITLE:
      'Based on our brainstorming sessions with the team, the following changes were made to the design of the app:',
    STEP1_CONTENT:
      'We removed the sharing buttons and concentrated only on the download records section. But we wanted to give choosing option to users, so we provided the skip for now button.',
    STEP1_IMG1: importImg('MedypassPhone5.jpg'),
    STEP1_IMG2: importImg('MedypassPhone6.jpg'),
    STEP2_CONTENT:
      'Here we added an info icon that leads to the info page about the sharing features that explain how it works. We discovered that Wireless Sharing features need explanation of how it works.',
    STEP2_IMG1: importImg('MedypassPhone7.jpg'),
    STEP2_IMG2: importImg('MedypassPhone8.jpg'),
  },
  CHANGES: {
    TITLE: 'Additional changes',
    CONTENT:
      'I ran two more sessions with users to find more issues. The sessions helped to identify more pain-points and based on the feedback I received from the users, we made changes to the app:',
    DETAILS_TITLE: 'Card details not clear',
    DETAILS:
      'The record cards on the home page are clickable. But it was not clear in the beginning, so we decided to put an info icon to help users. However, it did not help that much, so we decided to use a button instead of an icon.',
    PHONE_IMG1: importImg('MedypassPhone9.jpg'),
    PHONE_IMG2: importImg('MedypassPhone10.jpg'),
    PHONE_IMG3: importImg('MedypassPhone11.jpg'),
    WIRELESS_TITLE: 'Wireless Sharing page',
    WIRELESS:
      'Based on the feedback we received from the second session, we made the changes to this page (middle image). But it did not provide expected results. So we improved the page further and ended with a better option (3rd image).',
    PHONE_IMG4: importImg('MedypassPhone4.jpg'),
    PHONE_IMG5: importImg('MedypassPhone13.jpg'),
    PHONE_IMG6: importImg('MedypassPhone14.jpg'),
  },
  FINALLOOK: {
    TITLE: 'The final look',
    IMG: importImg('MedypassFinalLook.jpg'),
  },
  EXPERIENCE: {
    TITLE: 'What I learned from this experience:',
    TOOLS: [
      {
        iconName: 'icon-one-box',
        title: 'Any usability testing has value',
        content:
          'I understood the term “Doing some usability testing is a lot better than not doing testing at all”. The first usability testing helped us to discover 2 big usability issues.',
      },
      {
        iconName: 'icon-two-box',
        title: 'Planning helps',
        content:
          'It was a lot easier for me to stick to the plan we made with my UX manager during the testing. The plan helped me to concentrate on the main tasks.',
      },
      {
        iconName: 'icon-three-box',
        title: 'Rational thinking is important',
        content:
          'In the last session, participants provided a lot of positive feedback. We had to be very careful with that because they were used to providing positive feedback as those participants were from the User Testing board.',
      },
      {
        iconName: 'icon-four-box',
        title: 'No to extra feature desires',
        content:
          'It has been very hard to stay away from extra feature desires. After the usability testing, we could rely on real user data to be able to concentrate on the main goals of the app and improve its usability.',
      },
    ],
  },
});
