import styled from 'styled-components';
import { StartupLogo } from '../../../components';
import { theme } from '../../../const';

const StartupLoaderBase = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.white};
  z-index: 10000;

  animation-name: startup-lock, startup-hide;
  animation-delay: 0s, 2s;
  animation-duration: 2s, 0.3s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;

  @keyframes startup-lock {
    99% {
      pointer-events: all;
    }
    100% {
      pointer-events: none;
    }
  }

  @keyframes startup-hide {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

const LoaderBase = styled.div`
  width: 20rem;
  height: 0.4rem;

  position: absolute;
  bottom: 16rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 1rem;
  overflow: hidden;

  @media (max-height: 500px) {
    top: calc(50% + 8rem);
    bottom: initial;
  }
`;

const LoaderBackground = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.darkBlue};
  opacity: 0.2;
`;

const Loader = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${theme.colors.blue};
  position: absolute;
  top: 0;
  animation: loading 2s ease-out;

  @keyframes loading {
    0% {
      width: 0%;
    }

    100% {
      width: 100%;
    }
  }
`;

export const StartupLoader = () => {
  return (
    <StartupLoaderBase>
      <StartupLogo />
      <LoaderBase>
        <LoaderBackground />
        <Loader />
      </LoaderBase>
    </StartupLoaderBase>
  );
};
