import { Container } from '../../components';
import {
  PathNavigation,
  ScrollToUpButton,
  Header,
  Overview,
  Problem,
  Constraints,
  Audience,
  Process,
  Projects,
  FinalLook,
  Experience,
} from '../components';
import { Plan } from './Plan';
import { Test } from './Test';
import { Brainstorming } from './Brainstorming';
import { Design } from './Design';
import { Changes } from './Changes';
import { MEDYPASS } from '../../const';

export const Medypass = () => {
  return (
    <>
      <PathNavigation paths={MEDYPASS().PATHS} />
      <ScrollToUpButton />
      <Container main>
        <Header PAGE={MEDYPASS().PAGE} {...MEDYPASS().HEADER} />
        <Overview PAGE={MEDYPASS().PAGE} {...MEDYPASS().OVERVIEW} />
        <Problem PAGE={MEDYPASS().PAGE} {...MEDYPASS().PROBLEM} />
        <Constraints PAGE={MEDYPASS().PAGE} {...MEDYPASS().CONSTRAINTS} />
        <Audience PAGE={MEDYPASS().PAGE} {...MEDYPASS().AUDIENCE} />
        <Process PAGE={MEDYPASS().PAGE} {...MEDYPASS().PROCESS} />
        <Plan PAGE={MEDYPASS().PAGE} {...MEDYPASS().PLAN} />
        <Test PAGE={MEDYPASS().PAGE} {...MEDYPASS().TEST} />
        <Brainstorming PAGE={MEDYPASS().PAGE} {...MEDYPASS().BRAINSTORMING} />
        <Design PAGE={MEDYPASS().PAGE} {...MEDYPASS().DESIGN} />
        <Changes PAGE={MEDYPASS().PAGE} {...MEDYPASS().CHANGES} />
        <FinalLook PAGE={MEDYPASS().PAGE} {...MEDYPASS().FINALLOOK} />
        <Experience PAGE={MEDYPASS().PAGE} {...MEDYPASS().EXPERIENCE} />
        <Projects showProjects={[3, 5]} otherProjects />
      </Container>
    </>
  );
};
