import styled from 'styled-components';
import { LinkButton } from './LinkButton';
import { IconSVG } from '../icon-svg';
import { theme } from '../../const';

const SocialButtonBase = styled(LinkButton)`
  height: max-content;
  padding: 0;
  fill: ${theme.colors.blue100};
  color: ${theme.colors.blue};
  transition: fill 0.3s, color 0.3s;

  &:hover {
    fill: ${theme.colors.blue};
    color: ${theme.colors.white};
  }
`;

const sizes = {
  small: '3rem',
  normal: '4rem',
};

export const SocialButton = ({ iconName, size = 'normal', ...props }) => {
  return (
    <SocialButtonBase variant={'none'} {...props}>
      <IconSVG iconName={iconName} size={sizes[size]} />
    </SocialButtonBase>
  );
};
