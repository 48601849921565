import { GlobalStyle } from './components';
import { Routes } from './routes';
import { ImageViewer } from './components';
import { StartupLoader } from './pages';

export const App = () => {
  return (
    <>
      <GlobalStyle />
      <Routes />
      <ImageViewer />
      <StartupLoader />
    </>
  );
};
