import styled, { css } from 'styled-components';
import { Typography, media } from '../../../components';
import { theme } from '../../../const';

const HeaderBase = styled.div`
  display: grid;
  grid-template-rows: repeat(3, max-content);
  justify-items: center;
  padding-top: 7rem;
  padding-bottom: 0;

  ${(props) =>
    props.page === 'medyear-pro' &&
    css`
      padding-bottom: 5rem;
    `}

  ${(props) =>
    props.page === 'driver-app' &&
    css`
      padding-bottom: 2.5rem;
    `}

  ${(props) =>
    props.page === 'branding-projects' &&
    css`
      padding-bottom: 10rem;
    `}

  ${media.tablet`
    padding: 5rem 0 0 0;

    ${(props) =>
      props.page === 'branding-projects' &&
      css`
        padding-bottom: 5rem;
      `}
  `}

  ${media.mobile`
    padding: 3rem 0;
  `}
`;

const HeaderSubtitle = styled(Typography)`
  padding: 1rem 0 3rem;

  ${media.tablet`
    padding: 1rem 0 2rem;
  `}

  ${(props) =>
    props.page === 'medypass' &&
    css`
      max-width: 60rem;

      ${media.tablet`
        max-width: 53.1rem;
      `}
    `}

  ${(props) =>
    props.page === 'medyear-pro' &&
    css`
      max-width: 66rem;

      ${media.tablet`
        max-width: 59.3rem;
      `}
    `}
    
  ${(props) =>
    props.page === 'driver-app' &&
    css`
      max-width: 60.9rem;

      ${media.tablet`
        max-width: 54.1rem;
        padding-bottom: 3rem;
      `}
    `}
    
  ${(props) =>
    props.page === 'customer-app' &&
    css`
      max-width: 58.5rem;

      ${media.tablet`
        max-width: 52.1rem;
        padding-bottom: 3rem;
      `}
    `}

  ${(props) =>
    props.page === 'personal-website' &&
    css`
      max-width: 40.3rem;

      ${media.tablet`
        max-width: 35.7rem;
        padding-bottom: 3rem;
      `}
    `}

  ${(props) =>
    props.page === 'jowi-owner-app' &&
    css`
      max-width: 46.3rem;

      ${media.tablet`
        max-width: 41.3rem;
        padding-bottom: 3rem;
      `}
    `}
    
  ${(props) =>
    props.page === 'personal-logo' &&
    css`
      max-width: 47.5rem;

      ${media.tablet`
        max-width: 42.1rem;
        padding-bottom: 3rem;
      `}
    `}

  ${(props) =>
    props.page === 'branding-projects' &&
    css`
      max-width: 57.5rem;
      text-align: left;

      ${media.tablet`
        max-width: 52.7rem;
        padding-bottom: 3rem;
        text-align: center;
      `}
    `}

  ${media.mobile`
    padding: 0.4rem 0 1rem;
    line-height: 1.6rem;

    ${(props) =>
      props.page === 'personal-website' &&
      css`
        padding-bottom: 2rem;
      `}
  `}
`;

const HeaderImages = styled.div`
  display: grid;
  width: 100%;

  ${(props) =>
    props.page === 'medyear-pro' &&
    css`
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 3rem;

      ${media.mobile`
        grid-column-gap: 1.4rem;
      `}
    `}

  ${(props) =>
    props.page === 'personal-website' &&
    css`
      max-width: 97.2rem;
    `}
`;

const HeaderImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const Header = ({ PAGE, TITLE, SUBTITLE, IMAGES }) => {
  return (
    <HeaderBase page={PAGE}>
      <Typography
        variant={'48/58'}
        weight={theme.font.weightBold}
        align={'center'}
      >
        {TITLE}
      </Typography>
      <HeaderSubtitle variant={'20/28'} align={'center'} page={PAGE}>
        {SUBTITLE}
      </HeaderSubtitle>
      <HeaderImages page={PAGE}>
        {IMAGES.map((img, i) => (
          <HeaderImg key={i} src={img} page={PAGE} alt={'Header'} />
        ))}
      </HeaderImages>
    </HeaderBase>
  );
};
