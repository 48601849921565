import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { IconSVG, media } from '../../../components';

const NavigationHamburgerBase = styled.div`
  display: none;
  width: 2.4rem;
  height: 2.4rem;

  position: relative;
  cursor: pointer;

  ${media.mobile`
    display: initial;
  `}
`;

const IconHamburger = styled(IconSVG)`
  width: 100%;
  height: 100%;

  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s, transform 0.3s;

  ${(props) =>
    props.isOpenMenu &&
    css`
      opacity: 0;
      transform: rotateZ(180deg);
    `}
`;

const IconClose = styled(IconHamburger)`
  ${(props) =>
    !props.isOpenMenu &&
    css`
      opacity: 0;
    `}

  ${(props) =>
    props.isOpenMenu &&
    css`
      opacity: 1;
    `}
`;

export const NavigationHamburger = ({
  isOpenMenu,
  setIsOpenMenu,
  handleOnClick,
}) => {
  const handleHamburgerClick = useCallback(() => {
    setIsOpenMenu(!isOpenMenu);
    handleOnClick && handleOnClick(!isOpenMenu);
  }, [isOpenMenu, setIsOpenMenu, handleOnClick]);

  return (
    <NavigationHamburgerBase onClick={handleHamburgerClick}>
      <IconHamburger iconName={'icon-hamburger'} isOpenMenu={isOpenMenu} />
      <IconClose iconName={'icon-close'} isOpenMenu={isOpenMenu} />
    </NavigationHamburgerBase>
  );
};
