import styled from 'styled-components';
import { ContainerWrapper, media } from '../../components';

const HeaderBase = styled(ContainerWrapper)`
  height: 56rem;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.img});

  ${media.tablet`
    height: 22.4rem;
  `}

  ${media.mobile`
    height: initial;
    padding-bottom: calc((20 / 33) * 100%) !important;
  `}
`;

export const Header = ({ BACKGROUND_IMG }) => {
  return <HeaderBase img={BACKGROUND_IMG} />;
};
