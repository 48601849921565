import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Typography } from '../typography';

const LinkBase = styled(Typography)`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.noUnderline &&
    css`
      text-decoration: none;
    `}
`;

export const Link = ({ href, to, noUnderline, children, ...props }) => {
  const historyPush = useNavigate();

  const handleOnClick = useCallback(() => {
    if (href) {
      window.location.href = href;
      return;
    }

    if (to) historyPush(to);
  }, [historyPush, href, to]);

  return (
    <LinkBase onClick={handleOnClick} {...props}>
      {children}
    </LinkBase>
  );
};
