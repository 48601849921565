import { useLayoutEffect, useState, useRef } from 'react';
import { windowResizeChanger } from '.';

export const useWindowFlow = ({ firstBreak = false }) => {
  const breaked = useRef(false);
  const [windowFlow, setWindowFlow] = useState(
    windowResizeChanger({
      desktop: 'desktop',
      tablet: 'tablet',
      mobile: 'mobile',
    })
  );

  useLayoutEffect(() => {
    const handleResize = () => {
      const getWindowFlow = windowResizeChanger({
        desktop: 'desktop',
        tablet: 'tablet',
        mobile: 'mobile',
      });

      if (getWindowFlow === 'mobile' && getWindowFlow !== windowFlow)
        setWindowFlow('mobile');

      if (getWindowFlow === 'tablet' && getWindowFlow !== windowFlow)
        setWindowFlow('tablet');

      if (getWindowFlow === 'desktop' && getWindowFlow !== windowFlow)
        setWindowFlow('desktop');
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [windowFlow]);

  if (firstBreak !== breaked.current) {
    breaked.current = true;
    return;
  }

  return windowFlow;
};
