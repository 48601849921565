import styled, { css } from 'styled-components';
import { media } from '../../../components';
import { theme } from '../../../const';

const ToolBoxesBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 2rem;
  margin-top: 5rem;
  width: 100%;

  & > *:nth-child(2n + 3) {
    margin-top: 6rem;
  }

  ${media.tablet`
    margin-top: 3rem;
    grid-row-gap: 1rem;

    & > *:nth-child(2n + 3) {
      margin-top: 2rem;
    }
  `}

  ${(props) =>
    props.page === 'medyear-pro' &&
    props.section === 'brainstorming' &&
    css`
      & > *:nth-child(2) > *:nth-child(3) {
        grid-column: 3 / 5;
      }
    `}

  ${(props) =>
    props.page === 'driver-app' &&
    props.section === 'research' &&
    css`
      grid-row-gap: 3rem;

      & > *:nth-child(1) {
        justify-self: left;
      }

      & > *:nth-child(2n + 3) {
        margin-top: 2rem;
      }

      ${media.tablet`
        grid-row-gap: 0.5rem;

        & > *:nth-child(2n + 3) {
          margin-top: 1.5rem;
        }
      `}
    `}

  ${(props) =>
    props.page === 'driver-app' &&
    props.section === 'brainstorming' &&
    css`
      grid-row-gap: 1rem;
      margin-top: 3rem;

      & > *:nth-child(2n + 3) {
        margin-top: 4rem;
      }

      ${media.tablet`
        & > *:nth-child(2n + 3) {
          margin-top: 2rem;
        }
      `}
    `}

  ${(props) =>
    props.page === 'driver-app' &&
    props.section === 'design' &&
    css`
      grid-row-gap: 1rem;

      & > *:nth-child(2n + 3) {
        margin-top: 4rem;
      }
    `}

  ${(props) =>
    props.page === 'driver-app' &&
    props.section === 'not-work' &&
    css`
      margin-top: 3rem;

      & > *:nth-child(2n + 3) {
        margin-top: 0rem;
      }
    `}

  ${(props) =>
    props.page === 'customer-app' &&
    props.section === 'research' &&
    css`
      & > *:nth-child(2n + 3) {
        margin-top: 3rem;
      }
    `}

  ${(props) =>
    props.page === 'customer-app' &&
    props.section === 'brainstorming' &&
    css`
      grid-row-gap: 5rem;

      & > *:nth-child(2n + 3) {
        margin-top: 0rem;
      }

      ${media.tablet`
        grid-row-gap: 2rem;
      `}
    `}
  
  ${(props) =>
    (props.page === 'customer-app' || props.page === 'jowi-owner-app') &&
    props.section === 'design' &&
    css`
      grid-row-gap: 1rem;

      & > *:nth-child(2n + 3) {
        margin-top: 4rem;
      }

      ${media.tablet`
        & > *:nth-child(2n + 3) {
          margin-top: 2rem;
        }

        & > *:nth-child(1) > *:nth-child(1) {
          grid-column: 1 / 3;
        }
      `}
    `}
  
  ${(props) =>
    props.page === 'jowi-owner-app' &&
    props.section === 'research' &&
    css`
      margin-top: 3rem;

      & > *:nth-child(2) {
        margin-top: 3rem;
      }

      ${media.tablet`
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 3rem;

        & > *:nth-child(2) {
          margin-top: 0;
        }
      `}
    `}

  ${(props) =>
    props.page === 'jowi-owner-app' &&
    props.section === 'brainstorming' &&
    css`
      grid-row-gap: 3rem;
      margin-top: 3rem;

      & > *:nth-child(3) {
        margin-top: 7rem;
      }

      ${media.tablet`
        grid-row-gap: 2rem;

        & > *:nth-child(1),
        & > *:nth-child(2) {
          grid-row-gap: 1rem;
        }

        & > *:nth-child(1) > *,
        & > *:nth-child(2) > * {
          align-items: start;
        }

        & > *:nth-child(1) > *:nth-child(n + 3), 
        & > *:nth-child(2) > *:nth-child(n + 3) {
          width: 25.8rem;
          margin-left: 8rem;
        }

        & > *:nth-child(2) {
          margin: 3rem 0;
        }

        & > *:nth-child(3) {
          margin-top: 0;

          & > * {
            max-width: 100%;
          }
        }
      `}
    `}

  ${(props) =>
    props.page === 'jowi-owner-app' &&
    props.section === 'design' &&
    media.tablet`
      & > *:nth-child(1) > *:nth-child(1) {
        grid-column: 1 / 3;
      }
    `}

  ${(props) =>
    props.page === 'personal-logo' &&
    props.section === 'research' &&
    css`
      & > *:nth-child(2n + 3) {
        margin-top: 0rem;
      }

      ${media.tablet`
        grid-row-gap: 3rem;

        & > *:nth-child(1) {
          max-width: 52.7rem;
          justify-self: center;
        }

        & > *:nth-child(2) {
          max-width: 66.6rem;
          height: max-content;
          justify-self: center;
        }
      `}
    `}

  ${(props) =>
    props.page === 'personal-logo' &&
    props.section === 'brainstorm' &&
    media.tablet`
      & > *:nth-child(1) {
        grid-row-gap: 3rem;
      }
    `}

  ${(props) =>
    props.page === 'personal-logo' &&
    props.section === 'design-sketches' &&
    css`
      & > *:nth-child(2n + 3) {
        margin-top: 1rem;
      }

      ${media.tablet`
        & > *:nth-child(2n + 3) {
          margin-top: 0.5rem;
        }
      `}
    `}

  ${(props) =>
    props.page === 'personal-logo' &&
    props.section === 'design-concept' &&
    css`
      grid-row-gap: 0;
      margin-top: 2rem;

      & > *:nth-child(2n + 3) {
        margin-top: 0;
      }

      & > *:nth-child(2) {
        margin: 5rem 0 1rem;
      }

      & > *:nth-child(3) {
        grid-column-gap: 3rem;

        & > * {
          height: max-content;
        }
      }

      ${media.tablet`
        grid-row-gap: 0;

        & > *:nth-child(2) {
          justify-self: left;
        }
      `}
    `}
  
  ${(props) =>
    props.page === 'personal-logo' &&
    props.section === 'design-test' &&
    css`
      grid-row-gap: 5rem;
      margin-top: 3rem;

      & > *:nth-child(2n + 3) {
        margin-top: 0;
      }

      ${media.tablet`
        grid-row-gap: 3rem;
        margin-top: 1rem;
      `}
    `}

  ${(props) =>
    props.page === 'personal-logo' &&
    props.section === 'design-color' &&
    css`
      grid-row-gap: 0rem;

      & > *:nth-child(1) {
        margin-bottom: 1rem;
      }

      & > *:nth-child(2) > *:nth-child(3) {
        align-self: center;
        justify-self: center;
        width: initial;
        height: initial;
      }

      & > *:nth-child(2n + 3) {
        margin-top: 0;
      }

      & > *:nth-child(3) {
        margin: 8rem 0 3rem;
      }

      & > *:nth-child(4) {
        align-items: center;

        & > *:nth-child(4) {
          justify-self: center;
          width: initial;
        }
      }

      ${media.tablet`
        & > *:nth-child(1) {
          margin-bottom: 0rem;
        }

        & > *:nth-child(4n + 3) {
          justify-self: center;
          margin: 2rem 0 0 0;
        }

        & > *:nth-child(4),
        & > *:nth-child(8) {
          justify-self: center;
        }

        & > *:nth-child(6) {
          align-items: center;
        }
      `}
    `}
    
  ${(props) =>
    props.page === 'personal-logo' &&
    props.section === 'design-accessibility' &&
    css`
      grid-row-gap: 0;

      & > *:nth-child(2) {
        margin: 2rem 0 8rem;

        ${media.tablet`
          margin: 0 0 2rem;
        `}
      }

      & > *:nth-child(2n + 3) {
        margin-top: 0;
      }

      & > *:nth-child(2n + 4) {
        margin: 3rem 0 1rem;

        ${media.tablet`
          margin: 0;
        `}
      }

      & > *:nth-child(2n + 5) > *:nth-child(n) {
        height: 20rem;
        padding: 5rem 0 4.9rem;

        ${media.tablet`
          height: 16rem;
          padding: 3.9rem 0 4rem;
        `}
      }

      & > *:nth-child(2n + 5) > *:nth-child(2n + 1) {
        background-color: ${theme.colors.darkBlue100};
      }

      & > *:nth-child(6) {
        margin-top: 7rem;
      }

      & > *:nth-child(2n + 6) {
        ${media.tablet`
          margin-top: 2rem;
        `}
      }

      & > *:nth-child(5) > *:nth-child(2) {
        background-color: ${theme.colors.blue};
      }

      & > *:nth-child(7) > *:nth-child(2) {
        background-color: ${theme.colors.darkBlue900};
      }

      & > *:nth-child(7) > *:nth-child(4) {
        background-color: ${theme.colors.darkBlue700};
      }

      & > *:nth-child(9) > *:nth-child(2) {
        background-color: ${theme.colors.darkBlue800};
      }

      & > *:nth-child(9) > *:nth-child(4) {
        background-color: ${theme.colors.darkBlue600};
      }

      & > *:nth-child(11) > *:nth-child(2) {
        background-color: ${theme.colors.lightGreen900};
      }

      & > *:nth-child(11) > *:nth-child(4) {
        background-color: ${theme.colors.blue700};
      }

      & > *:nth-child(13) > *:nth-child(2) {
        background-color: ${theme.colors.black700};
      }

      & > *:nth-child(13) > *:nth-child(4) {
        background-color: ${theme.colors.blue500};
      }
    `}

    
  ${media.mobile`
    margin-top: 1rem;
        
    & > *:nth-child(2n + 3) {
      margin-top: 1rem;
    }

    ${(props) =>
      props.page === 'driver-app' &&
      props.section === 'research' &&
      css`
        margin-top: 2rem;

        & > *:nth-child(1) {
          margin-bottom: 0.5rem;
        }
      `}

    ${(props) =>
      props.page === 'driver-app' &&
      props.section === 'design' &&
      css`
        margin-top: 2rem;
      `}

    ${(props) =>
      props.page === 'customer-app' &&
      props.section === 'problem' &&
      css`
        margin-top: 2rem;
      `}

    ${(props) =>
      props.page === 'customer-app' &&
      props.section === 'research' &&
      css`
        grid-row-gap: 0.5rem;
        margin-top: 1.5rem;
      `}

    ${(props) =>
      props.page === 'customer-app' &&
      props.section === 'brainstorming' &&
      css`
        grid-row-gap: 1.5rem;
        margin-top: 2rem;

        & > *:nth-child(2n + 3) {
          margin-top: 0;
        }
      `}

    ${(props) =>
      props.page === 'customer-app' &&
      props.section === 'design' &&
      css`
        margin-top: 2rem;

        & > *:nth-child(1) > *:nth-child(1) {
          grid-column: 1;
        }

        & > *:nth-child(2n + 3) {
          grid-row-gap: 0.5rem;
        }
      `}

    ${(props) =>
      props.page === 'personal-website' &&
      props.section === 'process' &&
      css`
        margin-top: 2rem;

        & > *:nth-child(1) {
          grid-row-gap: 2rem;
        }
      `}

    ${(props) =>
      props.page === 'personal-website' &&
      props.section === 'discovered' &&
      css`
        margin-top: 2rem;

        & > *:nth-child(1) {
          grid-row-gap: 2rem;
        }
      `}

    ${(props) =>
      props.page === 'jowi-owner-app' &&
      props.section === 'research' &&
      css`
        grid-template-columns: 1fr;
        grid-row-gap: 1.5rem;
      `}

    ${(props) =>
      props.page === 'jowi-owner-app' &&
      props.section === 'brainstorming' &&
      css`
        margin-top: 2rem;

        & > *:nth-child(1) > *:nth-child(n + 3),
        & > *:nth-child(2) > *:nth-child(n + 3) {
          width: 100%;
          margin-left: 0;
        }

        & > *:nth-child(2) {
          margin: 0;
        }

        & > *:nth-last-child(1) {
          margin-top: -1rem;
        }
      `}

    ${(props) =>
      props.page === 'jowi-owner-app' &&
      props.section === 'design' &&
      css`
        margin-top: 2rem;

        & > *:nth-child(2n + 3) {
          grid-row-gap: 0.5rem;
        }
      `}

    ${(props) =>
      props.page === 'personal-logo' &&
      props.section === 'problem' &&
      css`
        margin-top: 2rem;
      `}

    ${(props) =>
      props.page === 'personal-logo' &&
      props.section === 'research' &&
      css`
        margin-top: 2rem;
        grid-row-gap: 1rem;
      `}

    ${(props) =>
      props.page === 'personal-logo' &&
      props.section === 'brainstorm' &&
      css`
        & > *:nth-child(1) {
          grid-row-gap: 1.5rem;
        }
      `}

    ${(props) =>
      props.page === 'personal-logo' &&
      props.section === 'design-sketches' &&
      css`
        & > *:nth-last-child(1) {
          margin-top: 0;
        }
      `}

    ${(props) =>
      props.page === 'personal-logo' &&
      props.section === 'design-concept' &&
      css`
        & > *:nth-child(2) {
          margin: 2rem 0 1rem;
        }

        & > *:nth-child(3) {
          grid-row-gap: 2rem;
          margin: 0;
        }
      `}

    ${(props) =>
      props.page === 'personal-logo' &&
      props.section === 'design-test' &&
      css`
        grid-row-gap: 1.5rem;

        & > *:nth-last-child(1) {
          margin-top: 0;
        }
      `}
      
    ${(props) =>
      props.page === 'personal-logo' &&
      props.section === 'design-color' &&
      css`
        margin-top: 1.5rem;

        & > *:nth-child(2n + 3) {
          margin-top: 1rem;
        }

        & > *:nth-child(6) > *:nth-child(2) {
          width: 100%;
        }
      `}

    ${(props) =>
      props.page === 'personal-logo' &&
      props.section === 'design-accessibility' &&
      css`
        margin-top: 1.5rem;

        & > *:nth-child(2) {
          margin: 0;
        }

        & > *:nth-child(2n + 3) {
          margin-top: 0;
        }

        & > *:nth-child(2n + 4) {
          margin-top: 0.5rem;
        }

        & > *:nth-child(2n + 5) {
          margin-top: -0.5rem;
        }

        & > *:nth-child(2n + 5) > *:nth-child(n) {
          height: 12rem;
          padding: 2rem 0 1.9rem;
        }

        & > *:nth-child(9) > *:nth-child(2) {
          background-color: ${theme.colors.darkBlue700};
        }

        & > *:nth-child(11) > *:nth-child(2) {
          background-color: ${theme.colors.darkBlue800};
        }

        & > *:nth-child(13) > *:nth-child(2) {
          background-color: ${theme.colors.darkBlue600};
        }

        & > *:nth-child(15) > *:nth-child(2) {
          background-color: ${theme.colors.lightGreen900};
        }

        & > *:nth-child(17) > *:nth-child(2) {
          background-color: ${theme.colors.blue700};
        }

        & > *:nth-child(19) > *:nth-child(2) {
          background-color: ${theme.colors.black700};
        }

        & > *:nth-child(21) > *:nth-child(2) {
          background-color: ${theme.colors.blue500};
        }
      `}
  `}
`;

export const ToolBoxes = ({ PAGE, SECTION, TOOLS, children }) => {
  if (!TOOLS) return null;

  return (
    <ToolBoxesBase page={PAGE} section={SECTION}>
      {TOOLS.map((props, i) => children(props, i))}
    </ToolBoxesBase>
  );
};
