import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { ContainerBackground, Typography, media } from '../../../components';
import { IconSVG } from '../../../components/icon-svg';
import { theme, PORTFOLIO } from '../../../const';

const ProjectsBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 5rem;
  justify-items: center;

  position: relative;
  padding: 10rem 0;

  ${media.tablet`
    grid-row-gap: 3rem;
    padding: 8rem 0 3rem;

    ${(props) =>
      props.otherProjects &&
      css`
        padding: 5rem 0 3rem;
      `}
  `}

  ${media.mobile`
    grid-row-gap: 2rem;
    padding: 3rem 0;

    ${(props) =>
      props.otherProjects &&
      css`
        padding: 3rem 0 2rem;
      `}
  `}
`;

const ProjectsBackground = styled(ContainerBackground)`
  ${(props) =>
    (props.page === 'personal-website' ||
      props.page === 'jowi-owner-app' ||
      props.page === 'personal-logo') &&
    css`
      background-color: ${theme.colors.light};
    `}

  & + * {
    z-index: 100;
  }
`;

const ProjectBase = styled.div`
  display: grid;
  grid-template-rows: repeat(3, max-content);
  grid-row-gap: 1rem;

  height: 36rem;
  padding: 5rem 62rem 5rem 5rem;
  border-radius: 0.5rem;
  background-color: ${(props) => props.color};
  box-shadow: ${theme.shadows[0]};
  overflow: hidden;
  position: relative;

  ${media.tablet`
    grid-template-columns: minmax(26.6rem, 100%);
    grid-row-gap: 0.5rem;
    width: 100%;
    height: 24rem;
    padding: 3rem 40rem 3rem 3rem;
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 1rem;
    height: 100%;
    max-height: 39.1rem;
    padding: 2rem 2rem 0 2rem;
  `}
`;

const ProjectTitle = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProjectContent = styled(Typography)`
  ${media.mobile`
    display: none;
  `}
`;

const ProjectLink = styled(Link)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: max-content;
  margin-top: 1rem;
  text-decoration: none;
  grid-column-gap: 0.4rem;

  color: ${theme.colors.blue};
  font-size: ${theme.font.size14};
  font-weight: ${theme.font.weight600};
  line-height: ${theme.font.lineHeight16};
  letter-spacing: ${theme.font.letterSpacing007};

  ${media.tablet`
    margin-top: 1.5rem;
  `}

  ${media.mobile`
    margin: 0;
    margin-bottom: calc(100% + 3rem);
  `}
`;

const ProjectLinkIcon = styled(IconSVG)`
  width: 1.6rem;
  height: 1rem;
  transition: margin 0.3s;

  ${ProjectLink}:hover > & {
    margin-left: 1rem;
  }
`;

const ProjectImg = styled.div`
  width: 57rem;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right center;
  background-image: url(${(props) => props.img});

  position: absolute;
  right: 0;
  pointer-events: none;

  ${media.mobile`
    width: 100%;
    height: calc(100% - 9.1rem);
    max-height: 30rem;
    background-position: center top;
    top: 9.1rem;
    right: initial;
    background-size: cover;

    ${(props) =>
      props.mobileImgCenter &&
      css`
        background-position: center;
      `}
  `}
`;

const Project = ({ title, content, link, img, mobileImgCenter, color }) => {
  return (
    <ProjectBase color={color}>
      <ProjectTitle variant={'32/38'} weight={theme.font.weightBold}>
        {title}
      </ProjectTitle>
      <ProjectContent variant={'20/28'}>{content}</ProjectContent>
      <ProjectLink to={link}>
        {PORTFOLIO().PROJECTS.CARD_LINK_TEXT}
        <ProjectLinkIcon iconName={'icon-blue-right-arrow2'} />
      </ProjectLink>
      <ProjectImg img={img} mobileImgCenter={mobileImgCenter} />
    </ProjectBase>
  );
};

export const Projects = ({
  PAGE,
  showProjects = [0, 1, 2, 3, 4, 5, 6, 7],
  otherProjects,
}) => {
  return (
    <ProjectsBase otherProjects={otherProjects}>
      <ProjectsBackground page={PAGE} />
      <Typography
        variant={'40/48'}
        color={theme.colors.darkBlue}
        weight={theme.font.weight600}
      >
        {otherProjects
          ? PORTFOLIO().PROJECTS.OTHER_TITLE
          : PORTFOLIO().PROJECTS.TITLE}
      </Typography>
      {showProjects
        .map((n) => PORTFOLIO().PROJECTS.CARDS[n])
        .map(({ title, content, link, img, mobileImgCenter, color }, i) => (
          <Project
            key={i}
            title={title}
            content={content}
            link={link}
            img={img}
            mobileImgCenter={mobileImgCenter}
            color={color}
          />
        ))}
    </ProjectsBase>
  );
};
