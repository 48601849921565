import styled, { css } from 'styled-components';
import { Typography, media } from '../../../components';
import { theme } from '../../../const';

const FinalLookBase = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: 5rem;

  width: 100%;
  min-width: 100vw;
  height: 100%;

  position: relative;
  left: 50%;
  transform: translateX(-50%);

  & > *:nth-last-child(n + 2) {
    z-index: 100;
  }

  ${(props) =>
    props.setBackground &&
    css`
      background-color: ${theme.colors.light};
    `}

  ${media.tablet`
    grid-row-gap: 3rem;

    ${(props) =>
      props.page === 'personal-logo' &&
      css`
        padding-bottom: 0;
      `}
  `}

  ${media.mobile`
    grid-row-gap: 2rem;

    ${(props) =>
      props.page === 'medyear-pro' &&
      css`
        grid-row-gap: 0.5rem;
        padding-bottom: 2.3rem;
      `}

    ${(props) =>
      props.page === 'personal-website' &&
      css`
        grid-row-gap: 1.5rem;
      `}
  `}
`;

const FinalLookImageWrapper = styled.div`
  margin: auto;
  max-width: 192rem;
  line-height: 0;

  ${(props) =>
    (props.page === 'medypass' ||
      props.page === 'driver-app' ||
      props.page === 'customer-app' ||
      props.page === 'jowi-owner-app') &&
    css`
      padding: 0 7rem;

      ${media.tablet`
        padding: 0 3.1rem;
      `}

      ${media.mobile`
        padding: 0 1.5rem;
      `}
    `};

  ${(props) =>
    props.page === 'personal-logo' &&
    css`
      padding: 0 3rem;

      ${media.tablet`
        padding: 0;
      `}

      ${media.mobile`
        padding: 0 1.5rem;
      `}
    `}

  ${media.mobile`
    display: grid;
    justify-items: center;
    width: 100%;
    overflow: overlay;

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const FinalLookImage = styled.img`
  width: 100%;

  ${media.mobile`
    width: initial;

    ${(props) =>
      props.page === 'medypass' &&
      css`
        height: 58.3rem;
      `}

    ${(props) =>
      props.page === 'medyear-pro' &&
      css`
        height: 58rem;
      `}

    ${(props) =>
      props.page === 'driver-app' &&
      css`
        height: 58.3rem;
      `}

    ${(props) =>
      props.page === 'customer-app' &&
      css`
        height: 58.3rem;
      `}

    ${(props) =>
      props.page === 'personal-website' &&
      css`
        height: 61.6rem;
      `}

    ${(props) =>
      props.page === 'jowi-owner-app' &&
      css`
        height: 56.4rem;
      `}

    ${(props) =>
      props.page === 'personal-logo' &&
      css`
        height: 52rem;
      `}
  `}
`;

export const FinalLook = ({ PAGE, SET_BACKGROUND, TITLE, IMG }) => {
  return (
    <FinalLookBase page={PAGE} setBackground={SET_BACKGROUND}>
      <Typography
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </Typography>
      <FinalLookImageWrapper page={PAGE}>
        <FinalLookImage src={IMG} page={PAGE} alt={'The Final Look'} />
      </FinalLookImageWrapper>
    </FinalLookBase>
  );
};
