import { Typography } from '../../../components';
import { AdvancedContents } from './AdvancedContents';

export const RowContents = ({ contents, PAGE, SECTION }) => {
  if (!contents) return null;

  return (
    <AdvancedContents page={PAGE} section={SECTION}>
      <Typography variant={'20/28'}>{contents}</Typography>
    </AdvancedContents>
  );
};
