import { windowResizeChanger } from '../utils';
import { theme } from '.';
import { importImg } from '../images';

export const PERSONAL_WEBSITE = () => ({
  PAGE: 'personal-website',
  PATHS: [
    { path: '/portfolio', text: 'Portfolio' },
    { path: '/portfolio/personalwebsite', text: 'Personal website design' },
  ],
  HEADER: {
    TITLE: 'Personal website design',
    SUBTITLE:
      'Empowering my creativity to design my portfolio that demonstrates my skills as a UX designer.',
    IMAGES: [importImg('PersonalWebsiteHeader.jpg')],
  },
  OVERVIEW: {
    TITLE: 'Overview',
    PROJECT_TIMELINE: 'Project Timeline',
    YEARS: windowResizeChanger({
      desktop: 'Jun 2021 - Dec 2021',
      tablet: (
        <>
          Start: <b>Jun 2021</b>
          <br />
          Finish: <b>Dec 2021</b>
        </>
      ),
      mobile: 'Jun 2021 - Dec 2021',
    }),
    MY_ROLE_TITLE: 'My role',
    MY_ROLE: 'Product manager\nUX designer\nVisual designer',
    CONTEXT_TITLE: 'Context',
    CONTEXT1:
      'I want to become a UX expert in the future and to achieve my goal I need to refine my skills in projects where UX is widely used in the work cycle. This website needs to help me to get into those projects.',
    CONTEXT2:
      'I tried to design it user-friendly and visually attractive, also tried to show the skills I learned all these years. I applied 5-stage Design Thinking methodology during the design process.',
    CONTEXT3:
      'I led the entire project and worked on all the design aspects, also I helped the developers to implement the entire website.',
  },
  PROBLEM: {
    TITLE: 'Problem',
    SUBTITLE:
      'I want to get into projects where UX is widely used in the work cycle. I need to demonstrate my UX experience and skills to get into those projects.',
  },
  CONSTRAINTS: {
    TITLE: 'Constraints',
    TOOLS: [
      {
        iconName: 'icon-time-box',
        iconColor: theme.colors.green,
        title: 'Limited time',
        content:
          'I had a full-time job and I was taking some online courses during the project. I could only spare a couple of hours a day and I had to use that time wisely.',
      },
      {
        iconName: 'icon-covid-box',
        iconColor: theme.colors.red,
        title: 'Unexpected delay',
        content:
          'I caught C19 during the design process and I had to delay the project for about 2 months until I could fully recover.',
      },
      {
        iconName: 'icon-star-box',
        iconColor: theme.colors.blue,
        title: 'Hard to get feedback',
        content:
          'It was hard to get feedback from the target audience. Most members of my target audience I know were too busy to spare time for reviews.',
      },
    ],
  },
  PROCESS: {
    SECTION: 'process',
    SET_BACKGROUND: true,
    TITLE: 'Design Process',
    TOOLS: [
      {
        column: windowResizeChanger({
          desktop: '1fr 1fr 1fr 1fr 1fr',
          mobile: '1fr',
        }),
        tools: [
          {
            iconName: 'icon-search-box',
            iconColor: theme.colors.rose,
            title: 'Empathize',
            content: windowResizeChanger({
              desktop: 'User research &\nCompetitor\nanalysis,',
              mobile: 'User research & Competitor analysis,',
            }),
            columnMode: false,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-target-box',
            iconColor: theme.colors.blue,
            title: 'Define',
            content: 'Target audeince',
            columnMode: false,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-lamp-box',
            iconColor: theme.colors.red,
            title: 'Ideate',
            content: windowResizeChanger({
              desktop: 'Tasks to do &\nUser flow',
              mobile: 'Tasks to do & User flow',
            }),
            columnMode: false,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-design-box',
            iconColor: theme.colors.green,
            title: 'Prototype',
            content: windowResizeChanger({
              desktop: 'Low-fi Wireframe\n& prototype',
              mobile: 'Low-fi Wireframe & prototype',
            }),
            columnMode: false,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-test-box',
            iconColor: theme.colors.rose,
            title: 'Test',
            content: 'Expert reviews',
            columnMode: false,
            mobileColumnMode: true,
          },
        ],
      },
    ],
  },
  COMPETITORS: {
    TITLE: 'I learned about my users & competitors',
    BOXES: [
      {
        title: 'User research.',
        content:
          'As I targetted international companies I couldn’t use research methods like user interviews or observations. So I decided to surf the internet and I was excited to find a lot of hiring tips and feedback from UX managers all around the world. These are points to analyze:',
        img: importImg('PersonalWebsiteCompetitor1.jpg'),
        items: [
          <>
            Hiring managers are <b>interested in case studies</b>, <b>not</b>{' '}
            just <b>the final look</b> of the design.
          </>,
          <>
            They want to see the candidates’ <b>thinking process</b> and
            understand how
            <b>candidates solve problems</b>.
          </>,
          <>
            They also desire to <b>see the results</b> and <b>impact</b> the
            design brought to the project or company.
          </>,
          <>
            They <b>don’t read</b>, they usually <b>scan</b> or <b>scim</b> case
            studies. If they find it interesting then they read.
          </>,
          <>
            They usually have <b>40+ candidates</b> per position,{' '}
            <b>1-2 per portfolio</b> and spend around <b>30sec</b> for each case
            study.
          </>,
        ],
      },
      {
        title: 'Comptetitor analysis.',
        content:
          'I also decided to see how professional UX designers have designed their websites. I checked around 70 portfolio websites and analyzed their layout, information architecture and the overall design. I discovered these tendencies in those websites:',
        img: importImg('PersonalWebsiteCompetitor2.jpg'),
        items: [
          <>
            <b>66</b> designers placed their <b>projects in the home page</b> of
            their websites.
          </>,
          <>
            <b>63</b> designers <b>introduced themselves</b> with a short
            message in the home page and <b>gave more details</b> in about page.
          </>,
          <>
            <b>57</b> designers had at least 2-3 case studies in their websites.
          </>,
          <>
            <b>65</b> designers used simple and beatifult layouts to design
            their websites.
          </>,
          <>
            Only around <b>9</b> designers provided testimonials in their
            website.
          </>,
        ],
      },
    ],
  },
  AUDIENCE: {
    TITLE: 'I defined my target audience',
    PERSONS: [
      {
        avatar: importImg('PersonalWebsiteJonathan.png'),
        name: 'Jonathan',
        subname: 'UX manager | United States',
        content:
          'Jonathan manages a design team of 12 designers and he is thinking about hiring a new UX designer for the new product they are launching. He received a shortlist of 5 to 10 UX designers. He usually spends around 30 seconds on each case study of a candidate. If he finds a case study interesting, then he spends about 15 minutes reading the details.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Wants to hire a creative UX designer with great problem-solving skills.',
            'Needs to know how decisions were made and based on what criteria.',
            'Wants to see the results of the work and its impact on the business.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'Frustrated to see the lack of attention to case studies.',
            'Upset because he sees final work more than the design process.',
          ],
        },
      },
      {
        avatar: importImg('PersonalWebsiteJuliana.png'),
        name: 'Juliana',
        subname: 'HR manager | United Arab Emirates',
        content:
          'Juliana has been an HR manager for 3 years now. She usually quickly scans candidates’ CVs and checks their portfolios. If she finds a matching candidate she adds them to her shortlist. She gets upset when links to the portfolio are broken. Sometimes she gets frustrated when she cannot find the information she needs in CVs or portfolios.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Needs to shortlist from 40-50 candidates for the UX managers.',
            'Wants to see well-crafted and appealing portfolios.',
            'Needs to make sure the candidates match the position requirements.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'Frustrated because of broken links to candidates’ portfolios.',
            'Sad because of the complex and hard-to-read layout of CVs and portfolios.',
          ],
        },
      },
    ],
  },
  BRAINSTORM: {
    TITLE: 'I brainstormed about the website design',
    CONTENT: 'I decided to do the following things:',
    TOOLS: [
      {
        iconName: 'icon-one-box',
        title: 'Case studies',
        content:
          'I need to provide well crafted case studies of my best projects.',
      },
      {
        iconName: 'icon-two-box',
        title: 'Simple layout',
        content: 'The layout should be very simple and easy to navigate.',
      },
      {
        iconName: 'icon-three-box',
        title: 'More visuals',
        content: 'I need to use more visuals and less text in the content.',
      },
      {
        iconName: 'icon-four-box',
        title: 'Testimonials',
        content: 'I need to provide testimonials from my clients and managers.',
      },
    ],
    IMG_NAME: 'The sitemap of the website',
    IMG: importImg('PersonalWebsiteBrainstorm.svg'),
  },
  WIREFRAME: {
    TITLE: 'I designed a low-fi wireframe prototype',
    CONTENT:
      'To be able send it to my target audience and receive their reivews and make changes based on that.',
    IMAGES: [
      importImg('PersonalWebsiteWireframe1.jpg'),
      importImg('PersonalWebsiteWireframe2.jpg'),
      importImg('PersonalWebsiteWireframe3.jpg'),
      importImg('PersonalWebsiteWireframe4.jpg'),
      importImg('PersonalWebsiteWireframe5.jpg'),
    ],
    LINK_BUTTON: 'CHECK THE WIREFRAME',
  },
  DISCOVERED: {
    SECTION: 'discovered',
    TITLE: 'What I discovered',
    CONTENTS: [
      'I faced a difficulty of finding participants for usability testing. So I decided to conduct Expert reviews. I received feedback from UX, marketing, hiring managers and seniour UX/UI designers. I was suggested to:',
    ],
    CONTENTS_ALIGN: windowResizeChanger({ desktop: 'center', tablet: 'left' }),
    TOOLS: [
      {
        column: windowResizeChanger({ desktop: '1fr 1fr 1fr', mobile: '1fr' }),
        tools: [
          {
            iconName: 'icon-one-box',
            iconColor: theme.colors.red,
            title: 'Move testimonials',
            content:
              'to the home page, and remove it from the main menu on top.',
            columnMode: false,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-two-box',
            iconColor: theme.colors.red,
            title: 'Keep text length',
            content:
              'under 75 characters per line to increase the readibility.',
            columnMode: false,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-three-box',
            iconColor: theme.colors.red,
            title: 'Remove my name',
            content: 'and title information from the main navigation menu.',
            columnMode: false,
            mobileColumnMode: true,
          },
        ],
      },
    ],
  },
  FINALLOOK: {
    SET_BACKGROUND: true,
    TITLE: 'The final look',
    IMG: importImg('PersonalWebsiteFinalLook.jpg'),
  },
  EXPERIENCE: {
    SET_BACKGROUND: false,
    TITLE: 'What I learned from this experience:',
    TOOLS: [
      {
        iconName: 'icon-one-box',
        title: 'How to apply UX methods',
        content:
          'This project helped me refine my UX skills. It was great opportunity to analyze and apply the best techniques during the design process.',
      },
      {
        iconName: 'icon-two-box',
        title: 'How to manage projects',
        content:
          'This project also helped me to test myself as a product manager. I learned more about product feasibility and viability. I can apply these skills in my future projects.',
      },
      {
        iconName: 'icon-three-box',
        title: 'How to get expert reviews',
        content:
          'I discovered www.adplist.org, a website where I can connect with industry professionals and schedule a meeting with them to get their feedback.',
      },
      {
        iconName: 'icon-four-box',
        title: 'How to write a case study',
        content:
          'It was very difficult to write case studies. I had to take deep research to gain some skills. I learned to structre, design and showcase case studies.',
      },
    ],
  },
});
