import styled from 'styled-components';
import { ToolBox } from '../components';
import { Container, Typography, media } from '../../components';
import { theme } from '../../const';

const BrainstormBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  justify-items: center;

  & > *:nth-child(2) {
    max-width: 57rem;
    margin: 1rem 0 5rem;

    ${media.tablet`
      margin: 1rem 0 3rem;
    `}

    ${media.mobile`
      margin-bottom: 2rem;
    `}
  }
`;

const ToolBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3rem;
  width: 100%;

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 3rem;
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, max-content);
    grid-row-gap: 2rem;
  `}
`;

const BrainstormImgName = styled(Typography)`
  margin: 10rem 0 3rem;

  ${media.tablet`
    margin-top: 5rem;
  `}

  ${media.mobile`
    margin: 3rem 0 1rem;
    line-height: 1.6rem;
  `}
`;

const BrainstormImgWrapper = styled.div`
  display: grid;
  justify-items: center;
  width: 100%;

  ${media.mobile`
    width: 100vw;
    margin-left: -1.5rem;
    padding: 0 1.5rem;

    position: relative;
    left: 0;
    overflow: overlay;
  }

    &::-webkit-scrollbar {
      display: none;
    }
  `}
`;

const BrainstormImg = styled.img`
  width: 100%;

  ${media.mobile`
    width: initial;
    height: 39rem;
  `}
`;

export const Brainstorm = ({ PAGE, TITLE, CONTENT, TOOLS, IMG_NAME, IMG }) => {
  return (
    <BrainstormBase>
      <Typography
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </Typography>
      <Typography variant={'20/28'}>{CONTENT}</Typography>
      <ToolBoxes>
        {TOOLS.map(({ iconName, title, content }, i) => (
          <ToolBox
            key={i}
            iconName={iconName}
            iconColor={theme.colors.green}
            title={title}
            content={content}
            tabletColumnMode
          />
        ))}
      </ToolBoxes>
      <BrainstormImgName variant={'20/28'}>{IMG_NAME}</BrainstormImgName>
      <BrainstormImgWrapper>
        <BrainstormImg src={IMG} alt={'Brainstorm'} />
      </BrainstormImgWrapper>
    </BrainstormBase>
  );
};
