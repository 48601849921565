export const theme = {
  colors: {
    white: '#FFFFFF',
    light: '#FAFAFA',
    lightGray: '#E5E7EA',
    gray500: '#0B114533',
    gray700: '#697684',
    gray900: '#A0A8B1',
    gray: '#788490',
    black600: '#141F29',
    black700: '#505050',
    black800: '#002335',
    black: '#000000',
    cyan700: '#00D6E2',
    cyan: '#40FCFF',
    azureRadiance: '#007EFF',
    lightBlue800: '#F7FAFF',
    lightBlue: '#F5F8FF',
    blue100: '#0057ff1a',
    blue200: '#0057ff08',
    blue400: '#0057ff33',
    blue500: '#2C539F',
    blue600: '#03A9F4',
    blue700: '#0384E0',
    blue800: '#0036FB',
    blue900: '#0572FF',
    blue: '#0057FF',
    darkBlue100: '#061C3308',
    darkBlue300: '#061c331a',
    darkBlue500: '#00428F',
    darkBlue600: '#1141E7',
    darkBlue700: '#103AEA',
    darkBlue800: '#211ACD',
    darkBlue900: '#2626D6',
    darkBlue: '#061C33',
    navyBlue700: '#03192F',
    navyBlue800: '#0B1145',
    navyBlue900: '#000E3E',
    navyBlue: '#0B1145',
    pictionBlue: '#4EB2E0',
    lightYellow: '#FDFCF0',
    yellow600: '#FFC414',
    yellow700: '#FFCB00',
    yellow800: '#FCD007',
    yellow900: '#FFC927',
    yellow: '#FFCA28',
    orange500: '#FAA41A',
    orange700: '#F4801E',
    orange: '#FF8F41',
    lightRed: '#FFF7F6',
    red: '#FF3D19',
    rose: '#D319FF',
    lightGreen900: '#04B6AF',
    lightGreen: '#F4FCF9',
    green600: '#67CE78',
    green800: '#4CAF50',
    green: '#3EC688',
  },
  font: {
    family: 'Gilroy',
    styleNormal: 'normal',
    // weight
    weight300: '300',
    weight400: '400',
    weightMedium: '500',
    weight600: '600',
    weightBold: 'bold',
    // size
    size14: '1.4rem',
    size16: '1.6rem',
    size18: '1.8rem',
    size20: '2rem',
    size24: '2.4rem',
    size30: '3rem',
    size32: '3.2rem',
    size35: '3.5rem',
    size40: '4rem',
    size48: '4.8rem',
    // lineHeight
    lineHeight16: '1.6rem',
    lineHeight17: '1.7rem',
    lineHeight18: '1.8rem',
    lineHeight19: '1.9rem',
    lineHeight20: '2rem',
    lineHeight22: '2.2rem',
    lineHeight24: '2.4rem',
    lineHeight28: '2.8rem',
    lineHeight29: '2.9rem',
    lineHeight30: '3rem',
    lineHeight36: '3.6rem',
    lineHeight38: '3.8rem',
    lineHeight40: '4rem',
    lineHeight42: '4.2rem',
    lineHeight48: '4.8rem',
    lineHeight49: '4.9rem',
    lineHeight58: '5.8rem',
    // letterSpacing
    letterSpacing0: '0',
    letterSpacing007: '0.07rem',
    letterSpacing008: '0.08rem',
    letterSpacing016: '0.16rem',
    letterSpacing04: '0.4rem',
    // transformUppercase
    textTransform: 'uppercase',
  },
  shadows: {
    0: '0 0.1rem 0.4rem #0000001F',
    1: '0 0.1rem 0.8rem #00000029',
    18: '0 0.2rem 0.4rem #00000029',
    2: '0 0.2rem 0.6rem #00000029',
    25: '0 0.3rem 0.9rem #69768433',
    3: '0 0.6rem 1.8rem #0057ff3D',
    4: '0 1rem 2rem #00000033',
    // 5: '0 3.2rem 4rem -0.2rem rgba(0, 0, 0, 0.08), 0 0.1rem 0.1rem 0 rgba(0, 0, 0, 0.08)',
  },
};
