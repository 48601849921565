import React from 'react';
import styled from 'styled-components';
import sprite from './sprite.svg';

export const Icon = ({ iconName }) => {
  return <use xlinkHref={`${sprite}#${iconName}`} />;
};

const IconSVGBase = styled.svg`
  width: ${(props) => props.size || props.width || '2.4rem'};
  height: ${(props) => props.size || props.height || '2.4rem'};
  fill: ${(props) => props.iconColor || 'inherit'};
`;

export const IconSVG = ({
  iconName,
  iconColor,
  size,
  width,
  height,
  ...props
}) => {
  return (
    <IconSVGBase
      className={'icon'}
      iconName={iconName}
      iconColor={iconColor}
      size={size}
      width={width}
      height={height}
      {...props}
    >
      <Icon iconName={iconName} />
    </IconSVGBase>
  );
};
