import { windowResizeChanger } from '../utils';
import { theme } from '.';
import { importImg } from '../images';

export const PERSONALLOGO = () => ({
  PAGE: 'personal-logo',
  PATHS: [
    { path: '/portfolio', text: 'Portfolio' },
    { path: '/portfolio/personallog', text: 'Personal logo design' },
  ],
  HEADER: {
    TITLE: 'Personal logo design',
    SUBTITLE:
      'Strived to apply all my skills and knowledge to design my personal brand logo with passion and enthusiasm.',
    IMAGES: [importImg('PersonalLogoHeader.jpg')],
  },
  OVERVIEW: {
    TITLE: 'Overview',
    PROJECT_TIMELINE: 'Project Timeline',
    YEARS: windowResizeChanger({
      desktop: 'Jan 2017 - May 2021',
      tablet: (
        <>
          Start: <b>Jan 2017</b>
          <br />
          Finish: <b>May 2021</b>
        </>
      ),
      mobile: 'Jan 2017 - May 2021',
    }),
    MY_ROLE_TITLE: 'My role',
    MY_ROLE: 'UX researcher\nGraphic designer',
    CONTEXT_TITLE: 'Context',
    CONTEXT1:
      'I help goal-driven companies to achieve great results by delivering simple and creative solutions that result in an amazing user experience. I realized to advertise myself, I need to have my personal brand, logo, and a portfolio website.',
    CONTEXT2:
      'I researched and analyzed my competitors, enhanced my skills by practicing designing logos for my clients and improving my logo on the go.',
    CONTEXT3:
      'Finally, by applying my research and graphic design skills I designed a simple, easy to remember, and very unique logo that represents my professionalism.',
  },
  PROBLEM: {
    SECTION: 'problem',
    SET_BACKGROUND: true,
    TITLE: 'Problem',
    TOOLS: [
      {
        column: windowResizeChanger({ desktop: '1fr 1fr', mobile: '1fr' }),
        tools: [
          {
            iconName: 'icon-one-box',
            iconColor: theme.colors.red,
            title: 'Need for professionals',
            content:
              'I want to lead my people, learn and teach how to provide professional design services. I wanted to give a start to my dream with my own branding. My logo must reflect my dream and must be crafted professionally.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
          {
            iconName: 'icon-two-box',
            iconColor: theme.colors.red,
            title: 'Difficulty of advertisement',
            content:
              'I want to shout out that I’m a UX designer with a big set of skills. But without my own branding, it is very hard to do. So the very beginning step of the big journey is to design my own logo by using UX methods in the process.',
            tabletRowMode: true,
            mobileColumnMode: true,
          },
        ],
      },
    ],
  },
  CONSTRAINTS: {
    TITLE: 'Constraints',
    TOOLS: [
      {
        iconName: 'icon-time-box',
        iconColor: theme.colors.green,
        title: 'Limited spared time',
        content:
          'I had a full-time job when I was working on the project. I could only spare a couple of hours a day and I had to use it wisely.',
      },
      {
        iconName: 'icon-book-box',
        iconColor: theme.colors.red,
        title: 'Constantly learning',
        content:
          'I always try to learn new things so I take courses on UX. I had to manage my free time very carefully to be able to finish my logo.',
      },
      {
        iconName: 'icon-re-time-box',
        iconColor: theme.colors.blue,
        title: 'Unexpected delays',
        content:
          'When I changed my workplace, when I had tight deadlines at work, I had to postpone the project several times.',
      },
    ],
  },
  AUDIENCE: {
    TITLE: 'I defined my target audience',
    PERSONS: [
      {
        avatar: importImg('PersonalWebsiteJonathan.png'),
        name: 'Jonathan',
        subname: 'UX manager | United States',
        content:
          'Jonathan manages a design team of 12 designers and he is thinking about hiring a new UX designer for the new product they are launching. He received a shortlist of 5 to 10 UX designers. He usually spends around 30 seconds on each case study of a candidate. If he finds a case study interesting, then he spends about 15 minutes reading the details.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Wants to hire a creative UX designer with great problem-solving skills.',
            'Needs to know how decisions were made and based on what criteria.',
            'Wants to see the results of the work and its impact on the business.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'Frustrated to see the lack of attention to case studies.',
            'Upset because he sees final work more than the design process.',
          ],
        },
      },
      {
        avatar: importImg('PersonalWebsiteJuliana.png'),
        name: 'Juliana',
        subname: 'HR manager | United Arab Emirates',
        content:
          'Juliana has been an HR manager for 3 years now. She usually quickly scans candidates’ CVs and checks their portfolios. If she finds a matching candidate she adds them to her shortlist. She gets upset when links to the portfolio are broken. Sometimes she gets frustrated when she cannot find the information she needs in CVs or portfolios.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Needs to shortlist from 40-50 candidates for the UX managers.',
            'Wants to see well-crafted and appealing portfolios.',
            'Needs to make sure the candidates match the position requirements.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'Frustrated because of broken links to candidates’ portfolios.',
            'Sad because of the complex and hard-to-read layout of CVs and portfolios.',
          ],
        },
      },
    ],
  },
  PROCESS: {
    TITLE: 'Process',
    TOOLS: [
      {
        iconName: 'icon-search-box',
        iconColor: theme.colors.green,
        title: 'Research',
        content:
          'I ran competitor analyses to see how others designed their logo.',
      },
      {
        iconName: 'icon-lamp-box',
        iconColor: theme.colors.red,
        title: 'Brainstorm',
        content: 'I brainstormed to come up with the best logo for my brand.',
      },
      {
        iconName: 'icon-design-box',
        iconColor: theme.colors.blue,
        title: 'Design',
        content: 'I designed several options and chose the best option.',
      },
      {
        iconName: 'icon-test-box',
        iconColor: theme.colors.yellow,
        title: 'Test',
        content: 'I tested the logo with the professionals around me.',
      },
    ],
  },
  RESEARCH: {
    SECTION: 'research',
    SET_BACKGROUND: true,
    TITLE: 'Research',
    TOOLS: [
      {
        column: '1fr',
        tools: [
          {
            title: 'Competitor analysis.',
            content:
              'I checked around 75 designers’ website and their logos to identify which logotype those designers are using. I tried to categorize them according to their types:',
          },
        ],
      },
      {
        column: '1fr',
        images: [importImg('PersonalLogoResearch.svg')],
      },
      {
        column: '1fr',
        contents:
          'The pie chart shows that word mark and letter mark logos are the most popular logotypes among designers. I saw many designers just use their full names as their logo.',
      },
    ],
  },
  BRAINSTORM: {
    SECTION: 'brainstorm',
    TITLE: windowResizeChanger({
      desktop: 'Brainstorm',
      tablet: 'Brainstorming',
    }),
    CONTENTS: windowResizeChanger({
      desktop: null,
      tablet: [
        'I decided to go for the combination mark logo. There are a number of reasons which influenced my decision:',
      ],
    }),
    TOOLS: [
      {
        column: windowResizeChanger({ desktop: '1fr 1fr', tablet: '1fr' }),
        tools: [
          {
            iconName: 'icon-one-box',
            iconColor: windowResizeChanger({
              desktop: theme.colors.red,
              tablet: theme.colors.green,
            }),
            title: 'Simple & creative',
            content:
              'My logo should be very simple and creative to reflect my personality. Also, it should be scalable to many sizes.',
          },
          {
            iconName: 'icon-two-box',
            iconColor: windowResizeChanger({
              desktop: theme.colors.red,
              tablet: theme.colors.green,
            }),
            title: 'Visually balanced',
            content:
              'The logo should be visually balanced. I can’t use my full name as a logo, because it contains 19 letters.',
          },
          {
            iconName: 'icon-three-box',
            iconColor: windowResizeChanger({
              desktop: theme.colors.red,
              tablet: theme.colors.green,
            }),
            title: 'Easy to remember',
            content:
              'It should be easy to remember. I wanted to make it so simple that people could easily remember it from the first look.',
          },
          {
            iconName: 'icon-four-box',
            iconColor: windowResizeChanger({
              desktop: theme.colors.red,
              tablet: theme.colors.green,
            }),
            title: 'Unique',
            content:
              'My logo must be unique. Just following the trend and copying other designers, will not help me stand out.',
          },
        ],
      },
    ],
  },
  DESIGN_SKETCHES: {
    SECTION: 'design-sketches',
    SET_BACKGROUND: true,
    TITLE: 'Design: Sketches',
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr 1fr',
          tools: [
            {
              content:
                'I started thinking about how I could design my logo. In the beginning, I used simple A and B letters to come up with some good ideas. #2 was a suggestion given by a senior graphic designer and I liked the idea initially.',
            },
            {
              content:
                'Later I discovered very good logos where negative space is used in a very clever way. It was very appealing to me, so I tried my own version of negative space logos.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('PersonalLogoDesignSketches1.jpg'),
            importImg('PersonalLogoDesignSketches2.jpg'),
            importImg('PersonalLogoDesignSketches3.jpg'),
            importImg('PersonalLogoDesignSketches4.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'I liked option #3 a lot so I decided to go with it and improve it further to see what it could turn in to be.',
            },
          ],
        },
      ],
      mobile: [
        {
          column: '1fr',
          tools: [
            {
              content:
                'I started thinking about how I could design my logo. In the beginning, I used simple A and B letters to come up with some good ideas. #2 was a suggestion given by a senior graphic designer and I liked the idea initially.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignSketches1.jpg'),
            importImg('PersonalLogoDesignSketches2.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'Later I discovered very good logos where negative space is used in a very clever way. It was very appealing to me, so I tried my own version of negative space logos.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignSketches3.jpg'),
            importImg('PersonalLogoDesignSketches4.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'I liked option #3 a lot so I decided to go with it and improve it further to see what it could turn in to be.',
            },
          ],
        },
      ],
    }),
  },
  DESIGN_CONCEPT: {
    SECTION: 'design-concept',
    TITLE: 'Design: Concept',
    CONTENTS: [
      'I tried to design the final look with the help of fonts. I chose Helvetica as it provided a natural look of the letters A and B.',
    ],
    TOOLS: [
      {
        column: '1fr',
        images: [importImg('PersonalLogoDesignConcept.svg')],
      },
      {
        column: '1fr',
        contents:
          'The look I got was not the one I was hoping for. So I kept refining the logo until I got the perfect look.',
      },
      {
        column: windowResizeChanger({
          desktop: '56.1fr 54.5fr',
          tablet: '1fr 1fr',
          mobile: '1fr',
        }),
        images: [
          importImg('PersonalLogoDesignConcept2.svg'),
          importImg('PersonalLogoDesignConcept3.svg'),
        ],
      },
    ],
  },
  DESIGN_TEST: {
    SECTION: 'design-test',
    SET_BACKGROUND: true,
    TITLE: 'Design: Test',
    CONTENTS: [
      'I wanted to make sure that the logo I choose was clear and understandable to people. So I tried different variations of the AB logo and tried to get feedback.',
    ],
    TOOLS: [
      {
        column: windowResizeChanger({
          desktop: '1fr 1fr 1fr 1fr',
          mobile: '1fr 1fr',
        }),
        images: [
          importImg('PersonalLogoDesignTest1.svg'),
          importImg('PersonalLogoDesignTest2.svg'),
          importImg('PersonalLogoDesignTest3.svg'),
          importImg('PersonalLogoDesignTest4.svg'),
        ],
      },
      {
        column: windowResizeChanger({
          desktop: '1fr 1fr',
          mobile: '1fr',
        }),
        tools: [
          {
            iconName: 'icon-one-box',
            iconColor: theme.colors.red,
            title: 'Feedback from professionals',
            content:
              'I did an expert review with my UX and Marketing managers. In addition, I also tried to get feedback from senior graphic and UX designers. Professionals selected #1 as the best option.',
          },
          {
            iconName: 'icon-two-box',
            iconColor: theme.colors.red,
            title: 'Feedback from friends',
            content:
              'I wanted to get more feedback so I showed the logo to my colleagues, family members, and friends as well. Again option #1 received the most votes.',
          },
        ],
      },
      {
        column: '1fr',
        contents:
          'I was more than happy with the logo now. So after I was done with the best shape I could get, I concentrated on the color.',
      },
    ],
  },
  DESING_COLOR: {
    SECTION: 'design-color',
    TITLE: 'Design: Color',
    CONTENTS: [
      'My brand represents simplicity, boldness, trust, inspiration, optimism, and high quality. Blue was the best option that the most closely represents my brand besides, it is also my personal color.',
    ],
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr 1fr',
          tools: [
            {
              content:
                'Based on my brand purpose and personality, I selected two colors, Cerulean blue, and Sunglow yellow.',
            },
            {
              content:
                'Below are the looks of the logo with the selected colors:',
            },
          ],
        },
        {
          column: '1fr 1fr 57rem',
          colorBoxes: [
            {
              color: theme.colors.blue600,
              title: 'Cerulean blue',
              content: 'Trust, dependability,\nreliable, responsible.',
            },
            {
              color: theme.colors.yellow,
              title: 'Sunglow yellow',
              content: 'Joy, happiness, optimism, inspiration.',
            },
          ],
          img: importImg('PersonalLogoDesignColor1.svg'),
        },
        {
          column: '1fr 1fr',
          tools: [
            {
              content:
                'I ran another session of expert review with a senior graphic designer. I was suggested to try Royal blue which is brighter and sharper than Cerulean blue.',
            },
            {
              content:
                'Royal blue came out to look a lot better than the old blue I had chosen.',
            },
          ],
        },
        {
          column: '23rem 5rem 23rem 57rem',
          height: '17.4rem',
          colorBoxes: [
            {
              color: theme.colors.blue600,
              title: 'Cerulean blue',
            },
            {
              color: theme.colors.blue,
              title: 'Royal blue',
            },
          ],
          img: importImg('PersonalLogoDesignColor2.svg'),
        },
      ],
      tablet: [
        {
          column: '1fr',
          contents:
            'Based on my brand purpose and personality, I selected two colors, Cerulean blue, and Sunglow yellow.',
        },
        {
          column: '1fr 1fr',
          colorBoxes: [
            {
              color: theme.colors.blue600,
              title: 'Cerulean blue',
              content: 'Trust, dependability, reliable, responsible.',
            },
            {
              color: theme.colors.yellow,
              title: 'Sunglow yellow',
              content: 'Joy, happiness, optimism, inspiration.',
            },
          ],
        },
        {
          column: '1fr',
          contents: 'Below are the looks of the logo with the selected colors:',
        },
        {
          column: windowResizeChanger({ tablet: '43.5rem', mobile: '1fr' }),
          img: importImg('PersonalLogoDesignColor1.svg'),
        },
        {
          column: '1fr',
          contents:
            'I ran another session of expert review with a senior graphic designer. I was suggested to try Royal blue which is brighter and sharper than Cerulean blue.',
        },
        {
          column: '1fr 2.5rem 1fr',
          colorBoxes: [
            {
              color: theme.colors.blue600,
              title: 'Cerulean blue',
            },
            {
              color: theme.colors.blue,
              title: 'Royal blue',
            },
          ],
        },
        {
          column: '1fr',
          contents:
            'Royal blue came out to look a lot better than the old blue I had chosen.',
        },
        {
          column: windowResizeChanger({ tablet: '45.4rem', mobile: '1fr' }),
          img: importImg('PersonalLogoDesignColor2.svg'),
        },
      ],
    }),
  },
  DESIGN_ACCESSIBILITY: {
    SECTION: 'design-accessibility',
    TITLE: 'Design: Accessibility',
    CONTENTS: [
      'I wanted to be sure about my logo’s contrast and accessibility.',
    ],
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr',
          contents:
            'For the contrast test, I visited www.webaim.org. I tested the blue logo on a white background and vice versa. In both scenarios my logo passed the test with good results:',
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibility1.jpg'),
            importImg('PersonalLogoDesignAccessibility2.jpg'),
          ],
        },
        {
          column: '1fr',
          contents:
            'For the accessibility test, I used an Adobe XD plugin called Stark. Fortunately, the logo did not have issues here as well:',
        },
        {
          column: '1fr',
          titles: ['Original'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityOriginal.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr 1fr',
          titles: ['Protanopia - red blind', 'Protanomaly - red weak'],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityProtanopia.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
            importImg('PersonalLogoDesignAccessibilityProtanomaly.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr 1fr',
          titles: ['Deuteranopia - green blind', 'Deuteranomaly - green weak'],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityDeuteranopia.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
            importImg('PersonalLogoDesignAccessibilityDeuteranomaly.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr 1fr',
          titles: ['Tritanopia - blue blind', 'Tritanomaly - blue weak'],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityTritanopia.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
            importImg('PersonalLogoDesignAccessibilityTritanomaly.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr 1fr',
          titles: [
            'Achromatopsia - all color blind',
            'Achromatomaly - all color weak',
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityAchromatopsia.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
            importImg('PersonalLogoDesignAccessibilityAchromatomaly.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
      ],
      mobile: [
        {
          column: '1fr',
          contents:
            'For the contrast test, I visited www.webaim.org. I tested the blue logo on a white background and vice versa. In both scenarios my logo passed the test with good results:',
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibility1.jpg'),
            importImg('PersonalLogoDesignAccessibility2.jpg'),
          ],
        },
        {
          column: '1fr',
          contents:
            'For the accessibility test, I used an Adobe XD plugin called Stark. Fortunately, the logo did not have issues here as well:',
        },
        {
          column: '1fr',
          titles: ['Original'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityOriginal.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr',
          titles: ['Protanopia - red blind'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityProtanopia.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr',
          titles: ['Protanomaly - red weak'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityProtanomaly.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr',
          titles: ['Deuteranopia - green blind'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityDeuteranopia.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr',
          titles: ['Deuteranomaly - green weak'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityDeuteranomaly.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr',
          titles: ['Tritanopia - blue blind'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityTritanopia.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr',
          titles: ['Tritanomaly - blue weak'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityTritanomaly.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr',
          titles: ['Achromatopsia - all color blind'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityAchromatopsia.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
        {
          column: '1fr',
          titles: ['Achromatomaly - all color weak'],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('PersonalLogoDesignAccessibilityAchromatomaly.svg'),
            importImg('PersonalLogoDesignAccessibilityWhite.svg'),
          ],
        },
      ],
    }),
  },
  FINALLOOK: {
    SET_BACKGROUND: true,
    TITLE: 'The final look',
    IMG: importImg('PersonalLogoFinalLook.jpg'),
  },
  EXPERIENCE: {
    SET_BACKGROUND: false,
    TITLE: windowResizeChanger({
      desktop: 'The benefits of this experience:',
      mobile: 'Benefits from this experience:',
    }),
    TOOLS: [
      {
        iconName: 'icon-one-box',
        title: 'Social media activity',
        content:
          'I started actively posting on Linkedin, Telegram, and Facebook. I run a channel and a UX community on Telegram. Soon I will start running my youtube channel.',
      },
      {
        iconName: 'icon-two-box',
        title: 'Confidence to start marketing',
        content:
          'Now I’m confident to start my own business and marketing on Instagram. I have huge plans for my brand and finally, the first steps are taken.',
      },
      {
        iconName: 'icon-three-box',
        title: 'Opportunity to practice UX',
        content:
          'I tried to look at branding from a UX perspective. I tried to use as many research methods as possible to practice things I learned from courses.',
      },
      {
        iconName: 'icon-four-box',
        title: 'UX dairy',
        content:
          'I started documenting everything I do for my career. I have a diary now and I call UX dairy. I’m trying to document as much as I can. I will need it in the future.',
      },
      {
        iconName: 'icon-five-box',
        title: 'Organize work',
        content:
          'I improved my time management skills a lot. I have a to-do list now and I try to stick to it as much as I can. It helps me prioritize tasks and efficiently spread out my time across different projects in my daily routine.',
      },
      {
        iconName: 'icon-six-box',
        title: 'Areas to grow',
        content:
          'Before I used to state that I did not like graphic design. But I changed my mind because during this project, I discovered a lot of very good graphic designers and I was inspired a lot. Now, when I have free time, I want to invest it into branding and brand strategy.',
      },
    ],
  },
});
