import styled from 'styled-components';
import { ToolBox } from '../../components';
import { Typography, media } from '../../../components';
import { theme } from '../../../const';

const ProcessBase = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;

  ${media.tablet`
    grid-row-gap: 3rem;
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, max-content);
    grid-row-gap: 2rem;
  `}
`;

const ProcessTitle = styled(Typography)`
  justify-self: center;
  grid-column: 1 / 5;

  ${media.mobile`
    grid-column: 1;
  `}
`;

export const Process = ({ PAGE, TITLE, TOOLS }) => {
  return (
    <ProcessBase page={PAGE}>
      <ProcessTitle variant={'40/48'} weight={theme.font.weight600}>
        {TITLE}
      </ProcessTitle>
      {TOOLS.map(({ iconName, iconColor, title, content }, i) => (
        <ToolBox
          key={i}
          iconName={iconName}
          iconColor={iconColor}
          title={title}
          content={content}
          mobileColumnMode
        />
      ))}
    </ProcessBase>
  );
};
