import { Container } from '../../components';
import { Navigation, ScrollToUpButton, Projects, Footer } from '../components';
import { Header } from './Header';
import { Testimonials } from './Testimonials';

export const Portfolio = () => {
  return (
    <>
      <Navigation />
      <ScrollToUpButton />
      <Container main>
        <Header />
        <Projects />
        <Testimonials />
        <Footer />
      </Container>
    </>
  );
};
