import styled, { css } from 'styled-components';
import { Container, Typography, media, sizes } from '../../components';
import { ColorBox } from '../components';
import { theme, BRANDINGPROJECTS } from '../../const';

const Project = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
  justify-items: center;
  width: 100%;

  ${(props) => css`
    & > *:nth-child(${2 + Number(!!props.backgroundCSS)}) {
      margin-top: 0;
    }

    & > *:nth-child(${4 + Number(!!props.backgroundCSS)}) {
      margin-top: 4rem;

      ${media.tablet`
        margin-top: 2rem;
      `}

      ${media.mobile`
        margin-top: 1rem;
      `}
    }
  `}
`;

const ProjectBackground = css`
  background-color: ${theme.colors.light};
`;

const ProjectContents = styled(Typography)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 1rem;

  width: 100%;
  max-width: 57.5rem;
  margin-top: 4rem;
  text-align: left;

  ${media.tablet`
    grid-row-gap: 1.5rem;
    max-width: 52.7rem;
    margin-top: 2rem; 
  `}

  ${media.mobile`
    grid-row-gap: 1rem;
    margin-top: 1rem; 
  `}
`;

const ProjectContentItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  grid-column-gap: 3rem;
  grid-row-gap: 1rem;

  width: 100%;
  max-width: 57.5rem;

  ${(props) =>
    props.contentItemsColumn &&
    css`
      grid-template-columns: ${props.contentItemsColumn};
    `}

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-row-gap: 0.5rem;
  `}
`;

// <- ///////////////////////////
const BrandCreateStepsBase = styled.div`
  display: grid;
  grid-auto-columns: 27rem;
  grid-auto-flow: column;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;
  justify-content: center;

  ${(props) =>
    props.createStepsWidth &&
    css`
      grid-auto-columns: 1fr;
      width: ${props.createStepsWidth};
    `}

  ${(props) =>
    !(props.createStepsLength % 2) &&
    media.tablet`
      grid-auto-flow: initial;
      grid-template-columns: 1fr 1fr;
    `}

  ${media.tablet`
    margin-top: 2rem;
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-auto-flow: row;
    grid-row-gap: 1rem;
    margin-top: 0;
  `}
`;

const Step = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
  justify-items: center;

  ${media.mobile`
    ${(props) =>
      props.mobileColumnMode &&
      css`
        grid-template-columns: max-content 1fr;
        grid-auto-flow: column;
        grid-column-gap: 1.5rem;
        align-items: flex-start;
        justify-items: initial;
      `}
  `}

  ${media.mobile`
    ${(props) =>
      props.mobileRowMode &&
      css`
        justify-items: left;
      `}
  `}
`;

const StepImg = styled.img`
  ${(props) => css`
    width: ${props.imgWidth || '100%'};
    height: ${props.imgHeight};
  `}

  ${media.mobile`
    ${(props) => css`
      width: ${props.imgWidth || 'max-content'};
    `}

    ${(props) =>
      props.projectName === 'medyear' &&
      css`
        margin-bottom: -0.5rem;
      `}
  `}
`;

const BrandCreateSteps = ({ createSteps, createStepsWidth, projectName }) => {
  return (
    <BrandCreateStepsBase
      createStepsWidth={createStepsWidth}
      createStepsLength={createSteps.length}
    >
      {createSteps.map(
        (
          {
            img,
            imgWidth,
            imgHeight,
            content,
            mobileColumnMode,
            mobileRowMode,
          },
          i
        ) => (
          <Step
            key={i}
            mobileColumnMode={mobileColumnMode}
            mobileRowMode={mobileRowMode}
          >
            <StepImg
              src={img}
              imgWidth={imgWidth}
              imgHeight={imgHeight}
              projectName={projectName}
              alt={'Step'}
            />
            <Typography variant={'20/28'}>{content}</Typography>
          </Step>
        )
      )}
    </BrandCreateStepsBase>
  );
};

const ColorBoxesBase = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 3rem;
  width: 100%;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-auto-flow: row;
    grid-row-gap: 1.5rem;
  `}
`;

const ColorBoxes = ({ colorBoxes }) => {
  return (
    <ColorBoxesBase>
      {colorBoxes.map(({ color, border, title, content }, i) => (
        <ColorBox
          key={i}
          color={color}
          title={title}
          content={content}
          border={border}
          noBorder
        />
      ))}
    </ColorBoxesBase>
  );
};

const TheFinalLook = styled(Typography)`
  margin-top: 4rem;
  justify-self: left;

  ${media.tablet`
    margin-top: 2rem;
  `}

  @media (max-width: ${sizes.mobile}px) {
    margin-top: 1rem;
    font-weight: ${theme.font.weight600};
  }
`;

const TheFinalLookImg = styled.img`
  width: 100%;

  ${media.mobile`
    margin-top: -0.5rem;
  `}
`;

export const Projects = ({ PAGE }) => {
  return BRANDINGPROJECTS().PROJECTS.map(
    (
      {
        setBackground,
        title,
        contents1,
        contentItemsColumn,
        contentItems,
        contents2,
        createStepsWidth,
        createSteps,
        contents3,
        colorBoxes,
        theFinalLook,
        theFinalLookImg,
        projectName,
      },
      i
    ) => (
      <Project key={i} backgroundCSS={setBackground && ProjectBackground}>
        <Typography variant={'40/48'} weight={theme.font.weight600}>
          {title}
        </Typography>
        <ProjectContents variant={'20/28'}>{contents1}</ProjectContents>
        <ProjectContentItems contentItemsColumn={contentItemsColumn}>
          {contentItems.map((contentItem, i) => (
            <Typography key={i} variant={'20/28'} listItem>
              {contentItem}
            </Typography>
          ))}
        </ProjectContentItems>
        {contents2 && (
          <ProjectContents variant={'20/28'}>{contents2}</ProjectContents>
        )}
        <BrandCreateSteps
          createSteps={createSteps}
          createStepsWidth={createStepsWidth}
          projectName={projectName}
        />
        <ProjectContents variant={'20/28'}>{contents3}</ProjectContents>
        <ColorBoxes colorBoxes={colorBoxes} />
        <TheFinalLook variant={'24/29'} weight={theme.font.weightBold}>
          {theFinalLook}
        </TheFinalLook>
        <TheFinalLookImg src={theFinalLookImg} alt={'The Final Look'} />
      </Project>
    )
  );
};
