import styled from 'styled-components';
import { Typography, media } from '../../components';
import { theme, ABOUT } from '../../const';

const HobbiesBase = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;

  ${media.tablet`
    grid-row-gap: 1.5rem;
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  `}
`;

const HobbiesTitle = styled(Typography)`
  grid-column: 1 / 3;
  margin-bottom: 2rem;

  ${media.tablet`
    margin-bottom: 1.5rem;
  `}

  ${media.mobile`
    grid-column: 1;
    margin-bottom: 0;
  `}
`;

const HobbiesImg = styled.img`
  width: 100%;

  ${media.mobile`
    &:nth-child(3) {
      grid-row: 4;
      margin-top: 2rem;
    }
  `}
`;

export const Hobbies = () => {
  return (
    <HobbiesBase>
      <HobbiesTitle
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {ABOUT().HOBBIES.TITLE}
      </HobbiesTitle>
      {ABOUT().HOBBIES.IMAGES &&
        ABOUT().HOBBIES.IMAGES.map((img, i) => (
          <HobbiesImg key={i} src={img} alt={'Hobbies'} />
        ))}
      {ABOUT().HOBBIES.CONTENTS.map((content, i) => (
        <Typography key={i} variant={'20/28'}>
          {content}
        </Typography>
      ))}
    </HobbiesBase>
  );
};
