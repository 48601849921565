import styled, { css } from 'styled-components';
import {
  Container,
  LinkButton,
  Typography,
  IconSVG,
  media,
  sizes,
} from '../../components';
import { theme, ABOUT } from '../../const';

const CertificatesBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  justify-items: center;
  width: 100%;

  ${media.tablet`
    padding-bottom: 3rem;
  `}

  ${media.mobile`
    padding-bottom: 1.5rem;
  `}
`;

const CertificatesBackground = css`
  background-color: ${theme.colors.light};
`;

const CertificatesSubtitle = styled(Typography)`
  max-width: 43.9rem;
  margin: 2rem 0 5rem;

  ${media.tablet`
    max-width: 57.3rem;
    margin: 1rem 0 3rem;
  `}

  ${media.mobile`
    margin: 0.4rem 0 1rem;
  `}
`;

const CertificatesCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: max-content;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;

  ${media.tablet`
    grid-template-columns: repeat(3, 1fr);
  `}

  ${media.smallTablet`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-row-gap: 1.5rem;
    width: 100%;
  `}
`;

const CardBase = styled.div`
  display: grid;
  ${(props) => css`
    grid-template-rows: repeat(${props.contentsLength + 1}, max-content) 1fr;
  `};

  padding: 2rem;
  border-radius: 0.5rem;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows[0]};
`;

const CardIcon = styled(IconSVG)`
  ${media.tablet`
    width: 8rem;
    height: 8rem;
  `}

  ${media.mobile`
    width: 6rem;
    height: 6rem;  
  `}
`;

const CardTitle = styled(Typography)`
  margin-top: 1rem;

  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size16};
    line-height: ${theme.font.lineHeight19};
  }
`;

const CardContent = styled(Typography)`
  margin-top: 0.6rem;

  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size14};
    line-height: ${theme.font.lineHeight16};
    margin-top: 0.4rem;
  }
`;

const CardLink = styled(LinkButton)`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 0.4rem;
  align-items: center;
  width: max-content;
  height: max-content;
  margin-top: 2rem;
  text-decoration: none;
  color: ${theme.colors.blue};
`;

const CardLinkIcon = styled(IconSVG)`
  width: 1.6rem;
  height: 1rem;
  transition: margin 0.3s;

  ${CardLink}:hover > & {
    margin-left: 1rem;
  }
`;

const Card = ({ iconName, title, contents, link }) => {
  return (
    <CardBase contentsLength={contents.length}>
      <CardIcon iconName={iconName} size={'9.2rem'} />
      <CardTitle variant={'20/24'} weight={theme.font.weightBold}>
        {title}
      </CardTitle>
      {contents.map((content, i) => (
        <CardContent key={i} variant={'16/19'}>
          {content}
        </CardContent>
      ))}
      <CardLink variant={'link'} href={link} onlyText>
        {ABOUT().CERTIFICATES.VIEW_CERTIFICATE}
        <CardLinkIcon iconName={'icon-blue-right-arrow2'} />
      </CardLink>
    </CardBase>
  );
};

export const Certificates = () => {
  return (
    <CertificatesBase backgroundCSS={CertificatesBackground}>
      <Typography variant={'40/48'} weight={theme.font.weight600}>
        {ABOUT().CERTIFICATES.TITLE}
      </Typography>
      <CertificatesSubtitle variant={'20/28'} align={'center'}>
        {ABOUT().CERTIFICATES.SUBTITLE}
      </CertificatesSubtitle>
      <CertificatesCards>
        {ABOUT().CERTIFICATES.CARDS.map(
          ({ iconName, title, contents, link }, i) => (
            <Card
              key={i}
              iconName={iconName}
              title={title}
              contents={contents}
              link={link}
            />
          )
        )}
      </CertificatesCards>
    </CertificatesBase>
  );
};
