import { Container } from '../../components';
import {
  PathNavigation,
  ScrollToUpButton,
  Header,
  Overview,
  Problem,
  Constraints,
  Audience,
  Process,
  FinalLook,
  Experience,
  Projects,
  AdvancedSection,
} from '../components';
import { JOWIOWNERAPP } from '../../const';

export const JowiOwnerApp = () => {
  return (
    <>
      <PathNavigation paths={JOWIOWNERAPP().PATHS} />
      <ScrollToUpButton />
      <Container main>
        <Header PAGE={JOWIOWNERAPP().PAGE} {...JOWIOWNERAPP().HEADER} />
        <Overview PAGE={JOWIOWNERAPP().PAGE} {...JOWIOWNERAPP().OVERVIEW} />
        <Problem PAGE={JOWIOWNERAPP().PAGE} {...JOWIOWNERAPP().PROBLEM} />
        <Constraints
          PAGE={JOWIOWNERAPP().PAGE}
          {...JOWIOWNERAPP().CONSTRAINTS}
        />
        <Audience PAGE={JOWIOWNERAPP().PAGE} {...JOWIOWNERAPP().AUDIENCE} />
        <Process PAGE={JOWIOWNERAPP().PAGE} {...JOWIOWNERAPP().PROCESS} />
        <AdvancedSection
          PAGE={JOWIOWNERAPP().PAGE}
          {...JOWIOWNERAPP().RESEARCH}
        />
        <AdvancedSection
          PAGE={JOWIOWNERAPP().PAGE}
          {...JOWIOWNERAPP().BRAINSTORMING}
        />
        <AdvancedSection
          PAGE={JOWIOWNERAPP().PAGE}
          {...JOWIOWNERAPP().DESIGN}
        />
        <AdvancedSection PAGE={JOWIOWNERAPP().PAGE} {...JOWIOWNERAPP().TEST} />
        <FinalLook PAGE={JOWIOWNERAPP().PAGE} {...JOWIOWNERAPP().FINALLOOK} />
        <Experience PAGE={JOWIOWNERAPP().PAGE} {...JOWIOWNERAPP().EXPERIENCE} />
        <Projects
          PAGE={JOWIOWNERAPP().PAGE}
          showProjects={[4, 6]}
          otherProjects
        />
      </Container>
    </>
  );
};
