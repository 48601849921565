import styled, { css } from 'styled-components';
import { Typography, IconSVG, media } from '../../../components';
import { theme } from '../../../const';

const ToolBoxBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 2rem;

  ${(props) =>
    props.columnMode &&
    css`
      grid-template-columns: max-content 1fr;
      grid-column-gap: 3rem;

      ${media.tablet`
        grid-column-gap: 2rem;
      `}

      ${media.mobile`
        grid-column-gap: 1.5rem;
      `}
    `}

  ${media.tablet`
    ${(props) =>
      props.tabletColumnMode &&
      css`
        grid-template-columns: max-content 1fr;
        grid-column-gap: 2rem;
      `}

    ${(props) =>
      props.tabletColumnMode &&
      !props.title &&
      css`
        & > *:nth-child(2) {
          align-self: center;
        }
      `}

    ${(props) =>
      props.tabletRowMode &&
      css`
        grid-template-columns: initial;
        grid-row-gap: 1rem;
      `}
    `}

  ${media.mobile`
    ${(props) =>
      props.mobileColumnMode &&
      css`
        grid-template-columns: max-content 1fr;
        grid-column-gap: 1.5rem;
      `}

    ${(props) =>
      props.mobileColumnMode &&
      !props.title &&
      css`
        & > *:nth-child(2) {
          align-self: center;
        }
      `}

    ${(props) =>
      props.mobileRowMode &&
      css`
        grid-template-columns: 1fr;
        grid-row-gap: 1rem;
      `}
  `}

  ${(props) =>
    !props.iconName &&
    css`
      grid-template-columns: initial;
    `}
`;

const ToolIcon = styled(IconSVG)`
  width: 7rem;
  height: 7rem;

  ${media.tablet`
    width: 6rem;
    height: 6rem;
  `}

  ${media.mobile`
    width: 4rem;
    height: 4rem;
  `}
`;

const ToolBoxMain = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 1rem;

  ${media.mobile`
    grid-row-gap: 0.2rem;
  `}
`;

const ToolContent = styled(Typography)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 1rem;

  ${media.tablet`
    grid-row-gap: 1.5rem;
  `}

  ${media.mobile`
    grid-row-gap: 1rem;
  `}
`;

export const ToolBox = ({
  iconName,
  iconColor,
  title,
  content,
  columnMode,
  tabletColumnMode,
  tabletRowMode,
  mobileColumnMode,
  mobileRowMode,
  children,
}) => {
  return (
    <ToolBoxBase
      columnMode={columnMode}
      tabletColumnMode={tabletColumnMode}
      tabletRowMode={tabletRowMode}
      mobileColumnMode={mobileColumnMode}
      mobileRowMode={mobileRowMode}
      iconName={iconName}
      title={title}
    >
      {iconName && <ToolIcon iconName={iconName} iconColor={iconColor} />}
      <ToolBoxMain>
        {title && (
          <Typography variant={'24/29'} weight={theme.font.weightBold}>
            {title}
          </Typography>
        )}
        <ToolContent variant={'20/28'}>{content}</ToolContent>
        {children}
      </ToolBoxMain>
    </ToolBoxBase>
  );
};
