import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { windowResizeChanger } from '../../utils';
import { IconSVG } from '../icon-svg';

const LogoBase = styled(IconSVG)`
  cursor: pointer;

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;
    `}
`;

const sizes = () => ({
  big: { width: '15.3rem', height: '12rem' },
  normal: windowResizeChanger({
    desktop: { width: '5.1rem', height: '4rem' },
    mobile: { width: '3.6rem', height: '2.8rem' },
  }),
});

export const StartupLogo = ({ size = 'big' }) => {
  return (
    <LogoBase
      iconName={'icon-logo'}
      width={sizes()[size].width}
      height={sizes()[size].height}
      disabled
    />
  );
};

export const Logo = ({ size = 'normal', disabled = false, ...props }) => {
  const historyPush = useNavigate();

  const handleOnClick = useCallback(() => {
    if (!disabled) historyPush('/portfolio');
  }, [historyPush, disabled]);

  return (
    <LogoBase
      iconName={'icon-logo'}
      width={sizes()[size].width}
      height={sizes()[size].height}
      onClick={handleOnClick}
      {...props}
    />
  );
};
