import { Container } from '../../components';
import {
  PathNavigation,
  ScrollToUpButton,
  Header,
  Overview,
  Problem,
  Constraints,
  Audience,
  Process,
  FinalLook,
  Experience,
  Projects,
  AdvancedSection,
} from '../components';
import { DRIVERAPP } from '../../const';

export const DriverApp = () => {
  return (
    <>
      <PathNavigation paths={DRIVERAPP().PATHS} />
      <ScrollToUpButton />
      <Container main>
        <Header PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().HEADER} />
        <Overview PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().OVERVIEW} />
        <Problem PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().PROBLEM} />
        <Constraints PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().CONSTRAINTS} />
        <Audience PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().AUDIENCE} />
        <Process PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().PROCESS} />
        <AdvancedSection PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().RESEARCH} />
        <AdvancedSection
          PAGE={DRIVERAPP().PAGE}
          {...DRIVERAPP().BRAINSTORMING}
        />
        <AdvancedSection PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().DESIGN} />
        <AdvancedSection PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().TEST} />
        <FinalLook PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().FINALLOOK} />
        <Experience PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().EXPERIENCE} />
        <AdvancedSection PAGE={DRIVERAPP().PAGE} {...DRIVERAPP().NOTWORK} />
        <Projects showProjects={[1, 3]} otherProjects />
      </Container>
    </>
  );
};
