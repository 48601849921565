import styled from 'styled-components';
import { ToolBox } from '../components';
import { Container, Typography, media } from '../../components';
import { theme } from '../../const';

const BrainstormingBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  justify-items: center;

  & > *:nth-child(3) {
    max-width: 57rem;
    margin: 1rem 0 5rem;

    ${media.tablet`
      max-width: 100%;
      margin: 1rem 0 2rem;
    `}

    ${media.mobile`
      margin: 1rem 0;
    `}
  }
`;

const ToolBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 3rem;

  ${media.tablet`
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  `}
`;

export const Brainstorming = ({
  PAGE,
  TITLE,
  CONTENT,
  REMOVE_TITLE,
  REMOVE,
  PROVIDE_TITLE,
  PROVIDE,
}) => {
  return (
    <BrainstormingBase backgroundLight>
      <Typography
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </Typography>
      <Typography variant={'20/28'}>{CONTENT}</Typography>
      <ToolBoxes>
        <ToolBox
          iconName={'icon-one-box'}
          iconColor={theme.colors.red}
          title={REMOVE_TITLE}
          content={REMOVE}
          columnMode
        />
        <ToolBox
          iconName={'icon-two-box'}
          iconColor={theme.colors.red}
          title={PROVIDE_TITLE}
          content={PROVIDE}
          columnMode
        />
      </ToolBoxes>
    </BrainstormingBase>
  );
};
