import styled, { css } from 'styled-components';
import { ToolBox } from '../components';
import { Container, Typography, media } from '../../components';
import { theme } from '../../const';

const ResearchBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  justify-items: center;

  & > *:nth-child(3) {
    max-width: 57rem;
    margin: 1rem 0 5rem;

    ${media.tablet`
      max-width: 52.7rem;
      margin: 1rem 0 3rem;
    `}

    ${media.mobile`
      margin: 1rem 0;
    `}
  }
`;

const ResearchBackground = css`
  background-color: ${theme.colors.light};
`;

const ToolBoxes = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;

  ${media.tablet`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, max-content);
    grid-row-gap: 2rem;
    grid-auto-flow: column;
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-row-gap: 1.5rem;
    grid-auto-flow: row;
  `}
`;

export const Research = ({ PAGE, TITLE, CONTENT, TOOLS }) => {
  return (
    <ResearchBase backgroundCSS={ResearchBackground}>
      <Typography
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </Typography>
      <Typography variant={'20/28'}>{CONTENT}</Typography>
      <ToolBoxes>
        {TOOLS.map(({ iconName, content }, i) => (
          <ToolBox
            key={i}
            iconName={iconName}
            iconColor={theme.colors.red}
            content={content}
            tabletColumnMode
          />
        ))}
      </ToolBoxes>
    </ResearchBase>
  );
};
