import { windowResizeChanger } from '../utils';
import { theme } from '.';
import { importImg } from '../images';

export const PORTFOLIO = () => ({
  HEADER: {
    TEXT1: 'I’m Abdulaziz, a senior UX designer at ',
    MEDYEAR: 'Medyear',
    TEXT2:
      'I enjoy designing user-centric, creative and innovative interfaces that result in a great ',
    USER_EXPERIENCE: 'User Experience',
    TEXT3: 'Previously at ',
    AMAZON: 'Amazon',
    ABOUT_ME: 'READ ABOUT ME',
    IMG: windowResizeChanger({
      desktop: importImg('PortfolioHeader.jpg'),
      mobile: importImg('PortfolioHeaderMobile.jpg'),
    }),
  },
  PROJECTS: {
    TITLE: 'My Portfolio',
    OTHER_TITLE: 'Other Projects',
    CARD_LINK_TEXT: 'CASE STUDY',
    CARDS: [
      {
        title: 'Usability testing for Medypass',
        content:
          'Discovered how our innovative sharing features were affecting our users and made changes that resulted in a better user experience.',
        link: '/portfolio/medypass',
        img: windowResizeChanger({
          desktop: importImg('ProjectsMedypass.png'),
          mobile: importImg('ProjectsMedypassMobile.png'),
        }),
        color: theme.colors.lightGreen,
      },
      {
        title: 'Medyear Pro web system redesign',
        content:
          'Redesigned the web system for healthcare professionals to make the interaction with their patients easy, simple, and more effective.',
        link: '/portfolio/medyearpro',
        img: windowResizeChanger({
          desktop: importImg('ProjectsMedyear.png'),
          mobile: importImg('ProjectsMedyearMobile.png'),
        }),
        color: theme.colors.lightRed,
      },
      {
        title: 'Wing driver app redesign',
        content:
          'Reimagined how drivers perform deliveries with a different approach that contributed to a rise in efficiency and a drop in delivery time.',
        link: '/portfolio/driverapp',
        img: windowResizeChanger({
          desktop: importImg('ProjectsDriverApp.png'),
          mobile: importImg('ProjectsDriverAppMobile.png'),
        }),
        color: theme.colors.lightBlue,
      },
      {
        title: 'Wing Customer app redesign',
        content:
          'Strived to find the best solution that helps Wing enhance the usability of its delivery management system, based on the new business model.',
        link: '/portfolio/customerapp',
        img: windowResizeChanger({
          desktop: importImg('ProjectsCustomerApp.png'),
          mobile: importImg('ProjectsCustomerAppMobile.png'),
        }),
        color: theme.colors.lightYellow,
      },
      {
        title: 'Personal website design',
        content:
          'Empowered my creativity to design my portfolio website to demonstrate my skills as a UX designer.',
        link: '/portfolio/personalwebsite',
        img: windowResizeChanger({
          desktop: importImg('ProjectsPersonalWebsite.png'),
          mobile: importImg('ProjectsPersonalWebsiteMobile.png'),
        }),
        color: theme.colors.lightGreen,
      },
      {
        title: 'Jowi Owner app design',
        content:
          'Designed an app that helps restaurant owners manage their businesses in a very efficient way.',
        link: '/portfolio/jowiownerapp',
        img: windowResizeChanger({
          desktop: importImg('ProjectsJowiOwnerApp.png'),
          mobile: importImg('ProjectsJowiOwnerAppMobile.png'),
        }),
        color: theme.colors.lightRed,
      },
      {
        title: 'Personal logo design',
        content:
          'Strived to apply all my skills and knowledge to design my personal brand logo with passion and enthusiasm.',
        link: '/portfolio/personallogo',
        img: windowResizeChanger({
          desktop: importImg('ProjectsPersonalLogo.png'),
          mobile: importImg('ProjectsPersonalLogoMobile.png'),
        }),
        mobileImgCenter: true,
        color: theme.colors.lightBlue,
      },
      {
        title: windowResizeChanger({
          desktop: 'Logo design projects',
          mobile: 'Branding side projects',
        }),
        content:
          'Branding is my passion and it is my sidegig thing. Over the years I have designed a series of logos for companies from different parts of the world.',
        link: '/portfolio/brandingprojects',
        img: windowResizeChanger({
          desktop: importImg('ProjectsBranding.png'),
          mobile: importImg('ProjectsBrandingMobile.png'),
        }),
        color: theme.colors.lightYellow,
      },
    ],
  },
  TESTIMONIALS: {
    TITLE: 'Testimonials',
    CARDS: [
      {
        content:
          'Abdulaziz is a very talented UI/UX designer with a great work ethic.',
        name: 'Panya Chheng',
        subname: 'Co-founder | Medyear',
        linkedin: 'https://www.linkedin.com/in/pchheng/',
        instagram: 'https://www.instagram.com/panyachheng/',
        facebook: 'https://www.facebook.com/pchheng',
        img: importImg('PortfolioPanyaChheng.jpg'),
      },
      {
        content:
          'I was lucky enough to have the opportunity to pick his brain and learn about design.',
        name: 'Radu Iftodi',
        subname: 'Engineering Manager | Medyear',
        linkedin: 'https://www.linkedin.com/in/radu-iftodi-428a1486/',
        instagram: 'https://www.instagram.com/iftodiradu/',
        facebook: 'https://www.facebook.com/iftodi.raduemanuel',
        img: importImg('PortfolioRaduIftodi.jpg'),
      },
      {
        content:
          'Ambitious, enthusiastic, energetic, hardworking young talent.',
        name: 'DJ Makhkamov',
        subname: 'Product manager | Wing (Amazon)',
        linkedin: 'https://www.linkedin.com/in/djahon/',
        instagram: 'https://www.instagram.com/dmakhkamov/',
        facebook: 'https://www.facebook.com/djohamuzone',
        img: importImg('PortfolioDJMakhkamov.jpg'),
      },
      {
        content:
          'Abdulaziz is wonderful to work with and has exceptional expertise in UI and UX.',
        name: 'Mahmoud Yossif',
        subname: 'Marketing manager | Wing (Amazon)',
        linkedin: 'https://www.linkedin.com/in/mahmoudyi/',
        instagram: 'https://www.instagram.com/mahmoud.yi/',
        facebook: 'https://www.facebook.com/Mahmoudyi00',
        img: importImg('PortfolioMahmoudYossif.jpg'),
      },
    ],
  },
  FOOTER: {
    TITLE: 'Let’s Connect',
    CONTENT:
      'Feel free to reach out for collaborations or just a friendly hello 😀',
    EMAIL: 'abdulaziz.bekturdiev@gmail.com',
    LINKEDIN: 'https://www.linkedin.com/in/abdulazizbekturdiev/',
    INSTAGRAM: 'https://www.instagram.com/abdulaziz_bekturdiev/',
    DRIBBBLE: 'https://dribbble.com/abdulaziz_bekturdiev',
    BEHANCE: 'https://www.behance.net/abdulazizbekturdiev',
  },
});
