import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { debounce, throttle } from 'lodash';

export const useScroll = (
  { minScrollTop, unStop } = { minScrollTop: 0, unStop: false }
) => {
  const rootEl = useRef(document.getElementById('root'));
  const scrollTop = useRef(0);
  const isScrollStopRef = useRef(
    !unStop || minScrollTop < rootEl.current.scrollTop
  );
  const scrollToRef = useRef('');
  const [scorllTo, setScrollTo] = useState('');
  const [isScrollStop, setIsScrollStop] = useState(
    !unStop || minScrollTop < rootEl.current.scrollTop
  );

  const handleScrollStop = useCallback(() => {
    if (unStop && minScrollTop >= rootEl.current.scrollTop) return;

    isScrollStopRef.current = true;
    setIsScrollStop(true);
    scrollToRef.current = '';
    setScrollTo('');
  }, [unStop, minScrollTop]);

  const handleScrollUnStop = () => {
    isScrollStopRef.current = false;
    setIsScrollStop(false);
    scrollToRef.current = '';
    setScrollTo('');
  };

  const debounceScrollStop = useMemo(
    () => debounce(handleScrollStop, 500),
    [handleScrollStop]
  );

  const handleScrollTo = useCallback(() => {
    if (
      scrollToRef.current !== 'bottom' &&
      scrollTop.current < rootEl.current.scrollTop
    ) {
      scrollToRef.current = 'bottom';
      setScrollTo('bottom');
    }

    if (
      scrollToRef.current !== 'top' &&
      scrollTop.current > rootEl.current.scrollTop
    ) {
      scrollToRef.current = 'top';
      setScrollTo('top');
    }

    scrollTop.current = rootEl.current.scrollTop;
  }, []);

  const throttleScorllTo = useMemo(
    () => throttle(handleScrollTo, 200),
    [handleScrollTo]
  );

  useEffect(() => {
    let rootElement = !!rootEl.current ? rootEl.current : null;

    const handleOnScroll = (e) => {
      if (unStop && minScrollTop >= rootEl.current.scrollTop) {
        if (isScrollStopRef.current || scrollToRef.current)
          handleScrollUnStop();
        return;
      }

      if (minScrollTop >= e.target.scrollTop) {
        if (!isScrollStopRef.current) handleScrollStop();
        return;
      }

      if (isScrollStopRef.current) {
        isScrollStopRef.current = false;
        setIsScrollStop(false);
      }

      throttleScorllTo();
      debounceScrollStop();
    };

    rootElement.addEventListener('scroll', handleOnScroll);
    return () => rootElement.removeEventListener('scroll', handleOnScroll);
  }, [
    minScrollTop,
    unStop,
    handleScrollStop,
    throttleScorllTo,
    debounceScrollStop,
  ]);

  return [scorllTo, isScrollStop];
};
