import { theme } from '.';
import { importImg } from '../images';
import { windowResizeChanger } from '../utils';

export const BRANDINGPROJECTS = () => ({
  PAGE: 'branding-projects',
  PATHS: [
    { path: '/portfolio', text: 'Portfolio' },
    { path: '/portfolio/brandingprojects', text: 'Logo design projects' },
  ],
  HEADER: {
    TITLE: 'Logo design projects',
    SUBTITLE:
      'Branding is my passion and it is my side-gig thing. Over the years I have designed a series of logos for companies from different parts of the world.',
    IMAGES: [importImg('BrandingProjectsHeader.jpg')],
  },
  PROJECTS: [
    {
      setBackground: true,
      title: 'WING DMS',
      contents1: (
        <>
          <span>
            WING was a courier company changed owned by Amazon. In 2019 and it
            started selling its system to other couriers companies on
            subscription-based. We decided that the new business model will run
            under a separate brand called WING DMS. 
          </span>
          <span>
            I was tasked to design a logo for the company. The logo should
            represent the followings:
          </span>
        </>
      ),
      contentItems: [
        'Delivery service of any type',
        'Service provided Worldwide',
        'Technology',
        'Connection to WING',
      ],
      contents2:
        'It was hard to join everything into one logo, but I could manage to design this option that could provide everything listed above.',
      createSteps: [
        {
          img: importImg('BrandingProjectsWingDms1.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'The turning line with a circle at the end represents a path with a destination that indicates on delivery service.',
          mobileColumnMode: true,
        },
        {
          img: importImg('BrandingProjectsWingDms2.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'By adding negative space to the circle, I made the line look like a wire that is widely used to represent technology.',
          mobileColumnMode: true,
        },
        {
          img: importImg('BrandingProjectsWingDms3.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I tilted the square 45 degrees to make its 4 rounded corners look like 4 arrows that signify 4 sides of the world or worldwide.',
          mobileColumnMode: true,
        },
        {
          img: importImg('BrandingProjectsWingDms4.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I added the circle of the Wing logo to the center of the shape. The movement is happening around the circle which means WING runs everything.',
          mobileColumnMode: true,
        },
      ],
      contents3:
        'For the colors and font, we decided that WING font Blogger sans and WING colors are the best options to show even more connections between the two brands.',
      colorBoxes: [
        {
          color: theme.colors.navyBlue,
          title: 'Navy Blue',
          content: 'Hex: 0B1145',
        },
        {
          color: theme.colors.yellow800,
          title: 'Sunlight Yellow',
          content: 'Hex: FCD007',
        },
        {
          color: theme.colors.white,
          border: `0.1rem solid ${theme.colors.gray500}`,
          title: 'White',
          content: 'Hex: FFFFFF',
        },
      ],
      theFinalLook: 'The final look',
      theFinalLookImg: importImg('BrandingProjectsWingDmsFinalLook.jpg'),
    },
    {
      projectName: 'medyear',
      setBackground: false,
      title: 'Medyear',
      contents1: (
        <>
          <span>
            Medyear is a healthcare technology company established in 2012. By
            2019 the company logo looked outdated and the brand identity assets
            were not organized and designed properly.
          </span>
          <span>
            I was tasked to redesign the company logo and the assets. The
            founder wanted the new logo to highlight these things.
          </span>
        </>
      ),
      contentItemsColumn: '1fr',
      contentItems: [
        'A snowflake to represent the uniqueness',
        'Keep the letters M and Y to indicate the company name.',
        'Medical records for a year long.',
      ],
      contents2:
        'I was inspired by the old logo but I did not want to take a similar approach as the old logo had too many objects.',
      createSteps: [
        {
          img: importImg('BrandingProjectsMedyear1.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'Medyear’s old logo consists of M and Y letters in the form of a snowflake.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsMedyear2.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I designed a snowflake that consisted of 6 sides in the form of people. It means Medyear connects all healthcare professionals with its solutions.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsMedyear3.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '2rem' }),
          imgWidth: windowResizeChanger({
            desktop: '22.2rem',
            mobile: 'max-content',
          }),
          content:
            'I joined the letters M and Y together to get the MY shape. It should add to the meaning of the new logo.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsMedyear4.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'The final shape was the snowflake with the MY letters in the center.',
          mobileRowMode: true,
        },
      ],
      contents3:
        'I chose Royal Blue and Navy Blue colors as the main colors and yellow as the secondary color. Royal blue represents trust, dependability, reliability, responsibility.',
      colorBoxes: [
        {
          color: theme.colors.blue900,
          title: 'Royal Blue',
          content: 'Hex: 0572FF',
        },
        {
          color: theme.colors.navyBlue700,
          title: 'Navy Blue',
          content: 'Hex: 03192F',
        },
        {
          color: theme.colors.yellow900,
          title: 'Yellow',
          content: 'Hex: FFC927',
        },
      ],
      theFinalLook: 'The final look',
      theFinalLookImg: importImg('BrandingProjectsMedyearFinalLook.jpg'),
    },
    {
      setBackground: true,
      title: 'Medypass',
      contents1: (
        <>
          <span>
            Medypass is an app designed for storing medical records digitally
            (mainly for C19 and Vaccine records) obtained from various sources.
            The app is very useful for travel and to enter facilities where
            proving safety from C19 and vaccination is required.
          </span>
          <span>
            We decided to launch the app under a separate brand. I was tasked to
            design a logo that represents these things:
          </span>
        </>
      ),
      contentItemsColumn: '1fr',
      contentItems: [
        'Safety in the form of a shield or something similar.',
        'Use an arrow that signifies a pass for freedom',
      ],
      createSteps: [
        {
          img: importImg('BrandingProjectsMedypass1.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I used a shield to represent safety. It is a very common method so I wanted to use the existing method.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsMedypass2.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I added a compass arrow pointing up the right side that represents freedom of travel.',
          mobileRowMode: true,
        },
      ],
      contents3:
        'I chose the colors from Medyear brand guidebook. As the primary color, I decided Fruit Salad green would be perfect. As the secondary colors, Navy Blue and Yellow accompanied the green.',
      colorBoxes: [
        {
          color: theme.colors.green800,
          title: 'Fruit Salad',
          content: 'Hex: 4CAF50',
        },
        {
          color: theme.colors.navyBlue800,
          title: 'Navy Blue',
          content: 'Hex: 03192F',
        },
        {
          color: theme.colors.yellow900,
          title: 'Yellow',
          content: 'Hex: FFC927',
        },
      ],
      theFinalLook: 'The final look',
      theFinalLookImg: importImg('BrandingProjectsMedypassFinalLook.jpg'),
    },
    {
      setBackground: false,
      title: 'Prenomed',
      contents1: (
        <>
          <span>
            Pronomed is a new project by Protective Labs that concentrates on
            DNA testing mainly. Prenomed app is a white-labeled version of
            Medyear app to exchange DNA testing results and records.
          </span>
          <span>
            I was tasked to design the company logo and the assets. I was
            informed that these things should be the main point to consider:
          </span>
        </>
      ),
      contentItemsColumn: '1fr',
      contentItems: [
        'The logo should indicate to Lab and DNA',
        'The logo should also indicate to Technology',
        'They have many other testing kits, DNA is the main target.',
      ],
      contents2:
        'I researched the internet looking for ideas. It was not simple to join all the meanings into one shape.',
      createStepsWidth: '100%',
      createSteps: [
        {
          img: importImg('BrandingProjectsPernomed1.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I stacked circles into a group that reminds a double helix, the sign of DNA.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsPernomed2.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I joined the circles in each line together to form a double helix. But each line also looks like wires, the sign of technology.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsPernomed3.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I tilted the shape to the right 45 degrees, to make it look like a DNA double helix.',
          mobileRowMode: true,
        },
      ],
      contents3:
        'I was requested to use Protective Lab colors, so I did not have to work on it that much, just took the colors and applied them.',
      colorBoxes: [
        {
          color: theme.colors.darkBlue500,
          title: 'Congress Blue',
          content: 'Hex: 00428F',
        },
        {
          color: theme.colors.pictionBlue,
          title: 'Picton Blue',
          content: 'Hex: 4EB2E0',
        },
        {
          color: theme.colors.white,
          border: `0.1rem solid ${theme.colors.gray500}`,
          title: 'White',
          content: 'Hex: FFFFFF',
        },
      ],
      theFinalLook: 'The final look',
      theFinalLookImg: importImg('BrandingProjectsPernomedFinalLook.jpg'),
    },
    {
      setBackground: true,
      title: 'Jowi',
      contents1: (
        <>
          <span>
            Jowi is a restaurant management system that operates in multiple
            countries. They approached me asking for a logo redesign. They did
            not like the previous design so wanted to redesign the logo.
          </span>
          <span>
            I decided to concentrate on the “Jowi” name based on these points
            listed by the client:
          </span>
        </>
      ),
      contentItemsColumn: '1fr',
      contentItems: [
        'Jowi is a restaurant management system.',
        'Jowi will have more products in the future, approximately 8-10.',
        'Each new product will use Jowi name, for instance, Jowi Booking.',
      ],
      createStepsWidth: '100%',
      createSteps: [
        {
          img: importImg('BrandingProjectsJowi1.svg'),
          imgHeight: windowResizeChanger({
            desktop: '6rem',
            tablet: '4.7rem',
            mobile: '2.5rem',
          }),
          content:
            'I used the company font Gilroy to get the Jowi word. But it was not even close to what I wanted to design.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsJowi2.svg'),
          imgHeight: windowResizeChanger({
            desktop: '6rem',
            tablet: '4.7rem',
            mobile: '2.5rem',
          }),
          content:
            'I made changes to the letter J, added the top part with an upward arrow to the letter. I changed the rounded bottom part of J.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsJowi3.svg'),
          imgHeight: windowResizeChanger({
            desktop: '6rem',
            tablet: '4.7rem',
            mobile: '2.5rem',
          }),
          content:
            'I added a wave to the Jowi word that split it into parts. I used two gradient colors to distinguish the two parts of the Jowi word.',
          mobileRowMode: true,
        },
      ],
      contents3:
        'I was requested to use the existing colors of the brand which made my work a lot easier.',
      colorBoxes: [
        {
          color: theme.colors.orange700,
          title: 'Tango',
          content: 'Hex: F4801E',
        },
        {
          color: theme.colors.orange500,
          title: 'Tree Poppy',
          content: 'Hex: FAA41A',
        },
        {
          color: theme.colors.black600,
          title: 'Mirage',
          content: 'Hex: 141F29',
        },
      ],
      theFinalLook: 'The final look',
      theFinalLookImg: importImg('BrandingProjectsJowiFinalLook.jpg'),
    },
    {
      setBackground: false,
      title: 'Shifra Technology',
      contents1: (
        <>
          <span>
            Shifra Technology is more like an IT company located in Lebanon. It
            is a new company and they asked me to help them design their new
            logo and the brand assets after that.
          </span>
          <span>
            The client shared his vision with me about the logo and it included:
          </span>
        </>
      ),
      contentItemsColumn: '1fr',
      contentItems: [
        'The logo needs to show the S letter in it.',
        'Make it obvious that the company is a technology company.',
        'The company will have many different technological products so it will be logical to show that is a multi-directional company.',
      ],
      createStepsWidth: '100%',
      createSteps: [
        {
          img: importImg('BrandingProjectsShifra1.svg'),
          imgWidth: windowResizeChanger({ desktop: '10rem', mobile: '6rem' }),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'First, I tried to design the letter S in a different way. Using fonts would not work here so I used custom design.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsShifra2.svg'),
          imgWidth: windowResizeChanger({ desktop: '10rem', mobile: '6rem' }),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I added circles to the end of each line to form two wires. Together they form the S letter, also they mean technology.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsShifra3.svg'),
          imgHeight: windowResizeChanger({ desktop: '10rem', mobile: '4rem' }),
          content:
            'I tilted the newly formed S by 30 degrees and placed it inside a hexagon in negative space to indicate that Shifra Technology is a multi-directional company.',
          mobileRowMode: true,
        },
      ],
      contents3:
        'I chose Strator and Cyan/Aqua as the main colors, and Azure Radiance as the secondary color.',
      colorBoxes: [
        {
          color: theme.colors.navyBlue900,
          title: 'Stratos',
          content: 'Hex: 000E3E',
        },
        {
          color: theme.colors.cyan,
          title: 'Cyan / Aqua',
          content: 'Hex: 40FCFF',
        },
        {
          color: theme.colors.azureRadiance,
          title: 'Azure Radiance',
          content: 'Hex: 007EFF',
        },
      ],
      theFinalLook: 'The final look',
      theFinalLookImg: importImg('BrandingProjectsShifraFinalLook.jpg'),
    },
    {
      setBackground: true,
      title: 'Safe Arrival',
      contents1: (
        <>
          <span>
            Safe Arrival is a courier company located in Saudi Arabia. In 2019,
            they wanted to enter the United Arab Emirates market and for that,
            they wanted to redesign their company logo.
          </span>
          <span>
            I redesigned their logo based on these instructions from the client:
          </span>
        </>
      ),
      contentItemsColumn: '1fr',
      contentItems: [
        'The client wanted a wordmark logo, the name “Safe Arrival” should be the logo of the company.',
        'The word Safe should be the main point here, as the company provides safe deliveries.',
        'Also, it would be nice to have delivery in the meaning of the logo.',
        '“We deliver smile” is their slogan and if possible put a sign in the logo.',
      ],
      createSteps: [
        {
          img: importImg('BrandingProjectsSafeArrival1.svg'),
          imgHeight: windowResizeChanger({
            desktop: '7.4rem',
            mobile: '3.7rem',
          }),
          content:
            'I wanted to write the words so that they form the shape of squares or rectangular.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsSafeArrival2.svg'),
          imgHeight: windowResizeChanger({
            desktop: '7.4rem',
            mobile: '3.7rem',
          }),
          content:
            'I tried to highlight the word Safe over Arrival so I decreased the size of the Arrival word and placed it below & center of Safe.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsSafeArrival3.svg'),
          imgHeight: windowResizeChanger({
            desktop: '7.4rem',
            mobile: '3.7rem',
          }),
          content:
            'I worked on the letter A to make it more like a tent and an arrow directed to the upside. This A symbol is as an app logo.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsSafeArrival4.svg'),
          imgHeight: windowResizeChanger({
            desktop: '7.4rem',
            mobile: '3.7rem',
          }),
          content:
            'I added two smiles to the word Arrival. When the shipment arrives, it causes a smile for the owner of the shipment.',
          mobileRowMode: true,
        },
      ],
      contents3:
        'I chose the Blue Ribbon, Supernova yellow, and Stratos as the main colors of the brand.',
      colorBoxes: [
        {
          color: theme.colors.blue800,
          title: 'Blue Ribbon',
          content: 'Hex: 0036FB',
        },
        {
          color: theme.colors.yellow700,
          title: 'Supernova',
          content: 'Hex: FFCB00',
        },
        {
          color: theme.colors.navyBlue900,
          title: 'Stratos',
          content: 'Hex: 000E3E',
        },
      ],
      theFinalLook: 'The final look',
      theFinalLookImg: importImg('BrandingProjectsSafeArrivalFinalLook.jpg'),
    },
    {
      setBackground: false,
      title: 'Fridgit',
      contents1: (
        <>
          <span>
            Fridgit is a product of Shifra Technology based in Saudi Arabia. It
            is a management system for fridge maintenance companies. The clients
            wanted to design a logo for the product.
          </span>
          <span>
            I received the following points to keep in mind while designing the
            logo for the product:
          </span>
        </>
      ),
      contentItemsColumn: '1fr',
      contentItems: [
        'The logo should indicate the maintenance of fridges.',
        'The service is done fast and the logo should have a sign for that.',
        'The logo should have a connection to Shifra Technology.',
      ],
      createSteps: [
        {
          img: importImg('BrandingProjectsFridgit1.svg'),
          imgHeight: windowResizeChanger({
            desktop: '10rem',
            mobile: '4rem',
          }),
          content:
            'I used a wrench to represent maintenance. Initially, I wanted to use a screwdriver, but it did not look good at the end.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsFridgit2.svg'),
          imgHeight: windowResizeChanger({
            desktop: '10rem',
            mobile: '4rem',
          }),
          content:
            'I joined 3 two-sided wrenches into one to form a snowflake. Snowflake represents fridges.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsFridgit3.svg'),
          imgHeight: windowResizeChanger({
            desktop: '10rem',
            mobile: '4rem',
          }),
          content:
            'I added a hint of the clock to represent fast service. I kept the hint small because did not want to ruin the logo with a big clock.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsFridgit4.svg'),
          imgHeight: windowResizeChanger({
            desktop: '10rem',
            mobile: '4rem',
          }),
          content:
            'I placed the shape inside a hexagon to have a connection with Shifra Technology.',
          mobileRowMode: true,
        },
      ],
      contents3:
        'In order to have more connection with Shifra Technology, I decided to use its main colors Azure Radiance and Stratos for Fridgit. On top of that, I used Robin’s Egg Blue as one of the primary colors.',
      colorBoxes: [
        {
          color: theme.colors.azureRadiance,
          title: 'Azure Radiance',
          content: 'Hex: 007EFF',
        },
        {
          color: theme.colors.cyan700,
          title: 'Robin’s Egg Blue',
          content: 'Hex: 00D6E2',
        },
        {
          color: theme.colors.navyBlue900,
          title: 'Stratos',
          content: 'Hex: 000E3E',
        },
      ],
      theFinalLook: 'The final look',
      theFinalLookImg: importImg('BrandingProjectsFridgitFinalLook.jpg'),
    },
    {
      setBackground: true,
      title: 'Propmint',
      contents1: (
        <>
          <span>
            Propmint is a property management system for landlords who have
            multiple properties. The client wanted to redesign their logo and
            the brand assets.
          </span>
          <span>I was asked to consider two main things while designing:</span>
        </>
      ),
      contentItemsColumn: '1fr',
      contentItems: [
        'Use home or similar objects to show that project is about the property.',
        'Also, the logo should show growth.',
      ],
      createSteps: [
        {
          img: importImg('BrandingProjectsPropmint1.svg'),
          imgHeight: windowResizeChanger({
            desktop: '7.2rem',
            mobile: '3.6rem',
          }),
          content:
            'I chose a rooftop with a chimney to convey the message of the property.',
          mobileRowMode: true,
        },
        {
          img: importImg('BrandingProjectsPropmint2.svg'),
          imgHeight: windowResizeChanger({
            desktop: '7.2rem',
            mobile: '3.6rem',
          }),
          content:
            'I added an upward arrow to indicate the growth of the properties for the landlords.',
          mobileRowMode: true,
        },
      ],
      contents3:
        'I chose Emaral green, Lightning Yellow, and Daintree as the main colors of the company.',
      colorBoxes: [
        {
          color: theme.colors.green600,
          title: 'Emerald',
          content: 'Hex: 67CE78',
        },
        {
          color: theme.colors.yellow600,
          title: 'Lightning Yellow',
          content: 'Hex: FFC414',
        },
        {
          color: theme.colors.black800,
          title: 'Hex: FFC414',
          content: 'Hex: 002335',
        },
      ],
      theFinalLook: 'The final look',
      theFinalLookImg: importImg('BrandingProjectsPropmintFinalLook.jpg'),
    },
  ],
});
