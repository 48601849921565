import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container } from '../../components';
import { PathNavigation, ScrollToUpButton } from '../components';
import { Header } from './Header';
import { Post } from './Post';
import { BLOGDETAILS } from '../../const';

export const BlogDetails = () => {
  const { id } = useParams();
  const historyPush = useNavigate();

  useEffect(() => {
    if (!BLOGDETAILS()[id]) {
      historyPush('/error');
    }
  }, [id, historyPush]);

  return (
    <>
      <PathNavigation paths={BLOGDETAILS()[id]?.PATHS} />
      <ScrollToUpButton />
      <Container main>
        <Header {...BLOGDETAILS()[id]?.HEADER} />
        <Post {...BLOGDETAILS()[id]} />
      </Container>
    </>
  );
};
