import styled from 'styled-components';

const ToolImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const RowImages = ({ images }) => {
  if (!images) return null;

  return images.map((img, i) => <ToolImg key={i + 'img'} src={img} alt={'Tool'} />);
};
