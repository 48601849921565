import styled, { css } from 'styled-components';
import { Container, Typography, media } from '../../../components';
import { AdvancedContents } from './AdvancedContents';
import { ToolBoxes } from './ToolBoxes';
import { RowBigTitle } from './RowBigTitle';
import { RowTools } from './RowTools';
import { RowImages } from './RowImages';
import { RowContents } from './RowContents';
import { RowTitles } from './RowTitles';
import { AdvancedSubcontent } from './AdvancedSubcontent';
import { theme } from '../../../const';

const AdvancedSectionBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  justify-items: center;
  width: 100%;

  ${(props) =>
    props.page === 'driver-app' &&
    props.section === 'not-work' &&
    css`
      max-width: 57rem;
    `}

  ${(props) =>
    props.page === 'personal-logo' &&
    props.section === 'research' &&
    css`
      max-width: 77rem;
    `}
`;

const AdvancedTitle = styled(Typography)`
  ${(props) =>
    props.contents &&
    css`
      margin-bottom: 1rem;
    `}
`;

const AdvancedSectionBackground = css`
  background-color: ${theme.colors.light};
`;

const ToolRow = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;

  ${(props) => css`
    grid-template-columns: ${props.column};
  `}

  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
      grid-auto-rows: initial;
    `}

  ${media.tablet`
    grid-row-gap: 2rem;
  `}

  ${media.mobile`
    grid-column-gap: 1.6rem;
    grid-row-gap: 1.5rem;
  `}
`;

export const AdvancedSection = ({
  PAGE,
  SECTION,
  SET_BACKGROUND = false,
  TITLE,
  CONTENTS,
  CONTENTS_ALIGN,
  CONTENTS_VARIANT,
  TOOLS,
  SUBCONTENT,
  UNIQUE_ROWS,
}) => {
  return (
    <AdvancedSectionBase
      backgroundCSS={SET_BACKGROUND && AdvancedSectionBackground}
      page={PAGE}
      section={SECTION}
    >
      <AdvancedTitle
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
        contents={CONTENTS}
      >
        {TITLE}
      </AdvancedTitle>
      <AdvancedContents
        PAGE={PAGE}
        SECTION={SECTION}
        CONTENTS={CONTENTS}
        CONTENTS_VARIANT={CONTENTS_VARIANT}
        CONTENTS_ALIGN={CONTENTS_ALIGN}
      />
      <ToolBoxes PAGE={PAGE} SECTION={SECTION} TOOLS={TOOLS}>
        {({ column, height, title, tools, images, contents, titles }, i) => (
          <ToolRow key={i + 'row'} column={column} height={height}>
            <RowBigTitle title={title} />
            <RowTools tools={tools} />
            <RowImages images={images} />
            <RowContents contents={contents} page={PAGE} section={SECTION} />
            <RowTitles titles={titles} />
            {UNIQUE_ROWS && UNIQUE_ROWS[i]}
          </ToolRow>
        )}
      </ToolBoxes>
      <AdvancedSubcontent variant={'20/28'} SUBCONTENT={SUBCONTENT} required />
    </AdvancedSectionBase>
  );
};
