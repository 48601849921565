import styled, { css } from 'styled-components';
import { media } from '../GlobalStyle';
import { theme } from '../../const';

export const ContainerWrapper = styled.div`
  pointer-events: none;
  width: 100%;
  min-width: 100vw;
  height: 100%;
  padding: 0 !important;

  position: relative;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
`;

const ContainerBase = styled.div`
  width: 100%;
  max-width: 117rem;
  margin: auto;

  position: relative;
  pointer-events: all;

  ${(props) =>
    (props.backgroundCSS || props.backgroundLight) &&
    css`
      & > *:nth-child(n + 2) {
        z-index: 1;
      }
    `}

  ${(props) =>
    props.main &&
    css`
      & > * {
        padding-top: 10rem;
        padding-bottom: 10rem;
      }
    `}

  ${(props) =>
    props.overlay &&
    css`
      padding-top: 10rem;
      padding-bottom: 10rem;
    `}

  ${(props) =>
    props.main &&
    media.tablet`
    max-width: 106.2rem;
    padding: 0 3.1rem;

    & > * {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  `}

  ${(props) =>
    props.overlay &&
    media.tablet`
      max-width: 106.2rem;
      padding: 5rem 3.1rem;
    `}

  ${(props) =>
    props.main &&
    media.mobile`
    padding: 0 1.5rem;

    & > * {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  `}

  ${(props) =>
    props.overlay &&
    media.mobile`
      padding: 3rem 1.5rem;
    `}
`;

export const ContainerBackground = styled(ContainerWrapper)`
  position: absolute;
  overflow: initial;

  ${(props) =>
    props.backgroundLight &&
    css`
      background-color: ${theme.colors.light};
    `}

  ${(props) => props.backgroundCSS}
`;

export const Container = ({
  main,
  backgroundCSS,
  backgroundLight,
  overlay,
  children,
  ...props
}) => {
  return overlay ? (
    <ContainerWrapper>
      <ContainerBase
        main={main}
        backgroundCSS={backgroundCSS}
        backgroundLight={backgroundLight}
        overlay={overlay}
        {...props}
      >
        {(backgroundCSS || backgroundLight) && (
          <ContainerBackground
            backgroundCSS={backgroundCSS}
            backgroundLight={backgroundLight}
          />
        )}
        {children}
      </ContainerBase>
    </ContainerWrapper>
  ) : (
    <ContainerBase
      main={main}
      backgroundCSS={backgroundCSS}
      backgroundLight={backgroundLight}
      {...props}
    >
      {(backgroundCSS || backgroundLight) && (
        <ContainerBackground
          backgroundCSS={backgroundCSS}
          backgroundLight={backgroundLight}
        />
      )}
      {children}
    </ContainerBase>
  );
};
