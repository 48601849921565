import styled from 'styled-components';
import { Typography, fullScreenImg, media } from '../../components';
import { theme } from '../../const';

const CompetitorsBase = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 3rem;
  grid-row-gap: 5.5rem;

  ${media.tablet`
    grid-row-gap: 3rem;
  `}

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  `}
`;

const CompetitorsTitle = styled(Typography)`
  grid-column: 1 / 3;

  ${media.mobile`
    grid-column: 1;
  `}
`;

const Competitor = styled.div`
  display: grid;
  grid-auto-rows: max-content;

  ${media.tablet`
    grid-row-gap: 1rem;

    & > *:nth-child(n + 3) {
      margin-top: 0.5rem;
    }
  `}

  ${media.mobile`
    & > *:nth-child(n + 3) {
      margin-top: 0;
    }
  `}
`;

const CompetitorTypography = styled(Typography)`
  margin-bottom: 1rem;

  ${media.tablet`
    margin: 0;
  `}
`;

const CompetitorImg = styled.img`
  width: 100%;
  margin-bottom: 2rem;
  cursor: zoom-in;

  ${media.tablet`
    margin: 0;
  `}
`;

const CompetitorItem = styled(Typography)`
  &:nth-last-of-type(n + 2) {
    margin-bottom: 1.5rem;
  }

  ${media.tablet`
    &:nth-last-of-type(n + 2) {
      margin-bottom: 0;
    }
  `}
`;

export const Competitors = ({ PAGE, TITLE, BOXES }) => {
  return (
    <CompetitorsBase>
      <CompetitorsTitle
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </CompetitorsTitle>
      {BOXES.map(({ title, content, img, items }, i) => (
        <Competitor key={i}>
          <CompetitorTypography
            variant={'24/29'}
            weight={theme.font.weightBold}
          >
            {title}
          </CompetitorTypography>
          <CompetitorTypography variant={'20/28'}>
            {content}
          </CompetitorTypography>
          <CompetitorImg
            src={img}
            onClick={() => fullScreenImg(img)}
            alt={'Competitor'}
          />
          {items.map((item, i) => (
            <CompetitorItem key={i} variant={'20/28'} listItem>
              {item}
            </CompetitorItem>
          ))}
        </Competitor>
      ))}
    </CompetitorsBase>
  );
};
