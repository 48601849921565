import styled, { css } from 'styled-components';
import {
  Container,
  Typography,
  SocialButton,
  IconSVG,
  media,
  sizes,
} from '../../components';
import { theme, PORTFOLIO } from '../../const';

const TestimonialsBase = styled(Container)`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 5rem;
  justify-items: center;
  width: 100%;

  ${media.tablet`
    grid-row-gap: 1.5rem;
    padding: 8rem 0 6.5rem;
  `}

  ${media.mobile`
    grid-row-gap: 0;
    padding: 3rem 0 1rem;
  `}
`;

const TestimonialsBackground = css`
  background-color: ${theme.colors.light};
`;

const TestimonialsTitle = styled(Typography)``;

const TestimonialCards = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-gap: 3rem;
  width: 100%;

  ${media.tablet`
    grid-template-columns: repeat(4, max-content);
    grid-template-rows: initial;
    grid-column-gap: 1.5rem;
    justify-self: left;
    
    width: 100vw;
    padding: 1.5rem 3.1rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    overflow: overlay;

    &::-webkit-scrollbar {
      display: none;
    }
  `}

  ${media.mobile`
    padding: 2rem 1.5rem;
  `}
`;

const TestimonialBase = styled.div`
  display: grid;
  grid-template-rows: max-content 1fr repeat(3, max-content);
  justify-items: start;

  height: 30rem;
  padding: 3rem 31rem 3rem 3rem;
  border-radius: 0.5rem;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows[0]};
  overflow: hidden;
  position: relative;

  ${media.tablet`
    width: 30rem;
    height: 35rem;
    padding: 2rem 0.7rem 2rem 2rem;
  `}
`;

const TestimonialContent = styled(Typography)`
  margin-top: 2rem;

  ${media.tablet`
    margin-top: 1rem;
  `}
`;

const TestimonialName = styled(Typography)`
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${theme.font.size20};
    line-height: ${theme.font.lineHeight24};
    margin-top: 0.5rem;
  }
`;

const TestimonialSubname = styled(Typography)`
  margin-top: 0.2rem;
  width: max-content;

  @media (max-width: ${sizes.tablet}px) {
    font-size: ${theme.font.size16};
    line-height: ${theme.font.lineHeight19};
    margin-top: 0.5rem;
  }
`;

const TestimonialSocial = styled.div`
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-column-gap: 1rem;
  margin-top: 1rem;
`;

const TestimonialImg = styled.div`
  width: 28.5rem;
  height: 30rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.img});

  position: absolute;
  right: 0;

  ${media.tablet`
    width: 8rem;
    height: 8rem;
    position: initial;
    border-radius: 50%;
  `}
`;

const Testimonial = ({
  content,
  name,
  subname,
  linkedin,
  instagram,
  facebook,
  img,
}) => {
  return (
    <TestimonialBase>
      <IconSVG iconName={'icon-quote'} width={'3.5rem'} height={'3rem'} />
      <TestimonialContent variant={'20/24'}>{content}</TestimonialContent>
      <TestimonialImg img={img} />
      <TestimonialName variant={'16/19'} weight={theme.font.weightBold}>
        {name}
      </TestimonialName>
      <TestimonialSubname variant={'14/16'}>{subname}</TestimonialSubname>
      <TestimonialSocial>
        <SocialButton
          href={linkedin}
          iconName={'icon-linkedin'}
          size={'small'}
        />
        <SocialButton
          href={instagram}
          iconName={'icon-instagram'}
          size={'small'}
        />
        <SocialButton
          href={facebook}
          iconName={'icon-facebook'}
          size={'small'}
        />
      </TestimonialSocial>
    </TestimonialBase>
  );
};

export const Testimonials = () => {
  return (
    <TestimonialsBase backgroundCSS={TestimonialsBackground}>
      <TestimonialsTitle
        variant={'40/48'}
        color={theme.colors.darkBlue}
        weight={theme.font.weight600}
      >
        {PORTFOLIO().TESTIMONIALS.TITLE}
      </TestimonialsTitle>
      <TestimonialCards>
        {PORTFOLIO().TESTIMONIALS.CARDS.map(
          (
            { content, name, subname, linkedin, instagram, facebook, img },
            i
          ) => (
            <Testimonial
              key={i}
              content={content}
              name={name}
              subname={subname}
              linkedin={linkedin}
              instagram={instagram}
              facebook={facebook}
              img={img}
            />
          )
        )}
      </TestimonialCards>
    </TestimonialsBase>
  );
};
