import styled from 'styled-components';
import {
  Container,
  LinkButton,
  Link,
  Typography,
  media,
  sizes,
} from '../../components';
import { theme, PORTFOLIO } from '../../const';

const HeaderBase = styled(Container)`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 3rem;
  align-items: center;
  width: 100%;

  ${media.tablet`
    padding: 8rem 0;
  `}

  ${media.mobile`
    grid-template-columns: 100%;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 2rem;
    padding: 0 0 3rem;
  `}
`;

const HeaderMain = styled.div`
  display: grid;
  grid-template-rows: repeat(4, max-content);
  grid-row-gap: 2rem;
  justify-items: start;

  ${media.tablet`
    grid-row-gap: 1rem;
  `}

  @media (max-width: ${sizes.mobile}px) {
    grid-row-gap: 1rem;
    order: 1;

    & > *:nth-last-child(n + 2) {
      font-size: ${theme.font.size16};
      line-height: ${theme.font.lineHeight20};
    }
  }
`;

const HeaderContent3 = styled(Typography)`
  ${media.tablet`
    margin: 1rem 0;
  `}

  ${media.mobile`
    margin: 0;
  `}
`;

const AboutmeButton = styled(LinkButton)`
  margin-top: 1rem;

  ${media.mobile`
    width: 100%;
  `}
`;

const HeaderImage = styled.img`
  width: 47rem;

  ${media.tablet`
    width: 33.8rem;
  `}

  ${media.mobile`
    width: 100vw;
    margin-left: -1.5rem;
  `}
`;

export const Header = () => {
  return (
    <HeaderBase backgroundLight>
      <HeaderMain>
        <Typography variant={'32/38'}>
          {PORTFOLIO().HEADER.TEXT1}
          <Link href={'https://medyear.com/'} color={'blue'} weight={'bold'}>
            {PORTFOLIO().HEADER.MEDYEAR}
          </Link>
          .
        </Typography>
        <Typography variant={'32/38'}>
          {PORTFOLIO().HEADER.TEXT2}
          <Typography weight={'bold'}>
            {PORTFOLIO().HEADER.USER_EXPERIENCE}
          </Typography>
          .
        </Typography>
        <HeaderContent3 variant={'24/29'}>
          {PORTFOLIO().HEADER.TEXT3}
          <Link
            href={
              'https://www.failory.com/amazon/wing-ae#:~:text=Description,commerce%20players%20in%20the%20area'
            }
            color={'blue'}
            weight={'bold'}
          >
            {PORTFOLIO().HEADER.AMAZON}
          </Link>
        </HeaderContent3>
        <AboutmeButton to={'/about'}>
          {PORTFOLIO().HEADER.ABOUT_ME}
        </AboutmeButton>
      </HeaderMain>
      <HeaderImage src={PORTFOLIO().HEADER.IMG} alt={'Portfolio Header'} />
    </HeaderBase>
  );
};
