import { Container } from '../../components';
import { Navigation, ScrollToUpButton, Footer } from '../components';
import { Blogs } from './Blogs';

export const Blog = () => {
  return (
    <>
      <Navigation />
      <ScrollToUpButton />
      <Container main>
        <Blogs />
        <Footer />
      </Container>
    </>
  );
};
