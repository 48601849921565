import styled from 'styled-components';
import { ToolBox } from '../components';
import { Typography, fullScreenImg, media } from '../../components';
import { theme } from '../../const';

const TestBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  justify-items: center;
`;

const TestSubtitle = styled(Typography)`
  width: 57rem;
  margin: 1rem 0 3rem;

  ${media.tablet`
    width: 100%;
  `}

  ${media.mobile`
    margin: 1rem 0 1.5rem;
  `}
`;

const TestBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;
  width: 100%;

  ${media.mobile`
    grid-template-columns: 1fr;
    grid-auto-rows: max-content;
    grid-row-gap: 1.5rem;
  `}
`;

const TestBoxBase = styled.div`
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 2rem;

  ${media.tablet`
    grid-row-gap: 1rem;
  `}

  ${media.mobile`
    grid-row-gap: 0.5rem;
    width: 100%;
  `}
`;

const TestBoxImg = styled.img`
  width: 100%;
  cursor: zoom-in;
`;

const TestBox = ({ title, children, ...props }) => {
  return (
    <TestBoxBase {...props}>
      <Typography variant={'24/29'} weight={theme.font.weightBold}>
        {title}
      </Typography>
      {children}
    </TestBoxBase>
  );
};

const TestBoxDiscovered = styled(TestBox)`
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 3rem;
  grid-row-gap: 2rem;
  grid-column: 1 / 3;

  & > *:nth-child(1) {
    grid-column: 1 / 5;
    margin-bottom: 1rem;

    ${media.tablet`
      grid-column: initial;
    `}

    ${media.mobile`
      margin-bottom: 0;
    `}
  }

  & > *:nth-child(2) {
    grid-column: 1 / 3;
    margin-bottom: 1rem;

    ${media.tablet`
      grid-column: initial;
      margin-bottom: 0;
    `}
  }

  & > *:nth-child(3) {
    grid-column: 3 / 5;
    margin-bottom: 1rem;

    ${media.tablet`
      grid-column: initial;
      grid-row: 4 / 5;
      margin-top: 5.2rem;
      margin-bottom: 0;
    `}

    ${media.mobile`
      margin-top: 1rem;
    `}
  }

  & > *:nth-child(4) {
    grid-column: 1 / 3;

    ${media.tablet`
      grid-column: initial;
    `}
  }

  & > *:nth-child(5) {
    grid-column: 3 / 5;

    ${media.tablet`
      grid-column: initial;
    `}
  }

  ${media.tablet`
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  `}

  ${media.mobile`
    grid-column: 1;
  `}
`;

const TestPhones = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-column-gap: 3rem;

  ${media.tablet`
    margin-left: 8rem;
  `}

  ${media.mobile`
    grid-template-columns: minmax(min-content, max-content) minmax(min-content, max-content);
    grid-column-gap: 1.6rem;
    justify-content: right;
    margin: 0;
    max-width: 38.5rem;
  `}
`;

const TestPhoneImg = styled.img`
  width: 27rem;

  ${media.tablet`
    width: 18rem;
  `}

  ${media.mobile`
    width: 100%;  
    max-width: 15.7rem;
  `}
`;

export const Test = ({
  PAGE,
  TITLE,
  SUBTITLE,
  CONNECTING_TITLE,
  CONNECTING_IMG,
  WIRELESS_TITLE,
  WIRELESS_IMG,
  DISCOVERED_TITLE,
  CONNECTING_TITLE1,
  CONNECTING1,
  WIRELESS_TITLE2,
  WIRELESS2,
  PHONE_IMG1,
  PHONE_IMG2,
  PHONE_IMG3,
  PHONE_IMG4,
}) => {
  return (
    <TestBase>
      <Typography variant={'40/48'} weight={theme.font.weight600}>
        {TITLE}
      </Typography>
      <TestSubtitle variant={'20/28'}>{SUBTITLE}</TestSubtitle>
      <TestBoxes>
        <TestBox title={CONNECTING_TITLE}>
          <TestBoxImg
            src={CONNECTING_IMG.normal}
            onClick={() => fullScreenImg(CONNECTING_IMG.fullscreen)}
            alt={'Test Box'}
          />
        </TestBox>
        <TestBox title={WIRELESS_TITLE}>
          <TestBoxImg
            src={WIRELESS_IMG.normal}
            onClick={() => fullScreenImg(WIRELESS_IMG.fullscreen)}
            alt={'Test Box'}
          />
        </TestBox>
        <TestBoxDiscovered title={DISCOVERED_TITLE}>
          <ToolBox
            iconName={'icon-one-box'}
            iconColor={theme.colors.red}
            title={CONNECTING_TITLE1}
            content={CONNECTING1}
            columnMode
          />
          <ToolBox
            iconName={'icon-two-box'}
            iconColor={theme.colors.red}
            title={WIRELESS_TITLE2}
            content={WIRELESS2}
            columnMode
          />
          <TestPhones>
            <TestPhoneImg src={PHONE_IMG1} alt={'Phone'} />
            <TestPhoneImg src={PHONE_IMG2} alt={'Phone'} />
          </TestPhones>
          <TestPhones>
            <TestPhoneImg src={PHONE_IMG3} alt={'Phone'} />
            <TestPhoneImg src={PHONE_IMG4} alt={'Phone'} />
          </TestPhones>
        </TestBoxDiscovered>
      </TestBoxes>
    </TestBase>
  );
};
