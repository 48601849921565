import styled from 'styled-components';
import { Typography, IconSVG, media } from '../../components';
import { theme } from '../../const';

const DesignBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  justify-items: center;
`;

const DesignSubtitle = styled(Typography)`
  width: 77rem;
  margin: 1rem 0 5rem;

  ${media.tablet`
    width: 100%;
    margin: 1rem 0 3rem;
    text-align: left;
  `}

  ${media.mobile`
    margin: 1rem 0 1.5rem;
  `}
`;

const DesignBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 3rem;
  grid-row-gap: 3rem;

  ${media.tablet`
    grid-template-columns: 1fr;
  `}

  ${media.mobile`
    grid-row-gap: 2rem;
  `}
`;

const DesignBoxBase = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 2rem;
  align-items: center;
  justify-items: center;

  & > *:nth-child(4) {
    grid-column: 1 / 4;

    ${media.tablet`
      grid-row: 1 / 2;
    `}
  }

  ${media.tablet`
    grid-template-columns: max-content max-content 1fr;
    grid-column-gap: 3rem;
    grid-row-gap: 1rem;
    justify-items: left;
    width: 100%;
  `}

  ${media.mobile`
    grid-template-columns: minmax(min-content,max-content) 1fr minmax(min-content,max-content);
    grid-column-gap: 0;
    justify-items: center;
  `}
`;

const DesignBox = ({ content, children, ...props }) => {
  return (
    <DesignBoxBase {...props}>
      {children}
      <Typography variant={'20/28'}>{content}</Typography>
    </DesignBoxBase>
  );
};

const DesignPhoneImg = styled.img`
  width: 24rem;

  ${media.tablet`
    width: 18rem;
  `}

  ${media.tablet`
    width: 100%;
    max-width: 14rem;
  `}
`;

const NextArrow = styled(IconSVG)`
  ${media.mobile`
    width: 3.6rem;
    height: 3.6rem;
  `}
`;

export const Design = ({
  PAGE,
  TITLE,
  SUBTITLE,
  STEP1_CONTENT,
  STEP1_IMG1,
  STEP1_IMG2,
  STEP2_CONTENT,
  STEP2_IMG1,
  STEP2_IMG2,
}) => {
  return (
    <DesignBase>
      <Typography variant={'40/48'} weight={theme.font.weight600}>
        {TITLE}
      </Typography>
      <DesignSubtitle variant={'20/28'} align={'center'}>
        {SUBTITLE}
      </DesignSubtitle>
      <DesignBoxes>
        <DesignBox content={STEP1_CONTENT}>
          <DesignPhoneImg src={STEP1_IMG1} alt={'Phone'} />
          <NextArrow iconName={'icon-next'} size={'4.8rem'} />
          <DesignPhoneImg src={STEP1_IMG2} alt={'Phone'} />
        </DesignBox>
        <DesignBox content={STEP2_CONTENT}>
          <DesignPhoneImg src={STEP2_IMG1} alt={'Phone'} />
          <NextArrow iconName={'icon-next'} size={'4.8rem'} />
          <DesignPhoneImg src={STEP2_IMG2} alt={'Phone'} />
        </DesignBox>
      </DesignBoxes>
    </DesignBase>
  );
};
