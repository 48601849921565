import styled from 'styled-components';
import { IconSVG } from '../../components';
import { ColorBox } from '../components';
import { windowResizeChanger } from '../../utils';
import { PERSONALLOGO } from '../../const';

const DesignColorImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const DesignColorUniqueRows = () =>
  windowResizeChanger({
    desktop: {
      1: PERSONALLOGO().DESING_COLOR.TOOLS && (
        <>
          <ColorBox
            color={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[0].color}
            title={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[0].title}
            content={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[0].content}
          />
          <ColorBox
            color={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[1].color}
            title={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[1].title}
            content={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[1].content}
          />
          <DesignColorImg
            src={PERSONALLOGO().DESING_COLOR.TOOLS[1].img}
            alt={'Design Color'}
          />
        </>
      ),
      3: PERSONALLOGO().DESING_COLOR.TOOLS &&
        PERSONALLOGO().DESING_COLOR.TOOLS[3].colorBoxes && (
          <>
            <ColorBox
              color={PERSONALLOGO().DESING_COLOR.TOOLS[3].colorBoxes[0].color}
              title={PERSONALLOGO().DESING_COLOR.TOOLS[3].colorBoxes[0].title}
              content={
                PERSONALLOGO().DESING_COLOR.TOOLS[3].colorBoxes[0].content
              }
            />
            <IconSVG
              iconName={'icon-big-next'}
              width={'5rem'}
              height={'4.8rem'}
            />
            <ColorBox
              color={PERSONALLOGO().DESING_COLOR.TOOLS[3].colorBoxes[1].color}
              title={PERSONALLOGO().DESING_COLOR.TOOLS[3].colorBoxes[1].title}
              content={
                PERSONALLOGO().DESING_COLOR.TOOLS[3].colorBoxes[1].content
              }
            />
            <DesignColorImg
              src={PERSONALLOGO().DESING_COLOR.TOOLS[3].img}
              alt={'Design Color'}
            />
          </>
        ),
    },
    tablet: {
      1: PERSONALLOGO().DESING_COLOR.TOOLS && (
        <>
          <ColorBox
            color={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[0].color}
            title={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[0].title}
            content={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[0].content}
          />
          <ColorBox
            color={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[1].color}
            title={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[1].title}
            content={PERSONALLOGO().DESING_COLOR.TOOLS[1].colorBoxes[1].content}
          />
        </>
      ),
      3: PERSONALLOGO().DESING_COLOR.TOOLS && (
        <>
          <DesignColorImg
            src={PERSONALLOGO().DESING_COLOR.TOOLS[3].img}
            alt={'Design Color'}
          />
        </>
      ),
      5: PERSONALLOGO().DESING_COLOR.TOOLS &&
        PERSONALLOGO().DESING_COLOR.TOOLS[5] && (
          <>
            <ColorBox
              color={PERSONALLOGO().DESING_COLOR.TOOLS[5].colorBoxes[0].color}
              title={PERSONALLOGO().DESING_COLOR.TOOLS[5].colorBoxes[0].title}
              content={
                PERSONALLOGO().DESING_COLOR.TOOLS[5].colorBoxes[0].content
              }
            />
            <IconSVG
              iconName={'icon-big-next'}
              width={'5rem'}
              height={'4.8rem'}
            />
            <ColorBox
              color={PERSONALLOGO().DESING_COLOR.TOOLS[5].colorBoxes[1].color}
              title={PERSONALLOGO().DESING_COLOR.TOOLS[5].colorBoxes[1].title}
              content={
                PERSONALLOGO().DESING_COLOR.TOOLS[5].colorBoxes[1].content
              }
            />
          </>
        ),
      7: PERSONALLOGO().DESING_COLOR.TOOLS &&
        PERSONALLOGO().DESING_COLOR.TOOLS[7] && (
          <>
            <DesignColorImg
              src={PERSONALLOGO().DESING_COLOR.TOOLS[7].img}
              alt={'Design Color'}
            />
          </>
        ),
    },
  });
