import styled, { css } from 'styled-components';
import { Container, Typography, media } from '../../../components';
import { theme } from '../../../const';

const AudienceBase = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;
  justify-items: center;

  & > *:nth-child(2) {
    grid-column: 1 / 3;
  }

  ${(props) =>
    props.columnMode &&
    media.desktop`
      grid-template-columns: initial;

      & > *:nth-child(2) {
        grid-column: 1;
      }
    `}

  ${media.tablet`
    grid-template-columns: 1fr;    
    grid-auto-rows:  max-content;
    grid-row-gap: 3rem;

    & > *:nth-child(2) {
      grid-column: 1;
    }
  `}

  ${media.mobile`
    grid-row-gap: 2rem;
    padding-bottom: 1.5rem;
  `}
`;

const PersonBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;

  width: 100%;
  padding: 3rem;

  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows[0]};
  border-radius: 0.5rem;

  ${(props) =>
    props.columnMode &&
    media.desktop`
      grid-template-columns:
        minmax(min-content, 34rem) minmax(min-content, 37rem)
        minmax(min-content, 34rem);
      grid-template-rows: max-content max-content;
      grid-auto-flow: column;
      grid-column-gap: 3rem;

      & > *:nth-child(n + 3) {
        grid-row: 1 / 3;
      }
    `}

  ${media.tablet`
    padding: 2rem;
  `}

  ${media.mobile`
    padding: 1.5rem;
  `}
`;

const PersonHeader = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 4.1rem 1fr;
  grid-column-gap: 3rem;
  grid-row-gap: 0.6rem;
  margin-bottom: 2rem;

  & > *:nth-child(2) {
    align-self: flex-end;
  }

  ${(props) =>
    props.columnMode &&
    media.desktop`
      grid-template-rows: 3.2rem 1fr;
      grid-column-gap: 2rem;
      grid-row-gap: 0.2rem;
      align-items: center;

      & > *:nth-last-child(1) {
        display: grid;
        grid-auto-rows: max-content;
        grid-auto-flow: row;
        grid-row-gap: 0.2rem;
      }
    `}

  ${media.tablet`
    grid-template-rows: 4rem 1fr;
    grid-column-gap: 2rem;
  `}

  ${media.mobile`
    /* grid-template-rows: repeat(2, max-content); */
    grid-template-rows: minmax(min-content, 100%) minmax(min-content, 1.9rem);
    grid-column-gap: 1rem;
    grid-row-gap: 0.4rem;
    margin-bottom: 1.5rem;
  `}
`;

const PersonAvatar = styled.div`
  grid-area: 1 / 1 / 3 / 2;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  overflow: hidden;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.avatar});

  ${media.mobile`
    width: 5rem;
    height: 5rem;
  `}
`;

const PersonSubname = styled(Typography)`
  ${media.mobile`
    line-height: 1.6rem;
  `}
`;

const PersonInfoBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
`;

const PersonInfoTopBorder = styled.div`
  height: 0.1rem;
  background-color: ${theme.colors.lightGray};
  margin: 3rem 0;

  ${(props) =>
    props.columnMode &&
    css`
      display: none;
    `}

  ${media.tablet`
    display: initial;
    margin: 2rem 0;
  `}

  ${media.mobile`
    margin: 1.5rem 0;
  `}
`;

const PersonInfoItem = styled(Typography)`
  margin-top: 1rem;

  &:nth-child(n + 4) {
    margin-top: 1.5rem;

    ${media.tablet`
      margin-top: 1rem;
    `}
  }

  ${(props) =>
    props.columnMode &&
    media.desktop`
      &:nth-child(n + 3) {
        margin-top: 1.5rem;
      }
    `}
`;

const PersonInfo = ({ title, tools, columnMode }) => {
  return (
    <PersonInfoBase>
      <PersonInfoTopBorder columnMode={columnMode} />
      <Typography variant={'24/36'} weight={theme.font.weightBold}>
        {title}
      </Typography>
      {tools.map((tool, i) => (
        <PersonInfoItem
          key={i}
          variant={'20/28'}
          columnMode={columnMode}
          listItem
        >
          {tool}
        </PersonInfoItem>
      ))}
    </PersonInfoBase>
  );
};

const Person = ({
  avatar,
  name,
  subname,
  content,
  goals,
  emotions,
  columnMode,
}) => {
  return (
    <PersonBase columnMode={columnMode}>
      <PersonHeader columnMode={columnMode}>
        <PersonAvatar avatar={avatar} />
        <Typography variant={'24/29'} weight={theme.font.weightBold}>
          {name}
        </Typography>
        <PersonSubname variant={'20/28'} color={theme.colors.gray700}>
          {subname}
        </PersonSubname>
      </PersonHeader>
      <Typography variant={'20/28'}>{content}</Typography>
      <PersonInfo
        title={goals.title}
        tools={goals.tools}
        columnMode={columnMode}
      />
      <PersonInfo
        title={emotions.title}
        tools={emotions.tools}
        columnMode={columnMode}
      />
    </PersonBase>
  );
};

export const Audience = ({ PAGE, TITLE, PERSONS, COLUMN_MODE }) => {
  return (
    <AudienceBase backgroundLight columnMode={COLUMN_MODE}>
      <Typography variant={'40/48'} weight={theme.font.weight600}>
        {TITLE}
      </Typography>
      {PERSONS.map(({ avatar, name, subname, content, goals, emotions }, i) => (
        <Person
          key={i}
          avatar={avatar}
          name={name}
          subname={subname}
          content={content}
          goals={goals}
          emotions={emotions}
          columnMode={COLUMN_MODE}
        />
      ))}
    </AudienceBase>
  );
};
