import { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { IconSVG, media } from '../../../components';
import { useScroll } from '../../../utils';
import { theme } from '../../../const';

const ScrollToUpButtonBase = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;

  width: 5.6rem;
  height: 5.6rem;
  padding: 0;
  background-color: ${theme.colors.blue};
  box-shadow: ${theme.shadows[25]};
  border-radius: 50%;
  cursor: pointer;

  position: fixed;
  right: 5rem;
  bottom: -6rem;
  z-index: 9000;
  transition: bottom 0.3s;

  &:hover {
    box-shadow: ${theme.shadows[4]};

    & > * {
      width: 1.8rem;
      height: 2.7rem;
    }
  }

  & > * {
    transition: width 0.3s, height 0.3s;
  }

  ${(props) =>
    (props.scrollTo === 'top' || props.isScrollStop) &&
    css`
      bottom: 5rem;
    `}

  ${media.tablet`
    right: 3.1rem;
    
    ${(props) =>
      (props.scrollTo === 'top' || props.isScrollStop) &&
      css`
        bottom: 3.1rem;
      `}
  `}

  ${media.mobile`
    right: 1.5rem;

    ${(props) =>
      (props.scrollTo === 'top' || props.isScrollStop) &&
      css`
        bottom: 1.5rem;
      `}
  `}
`;

export const ScrollToUpButton = () => {
  const [scrollTo, isScrollStop] = useScroll({
    minScrollTop: 68,
    unStop: true,
  });

  const handleOnClick = useCallback(() => {
    document.getElementById('root').scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <ScrollToUpButtonBase
      scrollTo={scrollTo}
      isScrollStop={isScrollStop}
      onClick={handleOnClick}
    >
      <IconSVG
        iconName={'icon-white-up-arrow'}
        width={'1.6rem'}
        height={'2.4rem'}
      />
    </ScrollToUpButtonBase>
  );
};
