import { Link as RouteLink } from 'react-router-dom';
import styled from 'styled-components';
import { ButtonBaseCSS } from './Button';

const LinkToWithoutSite = styled.a`
  text-decoration: none;
  ${ButtonBaseCSS}
`;

const Link = styled(RouteLink)`
  text-decoration: none;
  ${ButtonBaseCSS}
`;

export const LinkButton = ({
  variant = 'contained',
  color = 'blue',
  size = 'medium',
  isActive,
  disabled,
  onlyText = false,
  href,
  to,
  children,
  ...props
}) => {
  return (
    <>
      {href && (
        <LinkToWithoutSite
          href={href}
          data-variant={variant}
          data-color={color}
          size={size}
          data-active={isActive}
          data-only-text={onlyText}
          disabled={disabled}
          {...props}
        >
          {children}
        </LinkToWithoutSite>
      )}
      {to && (
        <Link
          to={to}
          data-variant={variant}
          data-color={color}
          size={size}
          data-active={isActive}
          disabled={disabled}
          data-only-text={onlyText}
          {...props}
        >
          {children}
        </Link>
      )}
    </>
  );
};
