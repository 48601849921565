import { Container } from '../../components';
import { PathNavigation, ScrollToUpButton, Header } from '../components';
import { Projects } from './Projects';
import { BRANDINGPROJECTS } from '../../const';

export const BrandingProjects = () => {
  return (
    <>
      <PathNavigation paths={BRANDINGPROJECTS().PATHS} />
      <ScrollToUpButton />
      <Container main>
        <Header PAGE={BRANDINGPROJECTS().PAGE} {...BRANDINGPROJECTS().HEADER} />
        <Projects PAGE={BRANDINGPROJECTS().PAGE} />
      </Container>
    </>
  );
};
