import { Typography } from '../../../components';
import { theme } from '../../../const';

export const RowBigTitle = ({ title }) => {
  if (!title) return null;

  return (
    <Typography variant={'24/29'} weight={theme.font.weightBold}>
      {title}
    </Typography>
  );
};
