import { importImg } from '../images';

export const BLOGS = () => [
  {
    img: importImg('BlogCardImg1.png'),
    title: 'How I designed my website with the help of adplist.org',
    content:
      'A portfolio is an essential tool for a designer to demonstrate their knowledge, skills, and experience. As a UX designer, I noticed the urge for a great portfolio in the early days of my career…',
    createdAt: 'Jan 16, 2022',
    link: '/blog/1',
    linkText: 'READ POST',
  },
  {
    img: importImg('BlogCardImg2.png'),
    title: 'Why it is important to know Material Design guidelines',
    content:
      '“Thinking outside the box” sounds like giving total freedom for creativity to spark. It sounds like there are no limits, borders, or rules to follow. It is very appealing to think of creative people…',
    createdAt: 'Jan 16, 2022',
    link: '/blog/2',
    linkText: 'READ POST',
  },
  {
    img: importImg('BlogCardImg3.png'),
    title: 'Why is problem-solving so important for a UX designer?',
    content:
      'UX design trend is evolving and emerging fast. Only a couple of years ago UX design was something unnoticed and many would just look at it skeptically. However, nowadays it seems that…',
    createdAt: 'Jan 16, 2022',
    link: '/blog/3',
    linkText: 'READ POST',
  },
];
