import styled from 'styled-components';
import { Typography, sizes } from '../../../components';
import { theme } from '../../../const';

const RowTitle = styled(Typography)`
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${theme.font.size20};
    line-height: ${theme.font.lineHeight22};
  }

  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size18};
    font-weight: ${theme.font.weight600};
  }
`;

export const RowTitles = ({ titles }) => {
  if (!titles) return null;

  return titles.map((title, i) => (
    <RowTitle key={i} variant={'20/28'} weight={theme.font.weightBold} nowrap>
      {title}
    </RowTitle>
  ));
};
