import { importImg } from '../images';

export const BLOGDETAILS = () => ({
  1: {
    PATHS: [
      { path: '/blog', text: 'Blog' },
      {
        path: '/blog/1',
        text: 'How I designed my website with the help of adplist.org',
      },
    ],
    HEADER: {
      BACKGROUND_IMG: importImg('BlogDetails1Header.jpg'),
    },
    POST: [
      { title: 'How I designed my website with the help of adplist.org' },
      {
        content:
          'A portfolio is an essential tool for a designer to demonstrate their knowledge, skills, and experience. As a UX designer, I noticed the urge for a great portfolio in the early days of my career, but it took me 6 whole years to finally design a professional portfolio. Adplist.org played a very important role in this journey of designing my portfolio.',
      },
      {
        content:
          'Feedback is an unimaginably crucial element in a designer career. Good things turn into great things with proper feedback from professionals. Why am I talking about the feedback you think? The reason is that I had been having a nightmare getting UX professionals’ feedback on my work until I discovered adplis.org.',
      },
      { img: importImg('BlogDetails1PostImg1.jpg') },
      {
        content:
          'The portfolio journey started 6 years ago. In the beginning, I did not seem to be very crucial to designing the portfolio, so I just made little plans. A year later I finally started working on my portfolio and designed the first version. I even coded the website myself and launched it. However, the website did not live long and was shut down a year later. I simply got very busy with projects and did not pay attention to the portfolio.',
      },
      {
        content:
          'At the end of 2019, the urge for a portfolio fired up again and I decided to collect the projects I had worked on into a PDF. I was happy to finish it, but it did not bring the expected results. I could state that it was a failure. The reason is that I was supposed to make case studies of my projects, but instead, I just made a list of the final looks of the projects. That is the reason I got rejected by several companies that did not believe in my skills.',
      },
      {
        content:
          'In 2021, I finally made up my mind and planned to design my portfolio website with the case studies of the projects. The first thing I did was to complete the design of my logo which was in the process for the last 4 years. I successfully completed the logo and then I concentrated on the website. There I met a lot of difficulties from a feedback perspective.',
      },
      { img: importImg('BlogDetails1PostImg2.jpg') },
      {
        content:
          'The reason for the issue is that the designers around me are visual designers, not users. I wanted to get feedback from UX designers, especially UX managers as they were my target audience. I relied on LinkedIn and sent requests to different UX managers from across the world. But I did not get a single reply and I was devastated. I thought that I could easily approach a designer and ask for their help. But I ended up with a bunch of requests without a single reply.',
      },
      {
        content:
          'I did not know what to do at that time. The project needs to be completed, days are going, time is ticking but I have no source of feedback. On one of those days, I saw a post by a designer in my LinkedIn feed. The post was about adplist.org and it got me interested. So, I visited adplist.org and ended up registering.',
      },
      { img: importImg('BlogDetails1PostImg3.jpg') },
      {
        content:
          'My first meeting with a professional UXer came fast and it was full of useful tips. I finally could improve my website based on the feedback received from my target audience. I have scheduled a lot of meetings with UX professionals from the US, Canada, the UK, Australia, and other European countries. All the feedback received helped me design and test my portfolio website. Besides, I got to meet real UX professionals and learn about their work processes. I exchanged ideas with them and learned from them.',
      },
      {
        content:
          'The sweet part is that for all above stated I paid nothing, as the service is totally free. I strongly encourage designers to sign up for adplist.org and get the benefits. I’m not going to stop using it even if the service becomes paid. I have plans to become a mentor and help other designers across the world to enhance their career.',
      },
    ],
  },
  2: {
    PATHS: [
      { path: '/blog', text: 'Blog' },
      {
        path: '/blog/2',
        text: 'Why it is important to know Material Design guidelines',
      },
    ],
    HEADER: {
      BACKGROUND_IMG: importImg('BlogDetails2Header.jpg'),
    },
    POST: [
      { title: 'Why it is important to know Material Design guidelines' },
      {
        content:
          '“Thinking outside the box” sounds like giving total freedom for creativity to spark. It sounds like there are no limits, borders, or rules to follow. It is very appealing to think of creative people that way, especially looking at the paintings of famous artists. From first sight it looks like there are no rules, those art pieces just happened in the peak of the artist’s creativity.',
      },
      {
        content:
          'However, it is such a big misconception of how creativity works. In design as in many other fields, there are several certain rules to know and follow. The reason for those rules is the human mind. Our brain is created and developed in such a way that we find it hard to accept and analyze unorganized data. For us, as human beings, it makes a lot better sense when the data we accept is organized in a clear and obvious way.',
      },
      {
        content:
          'To make my point clear, consider yourself driving on a road that is organized and controlled by the traffic lights. The experience seems normal, nothing that bothers you right. Now, imagine yourself driving in a messy street that has no traffic lights, packed up with cars, and with a huge traffic jam where inpatient drivers are pushing the horns constantly. You cannot think straight in this situation and the decisions you make or things you do would look weird. The reason is that our brain cannot analyze the data coming from an unorganized source.',
      },
      { img: importImg('BlogDetails2PostImg1.jpg') },
      {
        content:
          'You might ask why I am talking about the human brain. The reason is simple, Design is also full of rules and guidelines that help us designers provide our work in a logical, organized, and most importantly understandable way to our users. Aside from the basic design principles, we have design systems that help us designers organize our work so that it makes our job easy. Without design systems, our files would be a mess and we would be wasting our time redesigning the same things repeatedly.',
      },
      {
        content:
          'Over the years, I have experienced the need for design systems with different projects. Initially, it was all about icons, but later I noticed that pretty much every single piece of the UI I’m working on can in the design system. Bit by bit I started implementing design systems in my projects. My first and the deepest learned design system was Material design guidelines.',
      },
      {
        content:
          'In 2014 Google introduced a new concept, Material design. The previous version of Android looked bad compared to the iOS design. But with the new approach, Google showed how a good design system looks and works. The more I learned it, the more I fell in love with it. Besides the amazing design system, Google also provided very detailed guidelines on how to use it. I discovered that Material design guidelines are the best in the market to learn. I encourage young designers to start with it.',
      },
      { img: importImg('BlogDetails2PostImg2.jpg') },
      {
        content: (
          <>
            As of today, 72.2% (data from{' '}
            <a href={'https://www.techrepublic.com/'}>techrepublic.com</a>) of
            all mobile devices in the world use Android. It means that 2/3 of
            all device OS in the world is designed based on Material Design.
            This is a very strong argument for a UI/UX designer to consider.
            Because most of the application design projects will be based on
            Material Design.
          </>
        ),
      },
      {
        content:
          'For a young designer who just stepped into the design field, the material design guidelines are the perfect start with all those details of how to design every single possible element of the UI. This is another very strong argument because no other popular design systems by Apple, Microsoft, Salesforce, Uber, Atlassian and etc. are not as detailed as the Material Design guidelines.',
      },
      {
        content:
          'None of the competitors’ design system guidelines is as good as Material design guidelines. For example, Apple’s human interaction design guidelines lack details, and sometimes it is hard to understand what they are talking about. It took a while for me to get used to and understand iOS design guidelines. Besides, I had to spend tons of time and lots of effort to learn the guidelines from the UI kits they provide.',
      },
      {
        content:
          'Windows Fluent design system is another competitor for Material design. The long-lost phone manufacturer Nokia wanted to catch up with rivals Apple and Samsung, so they started a partnership with Windows wanted to get a piece of the yummy phone market. However, their effort did not bring the results they expected, and, in the end, we said goodbye to Windows Phones. I was happy about it by the way because trying to learn two design systems was hard enough when another gamer joined the race. So long story short, Windows Phone is off the table, and Nokia after realizing their second mistake in the last 10 years, started using Android like any other phone manufacturer in the market. Now, we don’t have to think about Windows phones, but I strongly encourage designers to learn Windows’ Fluent design guidelines. Especially the ones who design software for windows, you guys know how it is important to know Fluent design principles.',
      },
      {
        content:
          'One more argument is the foldable devices that could be a very problematic headache for designers. Phone manufacturers such as Samsung, Xiaomi, Huawei, and LG fighting each other in the market of foldable devices. I guess this trend will continue for a while in the future so we, designers should be flexible enough and learn the new techniques to provide a better user experience in our designs. To help us there, Google updated the Material design with Material You. They took the trend seriously and provided us with guidelines on how to design android apps for foldable devices. I have not seen anything like that from Apple as the company is not even in the race of foldable devices. All other companies simply use Material design, well except for Huawei. According to some rumors, because of the limits by Google towards Huawei, they decided to develop their own OS which means that soon we will face another design system that is developed especially for Huawei devices.',
      },
      { img: importImg('BlogDetails2PostImg3.jpg') },
      {
        content:
          'To sum up, if you want to design mobile applications you will have to learn Material design guidelines. These are the main reasons you should do that:',
      },
      {
        items: [
          '72.2% of the phone market uses Android',
          'Material design guidelines are the most detailed ones in the market.',
          'I can for sure say that it is a lot easier than Apple Human Interface guidelines',
          'You get to learn about how to design foldable devices with Material You update.',
          'I did not mention it in the article, but the Material design is universal and can be used for any device. You can design websites, tablets, watch, mobile apps and etc.',
          'Once you learn Material Design, any other design systems you open after look very easy.',
          'You will have a huge fundamental knowledge of how to create your own design systems.',
        ],
      },
    ],
  },
  3: {
    PATHS: [
      { path: '/blog', text: 'Blog' },
      {
        path: '/blog/3',
        text: 'Why is problem-solving so important for a UX designer?',
      },
    ],
    HEADER: {
      BACKGROUND_IMG: importImg('BlogDetails3Header.jpg'),
    },
    POST: [
      { title: 'Why is problem-solving so important for a UX designer?' },
      {
        content:
          'UX design trend is evolving and emerging fast. Only a couple of years ago UX design was something unnoticed and many would just look at it skeptically. However, nowadays it seems that everyone is going crazy about it. I guess people started noticing the benefit of UX.',
      },
      {
        content:
          'The term UX was introduced about 25-30 years ago and has just recently been picked up. 10 years ago, it was hardly possible to hear about UX design. The term is young but the ideology behind it has a very deep history.',
      },
      {
        content:
          'It got me thinking if the term UX is new, then what is the ideology behind it that has been alive for centuries. From my point of view and experience, I find “Problem-solving” is the core skill and ideology behind UX. We can look at things from any direction and still see that behind any action and movement in this field we can find problem-solving. For instance, you want to conduct usability testing with your users. You can find 10 problems with the product, but if you don’t know which problem to address the first or the most, then your findings are useless.',
      },
      {
        content:
          'No matter how we think about it, we still come to problem-solving. We take any company, product, or service and analyze their situation. We can for sure identify issues that need to be resolved. The difference between successful and failed companies, products, or services is that the first ones addressed the right problems and provided the right solutions at the right time. The latter missed doing that which eventually resulted in their failure.',
      },
      {
        content:
          'Blockbuster is a good example of a failed company. Renting video materials was something people loved at that time, but Blockbuster could not handle the new technological opportunities on time and very soon the company disappeared. Netflix on the other hand addressed the problems with correct solutions and became one of the best video streaming companies in the world.',
      },
      { img: importImg('BlogDetails3PostImg1.jpg') },
      {
        content:
          'We spend days and weeks going out and meeting our users. We collect tons of materials and data to be able to find the most painful issues. But if we make a mistake with the issues and the actions on them, the consequences could be catastrophic. That is why UX professionals tell you that problem solving is a very important skill a UX designer must have.',
      },
      {
        content:
          'There are no straightforward rules or guidelines to use to learn problem solving. You cannot just take one technique and apply it everywhere. Doing that would not work and would not give you the expected results. That is why we have a huge number of techniques that can be applied to find and solve problems.',
      },
      { img: importImg('BlogDetails3PostImg2.jpg') },
      {
        content:
          '4 years ago, I was taking courses to learn new skills. When I started learning about Design Thinking, I was honestly surprised and disappointed as well. I was surprised to know that there are things I had not known and when applied properly they could bring tons of benefits. I was disappointed that I was using them in the my project. The more I learned the more disappointed I got. The reason is that UX professionals around the world were using all of those techniques. What I was doing seemed was not enough to be considered a UX designer.',
      },
      {
        content:
          'Until I started using adplist.org my mind has been stuck with that idea. In adplist.org I could talk to UX professionals in 1:1 meetings. The more I talked to UX designers, the more I discovered that most of them don’t use most of those school-taught techniques. They were just like me, using whatever technique they can to identify problems and find solutions.',
      },
      {
        content:
          'I wanted to find more so I approached a number of designers asking them how they solved problems. Besides that, I analyzed how big tech companies use all those shiny techniques and solve problems they face. I discovered every single company or individual I analyzed uses their own adjusted methods and techniques to solve problems. I discovered that it does not matter what techniques they use, what methods they apply, or what methodologies they take. The only thing that matters is to find the right problems and act on them.',
      },
      { img: importImg('BlogDetails3PostImg3.jpg') },
      {
        content:
          'I have changed my understanding of a UX designer. For me, UX designers are problem solvers and I believe I’m one of them. No matter what methods or techniques we use, the main purpose would still be to identify problems and act on them to result in a great user experience. I still take courses to this day, and I have no intention to stop doing that. Because I do believe now that the more I learn the more techniques I can have to choose from. Besides, the more I practice the better problem solver I become.',
      },
      {
        content:
          'One more thing that changed in me is that I don’t use any technique blindly. Instead, I think about which techniques or approaches are the best for the situation and try to use them. It does not matter what techniques or methods are used; the core purpose anyway still be to provide a better user experience by addressing the right issues.',
      },
    ],
  },
});
