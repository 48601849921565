import { createGlobalStyle, css } from 'styled-components';
import { theme } from '../const/theme';
import '../assets';

export const sizes = {
  desktop: 1231,
  tablet: 1230,
  smallTablet: 1000,
  mobile: 767,
};

const mediaTypes = {
  desktop: 'min-width',
  tablet: 'max-width',
  smallTablet: 'max-width',
  mobile: 'max-width',
};

export const media = Object.keys(sizes).reduce((media, index) => {
  const mediatype = `${mediaTypes[index]}: ${sizes[index]}px`;
  media[index] = (...args) => css`
    @media (${mediatype}) {
      ${args}
    }
  `;

  return media;
}, {});

export const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    outline: none;
  }

  html {
    font-size: 62.5%;
    font-family: ${theme.font.family}, Arial, Helvetica, sans-serif;
    
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  body {
    box-sizing: border-box;
    overflow: hidden;
  }

  #root {
    height: 100vh;

    position: sticky;
    overflow: overlay;
    background-color: ${theme.colors.white};
  }


  *::-webkit-scrollbar {
    width: 1.2rem;
    height: 1.2rem;

    ${media.mobile`
      width: 0.6rem;
      height: 0.6rem;
    `}
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: rgba(0,0,0,0.5);

    ${media.mobile`
      border-radius: 0.5rem;
    `}
  }

  *::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0.6rem rgba(0,0,0,0.5);
    border-radius: 1rem;

    ${media.mobile`
      border-radius: 0.5rem;
    `}
  }
`;
