import React from 'react';
import styled, { css } from 'styled-components';
import {
  Container,
  LinkButton,
  IconSVG,
  media,
  sizes,
} from '../../../components';
import { useScroll } from '../../../utils';
import { theme } from '../../../const';

const PathNavigationBase = styled(Container)`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  grid-column-gap: 0.8rem;
  align-items: center;

  height: 4rem;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 0.4rem));
  z-index: 1000;
  transition: transform 0.3s;

  ${(props) =>
    (props.scrollTo === 'top' || props.isScrollStop) &&
    css`
      transform: translate(-50%, 0);
    `}

  ${media.tablet`
    max-width: 106.2rem;
    height: 4.8rem;
    padding: 0 3.1rem;
  `}

  ${media.mobile`
    grid-template-columns: repeat(2, max-content) 1fr;
    grid-column-gap: 0.4rem;
    height: 4rem;
    padding: 0 1.5rem;
  `}
`;

const PathNavigationBackground = css`
  box-shadow: ${theme.shadows[1]};
  background-color: ${theme.colors.white};
`;

const PathNavigationLink = styled(LinkButton)`
  display: initial;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: ${sizes.tablet}px) {
    &[data-variant='text'][data-color='gray-2'][data-active='true'] {
      color: ${theme.colors.gray};
    }

    &[data-variant='text'][data-color='gray-2'][data-active='false'] {
      color: ${theme.colors.blue};
    }
  } ;
`;

const PathNavigationArrow = styled(IconSVG)``;

const PathNavigationSpace = styled.div`
  width: 100%;
  height: 4rem;

  ${media.tablet`
    height: 4.8rem;
  `}

  ${media.mobile`
    height: 4rem;
  `}
`;

export const PathNavigation = ({ paths = [] }) => {
  const [scrollTo, isScrollStop] = useScroll({ minScrollTop: 52 });

  return (
    <>
      <PathNavigationBase
        scrollTo={scrollTo}
        isScrollStop={isScrollStop}
        backgroundCSS={PathNavigationBackground}
      >
        {paths.map(({ path, text }, i) => (
          <React.Fragment key={i}>
            <PathNavigationLink
              variant={'text'}
              color={'gray-2'}
              size={'big'}
              to={path}
              isActive={paths.length - 1 === i}
              onlyText
            >
              {text}
            </PathNavigationLink>
            {i < paths.length - 1 && (
              <PathNavigationArrow
                iconName={'icon-right-arrow'}
                iconColor={theme.colors.gray900}
                size={'2.4rem'}
              />
            )}
          </React.Fragment>
        ))}
      </PathNavigationBase>
      <PathNavigationSpace />
    </>
  );
};
