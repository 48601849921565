import styled from 'styled-components';
import { Typography, SocialButton, media, sizes } from '../../../components';
import { theme, PORTFOLIO } from '../../../const';

const FooterBase = styled.div`
  display: grid;
  grid-template-rows: repeat(4, max-content);
  grid-row-gap: 1rem;
  justify-items: center;
  padding: 10rem 0;

  ${media.mobile`
    padding: 6rem 0;
  `}
`;

const FooterContent = styled(Typography)`
  line-height: ${theme.font.lineHeight28};

  @media (max-width: ${sizes.tablet}px) {
    font-size: ${theme.font.size18};
    font-weight: ${theme.font.weight400};
    text-align: center;
  }

  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size16};
    line-height: ${theme.font.lineHeight20};
  }
`;

const FooterEmail = styled(Typography)`
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${theme.font.size18};
    font-weight: ${theme.font.weight600};
    line-height: ${theme.font.lineHeight22};
  }

  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size16};
    line-height: ${theme.font.lineHeight19};
  }
`;

const SocialNetworks = styled.div`
  display: grid;
  grid-template-columns: repeat(4, max-content);
  grid-column-gap: 2rem;
  margin-top: 2rem;

  ${media.mobile`
    margin-top: 1rem;
  `}
`;

export const Footer = () => {
  return (
    <FooterBase>
      <Typography variant={'40/48'} weight={theme.font.weight600}>
        {PORTFOLIO().FOOTER.TITLE}
      </Typography>
      <FooterContent variant={'20/28'}>
        {PORTFOLIO().FOOTER.CONTENT}
      </FooterContent>
      <FooterEmail variant={'20/24'} weight={theme.font.weightBold}>
        {PORTFOLIO().FOOTER.EMAIL}
      </FooterEmail>
      <SocialNetworks>
        <SocialButton
          href={PORTFOLIO().FOOTER.LINKEDIN}
          iconName={'icon-linkedin'}
        />
        <SocialButton
          href={PORTFOLIO().FOOTER.INSTAGRAM}
          iconName={'icon-instagram'}
        />
        <SocialButton
          href={PORTFOLIO().FOOTER.DRIBBBLE}
          iconName={'icon-dribbble'}
        />
        <SocialButton
          href={PORTFOLIO().FOOTER.BEHANCE}
          iconName={'icon-behance'}
        />
      </SocialNetworks>
    </FooterBase>
  );
};
