import styled from 'styled-components';
import { ToolBox } from '../components';
import { Container, Typography, IconSVG, media } from '../../components';
import { theme } from '../../const';

const ChangesBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  justify-items: center;

  & > *:nth-child(3) {
    max-width: 57rem;
    margin: 1rem 0 3rem;

    ${media.mobile`
      margin: 1rem 0;
    `}
  }
`;

const ChangeSteps = styled.div`
  display: grid;
  grid-template-columns: 27rem 1fr;
  grid-template-rows: repeat(2, max-content);
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;
  align-items: center;

  ${media.tablet`
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;

    & > *:nth-child(3) {
      margin-top: 2rem;
    }
  `}

  ${media.mobile`
    & > *:nth-child(3) {
      margin-top: 1rem;
    }
  `}
`;

const ChangeStep = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;
  justify-items: center;

  ${media.tablet`
    justify-items: initial;
    justify-content: space-between;
  `}

  ${media.mobile`
    grid-template-columns: minmax(min-content,max-content) max-content minmax(min-content,max-content) max-content minmax(min-content,max-content);
  `}
`;

const ChangePhoneImg = styled.img`
  width: 24rem;

  ${media.tablet`
    width: 18rem;
  `}

  ${media.mobile`
    width: 100%;
    max-width: 9.3rem;
  `}
`;

const NextArrow = styled(IconSVG)`
  ${media.mobile`
    width: 2.5rem;
    height: 2.5rem;
  `}
`;

export const Changes = ({
  PAGE,
  TITLE,
  CONTENT,
  DETAILS_TITLE,
  DETAILS,
  PHONE_IMG1,
  PHONE_IMG2,
  PHONE_IMG3,
  WIRELESS_TITLE,
  WIRELESS,
  PHONE_IMG4,
  PHONE_IMG5,
  PHONE_IMG6,
}) => {
  return (
    <ChangesBase backgroundLight>
      <Typography
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </Typography>
      <Typography variant={'20/28'}>{CONTENT}</Typography>
      <ChangeSteps>
        <ToolBox
          iconName={'icon-one-box'}
          iconColor={theme.colors.red}
          title={DETAILS_TITLE}
          content={DETAILS}
          tabletColumnMode
        />
        <ChangeStep>
          <ChangePhoneImg src={PHONE_IMG1} alt={'Phone'} />
          <NextArrow iconName={'icon-next'} size={'4.8rem'} />
          <ChangePhoneImg src={PHONE_IMG2} alt={'Phone'} />
          <NextArrow iconName={'icon-next'} size={'4.8rem'} />
          <ChangePhoneImg src={PHONE_IMG3} alt={'Phone'} />
        </ChangeStep>
        <ToolBox
          iconName={'icon-two-box'}
          iconColor={theme.colors.red}
          title={WIRELESS_TITLE}
          content={WIRELESS}
          tabletColumnMode
        />
        <ChangeStep>
          <ChangePhoneImg src={PHONE_IMG4} alt={'Phone'} />
          <NextArrow iconName={'icon-next'} size={'4.8rem'} />
          <ChangePhoneImg src={PHONE_IMG5} alt={'Phone'} />
          <NextArrow iconName={'icon-next'} size={'4.8rem'} />
          <ChangePhoneImg src={PHONE_IMG6} alt={'Phone'} />
        </ChangeStep>
      </ChangeSteps>
    </ChangesBase>
  );
};
