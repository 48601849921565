import styled, { css } from 'styled-components';
import {
  Container,
  ContainerBackground,
  Logo as LogoBase,
  LinkButton,
  Typography,
  media,
} from '../../components';
import { theme, ERROR as ERROR_CONST } from '../../const';

const ErrorBase = styled(Container)`
  height: 100vh;
  padding-top: 0;
  padding-bottom: 0;
`;

const ErrorBackgroundCSS = css`
  background-color: ${theme.colors.lightBlue800};
`;

const ErrorBackgroundImg = styled(ContainerBackground)`
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  background-image: url(${(props) => props.img});

  height: calc(100vh - 10rem);
  bottom: 0;
  z-index: 0 !important;

  ${media.tablet`
    height: calc(100vh - 29.1rem);
  `}

  ${media.mobile`
    height: calc((458 / 780) * 100%);
  `}

  @media (max-height: 779px) {
    height: calc((458 / 780) * 100%);
  }
`;

const Logo = styled(LogoBase)`
  position: absolute;
  top: 1.2rem;

  ${media.tablet`
    top: 2rem;
  `}

  ${media.mobile`
    top: 2.4rem;
    left: 3rem;
  `}
`;

const ErrorMain = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 1rem;
  justify-items: center;

  width: 100%;
  position: absolute;
  top: 24rem;
  left: 50%;
  transform: translateX(-50%);

  ${media.tablet`
    top: 30rem;
  `}

  @media (max-height: 779px) {
    top: 45%;
    transform: translate(-50%, -50%);
  }
`;

const GoToHomePage = styled(LinkButton)`
  margin-top: 2rem;
  padding: 0 7rem;

  ${media.mobile`
    width: 100%;  
    max-width: 27rem;
    padding: 0;
  `}
`;

export const Error = () => {
  const ERROR = ERROR_CONST();

  return (
    <ErrorBase backgroundCSS={ErrorBackgroundCSS} overlay>
      <ErrorBackgroundImg img={ERROR.IMG} />
      <Logo />
      <ErrorMain>
        <Typography
          variant={'32/40'}
          weight={theme.font.weightBold}
          color={theme.colors.red}
        >
          {ERROR.TITLE1}
        </Typography>
        <Typography
          variant={'32/38'}
          weight={theme.font.weight600}
          align={'center'}
        >
          {ERROR.TITLE2}
        </Typography>
        <GoToHomePage to={'/portfolio'}>{ERROR.GO_TO_HOMEPAGE}</GoToHomePage>
      </ErrorMain>
    </ErrorBase>
  );
};
