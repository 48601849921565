import { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { IconSVG } from '../icon-svg';
import { media } from '../GlobalStyle';
import { theme } from '../../const';

const SliderWrapper = styled.div`
  width: 100%;
  padding-bottom: 7rem;
  position: relative;

  ${media.mobile`
    padding-bottom: 4.4rem;
  `}
`;

const SliderBase = styled.div`
  display: grid;
  grid-auto-flow: column;

  transition: transform 0.3s;

  ${(props) => css`
    grid-auto-columns: ${props.widthColumn};
    width: ${props.widthColumn};
    transform: translateX(${props.columnPosition * -100}%);
  `}
`;

const SliderControl = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  align-items: center;

  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;

const LeftArrow = styled(IconSVG)`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;

  fill: ${theme.colors.blue};
  color: ${theme.colors.blue400};
  transform: rotateY(180deg);
  margin-right: 3rem;
  transition: color 0.3s, fill 0.3s;
  cursor: pointer;

  &:hover {
    fill: ${theme.colors.white};
    color: ${theme.colors.blue};
  }

  ${media.mobile`
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.8rem;
  `}
`;

const Dot = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;

  background-color: ${theme.colors.blue400};
  transition: background-color 0.3s;
  cursor: pointer;

  &:nth-of-type(n + 2) {
    margin-left: 1rem;
  }

  ${(props) =>
    props.active &&
    css`
      background-color: ${theme.colors.blue};
    `}

  ${media.mobile`
    width: 0.6rem;
    height: 0.6rem;
  `}
`;

const RightArrow = styled(LeftArrow)`
  transform: rotateY(0);
  margin-right: 0rem;
  margin-left: 3rem;

  ${media.mobile`
    margin-left: 1.8rem;
  `}
`;

export const Slider = ({ widthColumn, length, children, ...props }) => {
  const [columnPosition, setColumnPosition] = useState(0);

  const handleLeftClick = useCallback(() => {
    if (columnPosition === 0) {
      setColumnPosition(length - 1);
      return;
    }

    setColumnPosition((c) => c - 1);
  }, [columnPosition, length]);

  const handleDotClick = useCallback((value) => {
    setColumnPosition(value);
  }, []);

  const handleRightClick = useCallback(() => {
    if (columnPosition === length - 1) {
      setColumnPosition(0);
      return;
    }

    setColumnPosition((c) => c + 1);
  }, [columnPosition, length]);

  return (
    <SliderWrapper>
      <SliderBase
        columnPosition={columnPosition}
        widthColumn={widthColumn}
        {...props}
      >
        {children}
      </SliderBase>
      <SliderControl>
        <LeftArrow
          iconName={'icon-next-wireframe-arrow'}
          onClick={handleLeftClick}
        />
        {new Array(length).fill().map((_, i) => (
          <Dot
            key={i}
            active={columnPosition === i}
            onClick={() => handleDotClick(i)}
          />
        ))}
        <RightArrow
          iconName={'icon-next-wireframe-arrow'}
          onClick={handleRightClick}
        />
      </SliderControl>
    </SliderWrapper>
  );
};
