import { Container } from '../../components';
import { Navigation, ScrollToUpButton, Footer } from '../components';
import { Header } from './Header';
import { Certificates } from './Certificates';
import { Hobbies } from './Hobbies';
import { Languages } from './Languages';

export const About = () => {
  return (
    <>
      <Navigation />
      <ScrollToUpButton />
      <Container main>
        <Header />
        <Certificates />
        <Hobbies />
        <Languages />
        <Footer />
      </Container>
    </>
  );
};
