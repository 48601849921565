import { ToolBox } from '../tool-box';

export const RowTools = ({ tools }) => {
  if (!tools) return null;

  return tools.map(
    (
      {
        iconName,
        iconColor,
        title,
        content,
        columnMode = true,
        tabletColumnMode = false,
        tabletRowMode = false,
        mobileColumnMode = false,
        mobileRowMode = false,
      },
      i
    ) => (
      <ToolBox
        key={i + 'tool'}
        iconName={iconName}
        iconColor={iconColor}
        title={title}
        content={content}
        columnMode={columnMode}
        tabletColumnMode={tabletColumnMode}
        tabletRowMode={tabletRowMode}
        mobileColumnMode={mobileColumnMode}
        mobileRowMode={mobileRowMode}
      />
    )
  );
};
