import styled, { css } from 'styled-components';
import { media, sizes } from '../GlobalStyle';
import { theme } from '../../const';

const TypographyBase = styled.div`
  display: initial;
  color: ${theme.colors.darkBlue};
  font-weight: ${theme.font.weightMedium};
  letter-spacing: ${(props) => props.letterSpacing | 0};
  white-space: break-spaces;

  ${(props) =>
    props.variant === '14/16' &&
    css`
      font-size: ${theme.font.size14};
      line-height: ${theme.font.lineHeight16};
    `}

  ${(props) =>
    props.variant === '16/19' &&
    css`
      font-size: ${theme.font.size16};
      line-height: ${theme.font.lineHeight19};
    `}
    
  ${(props) =>
    props.variant === '20/22' &&
    css`
      font-size: ${theme.font.size20};
      line-height: ${theme.font.lineHeight22};

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size18};
        line-height: ${theme.font.lineHeight20};
      }

      @media (max-width: ${sizes.mobile}px) {
        font-size: ${theme.font.size16};
        line-height: ${theme.font.lineHeight18};
      }
    `}

  ${(props) =>
    props.variant === '20/24' &&
    css`
      font-size: ${theme.font.size20};
      line-height: ${theme.font.lineHeight24};
    `}

  ${(props) =>
    props.variant === '20/28' &&
    css`
      font-size: ${theme.font.size20};
      line-height: ${theme.font.lineHeight28};

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size18};
        line-height: ${theme.font.lineHeight24};
      }

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size14};
        line-height: ${theme.font.lineHeight16};
      }
    `}

  ${(props) =>
    props.variant === '24/28' &&
    css`
      font-size: ${theme.font.size24};
      line-height: ${theme.font.lineHeight28};

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size20};
        line-height: ${theme.font.lineHeight24};
      }

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size14};
        line-height: ${theme.font.lineHeight18};
      }
    `}

  ${(props) =>
    props.variant === '24/29' &&
    css`
      font-size: ${theme.font.size24};
      line-height: ${theme.font.lineHeight29};

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size20};
        line-height: ${theme.font.lineHeight24};
      }

      @media (max-width: ${sizes.mobile}px) {
        font-size: ${theme.font.size18};
        line-height: ${theme.font.lineHeight22};
      }
    `}
  
  ${(props) =>
    props.variant === '24/36' &&
    css`
      font-size: ${theme.font.size24};
      line-height: ${theme.font.lineHeight36};

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size20};
        line-height: ${theme.font.lineHeight24};
      }

      @media (max-width: ${sizes.mobile}px) {
        font-size: ${theme.font.size18};
        line-height: ${theme.font.lineHeight22};
      }
    `}

  ${(props) =>
    props.variant === '32/38' &&
    css`
      font-size: ${theme.font.size32};
      line-height: ${theme.font.lineHeight38};

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size24};
        line-height: ${theme.font.lineHeight29};
      }

      @media (max-width: ${sizes.mobile}px) {
        font-size: ${theme.font.size20};
        line-height: ${theme.font.lineHeight24};
      }
    `}

  ${(props) =>
    props.variant === '32/40' &&
    css`
      font-size: ${theme.font.size32};
      line-height: ${theme.font.lineHeight40};

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size24};
        line-height: ${theme.font.lineHeight30};
      }

      @media (max-width: ${sizes.mobile}px) {
        font-size: ${theme.font.size20};
        line-height: ${theme.font.lineHeight24};
      }
    `}
    
  ${(props) =>
    props.variant === '40/48' &&
    css`
      font-size: ${theme.font.size40};
      line-height: ${theme.font.lineHeight48};

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size32};
        line-height: ${theme.font.lineHeight38};
      }

      @media (max-width: ${sizes.mobile}px) {
        font-size: ${theme.font.size24};
        line-height: ${theme.font.lineHeight29};
      }
    `}
    
  ${(props) =>
    props.variant === '48/58' &&
    css`
      font-size: ${theme.font.size48};
      line-height: ${theme.font.lineHeight58};

      @media (max-width: ${sizes.tablet}px) {
        font-size: ${theme.font.size32};
        line-height: ${theme.font.lineHeight38};
      }

      @media (max-width: ${sizes.mobile}px) {
        font-size: ${theme.font.size24};
        line-height: ${theme.font.lineHeight29};
      }
    `}

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};

  ${(props) =>
    props.weight &&
    css`
      font-weight: ${props.weight};
    `};

  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `};

  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${(props) =>
    props.listItem &&
    css`
      padding-left: 1.8rem;
      position: relative;

      &:before {
        display: initial;
        width: 0.8rem;
        height: 0.8rem;
        background-color: ${theme.colors.darkBlue};
        border-radius: 0.4rem;

        position: absolute;
        top: 0.9rem;
        left: 0;
        transform: translateY(-50%);
        content: '';
      }

      ${media.tablet`
        &:before {
          top: 1rem;
        }
      `}

      ${media.mobile`
        padding-left: 1.1rem;

        &:before {
          width: 0.6rem;
          height: 0.6rem;
          top: 0.8rem;
        }
      `}
    `}

  ${(props) =>
    props.required &&
    css`
      &:before {
        font-size: inherit;
        color: ${theme.colors.red};
        content: '*';
      }
    `}

    
  ${(props) =>
    props.note &&
    css`
      display: grid;
      grid-auto-flow: column;

      & > *:nth-child(1) {
        color: ${theme.colors.red};
      }
    `}
`;

export const Typography = ({
  //variant,
  color,
  weight,
  letterSpacing,
  //align,
  //nowrap,
  //listItem,
  //required,
  children,
  ...props
}) => {
  return (
    <TypographyBase
      color={color}
      weight={weight}
      letterSpacing={letterSpacing}
      {...props}
    >
      {children}
    </TypographyBase>
  );
};
