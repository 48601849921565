import styled, { css } from 'styled-components';
import { Container, Typography, IconSVG, media, sizes } from '../../components';
import { theme, ABOUT } from '../../const';

const LanguagesBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 5rem;
  justify-items: center;
  max-width: 100rem;

  ${media.tablet`
    grid-row-gap: 4.5rem;
    padding-right: 4.2rem;
    padding-left: 4.2rem;
  `}

  ${media.mobile`
    grid-row-gap: 2rem;
    max-width: 26rem;
    padding-right: 0;
    padding-left: 0;
  `}
`;

const LanguagesBackground = css`
  background-color: ${theme.colors.light};
`;

const LangaugesTitle = styled(Typography)`
  @media (max-width: ${sizes.tablet}px) {
    font-size: ${theme.font.size30};
    line-height: ${theme.font.lineHeight36};
  }
`;

const LanguagesCountrys = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 10rem);
  grid-auto-rows: max-content;
  justify-content: space-between;
  width: 100%;

  ${media.mobile`
    grid-template-columns: repeat(2, 8rem);
    grid-row-gap: 3rem;
  `}
`;

const LanguagesCountryBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  justify-items: center;
  justify-content: center;

  & > *:nth-child(n + 2) {
    width: max-content;
  }
`;

const FlagIcon = styled(IconSVG)`
  ${media.mobile`
    width: 8rem;
    height: 8rem;
  `}
`;

const CountryTitle = styled(Typography)`
  margin: 2rem 0 1rem;

  ${media.tablet`
    margin: 1.5rem 0 0.5rem;
  `}

  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size20};
    line-height: ${theme.font.lineHeight24};
    margin: 1rem 0 0.4rem;
  }
`;

const CountrySubtitle = styled(Typography)`
  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size18};
    line-height: ${theme.font.lineHeight22};
  }

  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size14};
    line-height: ${theme.font.lineHeight16};
  }
`;

const LanguagesCountry = ({ iconName, title, subtitle }) => {
  return (
    <LanguagesCountryBase>
      <FlagIcon iconName={iconName} size={'10rem'} />
      <CountryTitle variant={'24/29'} weight={theme.font.weightBold}>
        {title}
      </CountryTitle>
      <CountrySubtitle variant={'20/24'} align={'center'}>
        {subtitle}
      </CountrySubtitle>
    </LanguagesCountryBase>
  );
};

export const Languages = () => {
  return (
    <LanguagesBase backgroundCSS={LanguagesBackground}>
      <LangaugesTitle variant={'40/48'} weight={theme.font.weight600}>
        {ABOUT().LANGUAGES.TITLE}
      </LangaugesTitle>
      <LanguagesCountrys>
        {ABOUT().LANGUAGES.COUNTRY.map(({ iconName, title, subtitle }, i) => (
          <LanguagesCountry
            key={i}
            iconName={iconName}
            title={title}
            subtitle={subtitle}
          />
        ))}
      </LanguagesCountrys>
    </LanguagesBase>
  );
};
