import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Container, Logo, LinkButton, media, sizes } from '../../../components';
import { NavigationHamburger } from './NavigationHamburger';
import { useScroll } from '../../../utils';
import { theme, NAVIGATION } from '../../../const';

const NavigationBase = styled(Container)`
  display: grid;
  grid-template-columns: 1fr max-content;

  width: 100%;
  height: 6.4rem;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, calc(-100% - 0.4rem));
  z-index: 1000;
  transition: transform 0.3s;

  ${(props) =>
    (props.scrollTo === 'top' || props.isScrollStop) &&
    css`
      transform: translate(-50%, 0);
    `}

  ${media.tablet`
    max-width: 106.2rem;
    padding: 0 3.1rem;
  `}

  ${media.mobile`
    grid-template-rows: 4.8rem max-content;
    align-items: center;
    height: 4.8rem;
    padding: 0;

    & > *:nth-child(2) {
      margin-left: 1.5rem;
    }

    & > *:nth-last-child(1) {
      margin-right: 1.5rem;
    }
  `}
`;

const NavigationBackground = css`
  box-shadow: ${theme.shadows[1]};
  background-color: ${theme.colors.white};

  @media (max-width: ${sizes.mobile}px) {
    box-shadow: none;
    border-bottom: 0.1rem solid ${theme.colors.darkBlue300};
    pointer-events: all;
    z-index: 1;
  }
`;

const NavigationLogo = styled(Logo)`
  align-self: center;
`;

const NavigationLinksWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (max-width: ${sizes.mobile}px) {
    grid-area: 2 / 1 / 3 / 3;
    align-self: self-start;

    width: 100%;
    height: 0;
    margin-top: -0.1rem;
    background-color: ${theme.colors.white};
    border-bottom: 0.1rem solid ${theme.colors.darkBlue300};
    overflow: hidden;
    transition: height 0.3s;
    z-index: 0 !important;

    ${(props) =>
      props.isOpenMenu &&
      (props.scrollTo === 'top' || props.isScrollStop) &&
      css`
        height: 100%;
      `}
  }
`;

const NavigationLinks = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-auto-flow: column;
  height: 100%;

  @media (max-width: ${sizes.mobile}px) {
    grid-template-columns: 1fr;
    grid-auto-rows: 5.6rem;
    grid-auto-flow: row;
    width: 100%;
    height: max-content;

    & > * {
      justify-content: left;
      width: 100%;
      height: 100%;
      padding: 0 1.5rem;
    }
  }
`;

const NavigationSpace = styled.div`
  width: 100%;
  height: 6.4rem;

  ${media.mobile`
    height: 4.8rem;
  `}
`;

export const Navigation = () => {
  const pathname = useLocation().pathname;
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [scrollTo, isScrollStop] = useScroll({ minScrollTop: 68 });

  return (
    <>
      <NavigationBase
        scrollTo={scrollTo}
        isScrollStop={isScrollStop}
        backgroundCSS={NavigationBackground}
      >
        <NavigationLogo />
        <NavigationLinksWrapper
          scrollTo={scrollTo}
          isScrollStop={isScrollStop}
          isOpenMenu={isOpenMenu}
        >
          <NavigationLinks>
            <LinkButton
              variant={'text'}
              color={'gray'}
              size={'big'}
              to={'/portfolio'}
              isActive={pathname.includes('/portfolio')}
            >
              {NAVIGATION().PORTFOLIO}
            </LinkButton>
            <LinkButton
              variant={'text'}
              color={'gray'}
              size={'big'}
              to={'/about'}
              isActive={pathname.includes('/about')}
            >
              {NAVIGATION().ABOUT_ME}
            </LinkButton>
            <LinkButton
              variant={'text'}
              color={'gray'}
              size={'big'}
              to={'/blog'}
              isActive={pathname.includes('/blog')}
            >
              {NAVIGATION().BLOG}
            </LinkButton>
          </NavigationLinks>
        </NavigationLinksWrapper>
        <NavigationHamburger
          isOpenMenu={isOpenMenu}
          setIsOpenMenu={setIsOpenMenu}
        />
      </NavigationBase>
      <NavigationSpace />
    </>
  );
};
