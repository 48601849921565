import styled from 'styled-components';
import { Button, Typography, media, sizes } from '../../components';
import { theme, ABOUT } from '../../const';
import AboutResumePDF from './AboutResume.pdf';

const HeaderBase = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 3rem;

  ${media.tablet`
    padding-top: 3rem;
  `}

  ${media.mobile`
    grid-template-columns: 100%;
    grid-auto-rows: max-content;
    grid-row-gap: 2rem;
    padding-top: 0;
  `}
`;

const HeaderMain = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  justify-items: start;

  ${media.mobile`
    order: 1;
  `}
`;

const HeaderTitle = styled(Typography)`
  margin-bottom: 1rem;

  @media (max-width: ${sizes.tablet}px) {
    margin-bottom: 0.5rem;
    font-size: ${theme.font.size20};
    line-height: ${theme.font.lineHeight24};
  }

  ${media.mobile`
    margin-bottom: 0.4rem;
  `}
`;

const HeaderContents = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 2rem;
  margin: 3rem 0;

  ${media.tablet`
    grid-row-gap: 1rem;
    margin: 1rem 0 2rem;
  `}

  ${media.mobile`
    margin-top: 1.5rem;
  `}
`;

const ResumeButton = styled(Button)`
  padding-right: 6.2rem;
  padding-left: 6.2rem;

  ${media.mobile`
    width: 100%;
  `}
`;

const HeaderImg = styled.img`
  height: 57rem;

  ${media.tablet`
    height: 41rem;
  `}

  ${media.mobile`
    width: 100vw;
    height: initial;
    margin-left: -1.5rem;
  `}
`;

export const Header = () => {
  return (
    <HeaderBase>
      <HeaderMain>
        <HeaderTitle variant={'40/48'} weight={theme.font.weight600}>
          {ABOUT().HEADER.TITLE}
        </HeaderTitle>
        <Typography variant={'20/28'}>{ABOUT().HEADER.SUBTITLE}</Typography>
        <HeaderContents>
          {ABOUT().HEADER.CONTENTS.map((content, i) => (
            <Typography key={i} variant={'20/28'}>
              {content}
            </Typography>
          ))}
        </HeaderContents>
        <ResumeButton onClick={() => window.open(AboutResumePDF, 'blank')}>
          {ABOUT().HEADER.RESUME}
        </ResumeButton>
      </HeaderMain>
      <HeaderImg src={ABOUT().HEADER.IMG} alt={'About Header'} />
    </HeaderBase>
  );
};
