import { windowResizeChanger } from '../utils';
import { importImg } from '../images';

export const ABOUT = () => ({
  HEADER: {
    TITLE: 'Hi, I’m Abdulaziz',
    SUBTITLE: 'Senior UX Designer at Medyear',
    CONTENTS: [
      <>
        I enjoy designing user-centric, creative and innovative solutions that
        result in amazing <b>User Experience.</b>
      </>,
      <>
        <b>Medyear</b> is a healthcare technology company that brings health
        records and people together in groundbreaking new ways and I joined the
        company to help to innovate in the Healthcare industry.
      </>,
      <>
        I helped <b>Wing</b> (an <b>Amazon</b> company) to build a Delivery
        management system that covers all the delivery aspects and enables
        delivery/courier companies to run their businesses in an efficient way.
      </>,
      <>
        I also do brand design and I provided branding services to companies
        like <b>Wing</b> (UAE), <b>Medyear</b> (USA), <b>Jowi</b> (Russia),{' '}
        <b>Shifra technology</b> (Lebanon), <b>Safe Arrival</b> (UAE), and{' '}
        <b>Royal Family</b> (Uzbekistan).
      </>,
    ],
    RESUME: 'RESUME',
    IMG: windowResizeChanger({
      desktop: importImg('AboutHeader.jpg'),
      mobile: importImg('AboutHeaderMobile.jpg'),
    }),
  },
  CERTIFICATES: {
    TITLE: 'Certificates',
    SUBTITLE:
      'I’m a self taught designer and I always try to enhance my knowledge and skills. I read books, articles, blogs and take courses.',
    CARDS: [
      {
        iconName: 'icon-certificate-1',
        title: 'Human-Computer Interaction - HCI',
        contents: ['Interaction Design Foundation', 'Issued Jan 2022'],
        link: 'https://www.interaction-design.org/abdulaziz/certificate/course/9754aea3-3099-43ac-9260-128bb42fab0c',
      },
      {
        iconName: 'icon-certificate-1',
        title: 'Become a UX Designer from Scratch',
        contents: ['Interaction Design Foundation', 'Issued Jan 2022'],
        link: 'https://www.interaction-design.org/abdulaziz/certificate/course/86f81510-9d7c-4088-91e0-a78bee020e32',
      },
      {
        iconName: 'icon-certificate-1',
        title: 'Journey Mapping',
        contents: ['Interaction Design Foundation', 'Issued Jan 2022'],
        link: 'https://www.interaction-design.org/abdulaziz/certificate/course/642e01d3-431e-4b59-8445-ce58872e0303',
      },
      {
        iconName: 'icon-certificate-1',
        title: 'Design Thinking: The Ultimate Guide',
        contents: ['Interaction Design Foundation', 'Issued Dec 2021'],
        link: 'https://www.interaction-design.org/abdulaziz/certificate/course/3d5348dc-ed2a-4356-be49-919ece5e2158',
      },
      {
        iconName: 'icon-certificate-1',
        title: 'User Research: Methods & Best Practices',
        contents: ['Interaction Design Foundation', 'Issued Dec 2021'],
        link: 'https://www.interaction-design.org/abdulaziz/certificate/course/9a914fc2-01b6-4273-b89a-431b3f99389a',
      },
      {
        iconName: 'icon-certificate-1',
        title: 'User Experience: The Beginner’s Guide',
        contents: ['Interaction Design Foundation', 'Issued Dec 2021'],
        link: 'https://www.interaction-design.org/abdulaziz/certificate/course/92159443-a954-46dc-b5ab-c3cff2207aae',
      },
      {
        iconName: 'icon-certificate-1',
        title: 'Conducting Usability Testing',
        contents: ['Interaction Design Foundation', 'Issued Dec 2021'],
        link: 'https://www.interaction-design.org/abdulaziz/certificate/course/a8b099e6-8030-4f1d-8eca-3beb732f72db',
      },
      {
        iconName: 'icon-certificate-2',
        title: 'User Experience for Web Design',
        contents: ['LinkedIn', 'Issued Feb 2020'],
        link: 'https://www.linkedin.com/learning/certificates/391fd83c5133ff3a072f350bf2f9608f1e4059bd3a8ab1f266b83fcdf7e13562?trk=share_certificate',
      },
      {
        iconName: 'icon-certificate-2',
        title: 'Responsive Layout',
        contents: ['LinkedIn', 'Issued Feb 2020'],
        link: 'https://www.linkedin.com/learning/certificates/c28484524362270eaf9f9298f8ed982cd117518202e40da7cbd7d0d44064b687?trk=share_certificate',
      },
      {
        iconName: 'icon-certificate-2',
        title: 'Advance Your Skills as a UX Researcher',
        contents: ['LinkedIn', 'Issued Oct 2018'],
        link: 'https://www.linkedin.com/learning/paths/advance-your-skills-as-a-user-experience-researcher',
      },
      {
        iconName: 'icon-certificate-2',
        title: 'UX Research Methods: Card Sorting',
        contents: ['LinkedIn', 'Issued Oct 2018'],
        link: 'https://www.linkedin.com/learning/certificates/0c9e547c3ac5a10bf88faa04043a4f8be06c9de07d1c75bc413de593ef605a40?trk=share_certificate',
      },
      {
        iconName: 'icon-certificate-2',
        title: 'UX Research Methods: Interviewing',
        contents: ['LinkedIn', 'Issued Oct 2018'],
        link: 'https://www.linkedin.com/learning/certificates/4d7ad14f0560908ef9a677628936cbc17105f76113a4043c37a808000654d2ad?trk=share_certificate',
      },
    ],
    VIEW_CERTIFICATE: 'VIEW CERTIFICATE',
  },
  HOBBIES: {
    TITLE: 'Hobbies',
    IMAGES: [importImg('AboutHobbies1.jpg'), importImg('AboutHobbies2.jpg')],
    CONTENTS: [
      'I love football. From the early days of my life, I loved playing football and I always played as a Center Forward. But I became a golly at university and I acheived a lot of good results. We become champions at university twice, won the second place in the International universities championship and also participated in the second leage of Uzbekistan futsal championship.',
      'I enjoy helping young designers. Whenever I have a chance, I try to run masterclasses where I talk about UX, workshops where I try to show how to run UX methods properly. Besides, I also try to share my knowledge with people. I share posts on Linkedin, Instagram (in English and Uzbek), Facebook and Telegram. I also like running hureistic analyses and expert reviews to help others with UX.',
    ],
  },
  LANGUAGES: {
    TITLE: 'Languages',
    COUNTRY: [
      { iconName: 'icon-uz-flag', title: 'Uzbek', subtitle: 'Native' },
      { iconName: 'icon-eng-flag', title: 'English', subtitle: 'Advanced' },
      { iconName: 'icon-rus-flag', title: 'Russian', subtitle: 'Advanced' },
      {
        iconName: 'icon-turk-flag',
        title: 'Turkish',
        subtitle: 'Work Proficient',
      },
    ],
  },
});
