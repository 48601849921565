import { windowResizeChanger } from '../utils';
import { theme } from '.';
import { importImg } from '../images';

export const JOWIOWNERAPP = () => ({
  PAGE: 'jowi-owner-app',
  PATHS: [
    { path: '/portfolio', text: 'Portfolio' },
    { path: '/portfolio/jowiownerapp', text: 'Jowi Owner app design' },
  ],
  HEADER: {
    TITLE: 'Jowi Owner app design',
    SUBTITLE:
      'Designed an app that helps restaurant owners manage their business in a very efficient way.',
    IMAGES: [importImg('JowiOwnerAppHeader.jpg')],
  },
  OVERVIEW: {
    TITLE: 'Overview',
    PROJECT_TIMELINE: 'Project Timeline',
    YEARS: windowResizeChanger({
      desktop: 'May 2020 - Aug 2020',
      tablet: (
        <>
          Start: <b>May 2020</b>
          <br />
          Finish: <b>Aug 2020</b>
        </>
      ),
      mobile: 'May 2020 - Aug 2020',
    }),
    MY_ROLE_TITLE: 'My role',
    MY_ROLE: 'UX designer\nVisual designer',
    CONTEXT_TITLE: 'Context',
    CONTEXT1:
      'Medyear Pro is a web based system that helps healthcare professionals manage digital interactions between them and their patients. The system has been in the market for more than 4 years and was struggling to meet its end users because of ongoing updates.',
    CONTEXT2:
      'I redesigned the Medyear brand and its assets, so Medyear Pro was required to be updated based on the new brand guidelines. However, aftar through analyses of the system and its structre we decided to update the whole system, not just the look.',
    CONTEXT3:
      'I redesigned the system structure and the layout while working closely iwth UX manager, the co-founder, engeneering manager and the developers.',
  },
  PROBLEM: {
    TITLE: 'Problem',
    SUBTITLE:
      'The system was not responsive so it could not be scaled down to fit mobile screens. It resulted in users being dependent on laptops or PCs. Considering the fact that restaurant owners move around all the time, the mobility of the system was a huge deal for them.',
  },
  CONSTRAINTS: {
    TITLE: 'Constraints',
    TOOLS: [
      {
        iconName: 'icon-dollar-box',
        iconColor: theme.colors.green,
        title: 'Limited budget',
        content:
          'The client’s budget was limited so I had to rely on my experience and cheap UX techniques in order to design the app.',
      },
      {
        iconName: 'icon-marker-box',
        iconColor: theme.colors.red,
        title: 'Remote',
        content:
          'It was remote work and I could only rely on zoom calls to discuss the details. Once I had to travel 1000km in order to meet the client for the discussions of the issues I faced.',
      },
      {
        iconName: 'icon-calendar-box',
        iconColor: theme.colors.blue,
        title: 'Tight deadline',
        content:
          'The client wanted to complete the design as soon as possible. Also, it was a side project for me and I had a full-time job that was additional pressure for me.',
      },
    ],
  },
  AUDIENCE: {
    TITLE: 'Target audience',
    COLUMN_MODE: true,
    PERSONS: [
      {
        avatar: importImg('JowiOwnerAppJamshid.jpg'),
        name: 'Jamshid',
        subname: windowResizeChanger({
          desktop: (
            <>
              <span>Restaurant owner</span>
              <span>Tashkent</span>
            </>
          ),
          tablet: 'Restaurant owner | Tashkent',
        }),
        content:
          'Jamshid runs two restaurants in Moscow and in Tashkent. He travels between two locations. He uses the Jowi system to manage his business. He misses important requests coming from the staff because he can access the Jowi system only through his laptop. Most of the time his employees call him directly, otherwise, they have to wait for his response until he sees the notifications.',
        goals: {
          title: 'Goals & Needs',
          tools: [
            'Wants to access the Jowi system through his phone to manage his business.',
            'He needs to be notified when there is a new request from his employees.',
            'He wants to see only the important piece of information that requires his attention.',
          ],
        },
        emotions: {
          title: 'Emotions',
          tools: [
            'He is tired of carrying his laptop while traveling just to be able to access the Jowi system when required.',
            'Frustrated because he keeps missing notifications. Thus, he is pushed to use his cellphone to solve issues.',
          ],
        },
      },
    ],
  },
  PROCESS: {
    TITLE: windowResizeChanger({
      desktop: 'Process',
      tablet: 'Design Process',
    }),
    TOOLS: [
      {
        iconName: 'icon-search-box',
        iconColor: theme.colors.green,
        title: 'Research',
        content: 'I ran stakeholder interviews to get the details I needed.',
      },
      {
        iconName: 'icon-lamp-box',
        iconColor: theme.colors.red,
        title: 'Brainstorm',
        content: 'I brainstormed to find the solutions to the problems.',
      },
      {
        iconName: 'icon-design-box',
        iconColor: theme.colors.blue,
        title: 'Design',
        content: 'I designed a high-fi mockup with prototype.',
      },
      {
        iconName: 'icon-test-box',
        iconColor: theme.colors.yellow,
        title: 'Test',
        content: 'I tested the app with the user from the target audience.',
      },
    ],
  },
  RESEARCH: {
    SECTION: 'research',
    SET_BACKGROUND: true,
    TITLE: 'Research',
    CONTENTS: [
      'My client was a restaurant owner so the app was designed based on his own needs and requirements. It helped a lot to work directly with the user of the app. I ran interview sessions with the client to find out about his needs as much as I could. These were the main things required in the app.',
    ],
    TOOLS: [
      {
        column: windowResizeChanger({
          desktop: '1fr 1fr 1fr 1fr',
          tablet: '1fr',
        }),
        tools: [
          {
            iconName: 'icon-one-box',
            iconColor: theme.colors.red,
            content:
              'The app should be for the shop, restaurant, and pharmacy owners.',
            columnMode: false,
            tabletColumnMode: true,
          },
          {
            iconName: 'icon-two-box',
            iconColor: theme.colors.red,
            content:
              'The app should act as a dashboard of information from one or multiple facilities.',
            columnMode: false,
            tabletColumnMode: true,
          },
          {
            iconName: 'icon-three-box',
            iconColor: theme.colors.red,
            content:
              'It should help the owner manage the requests coming from the staff.',
            columnMode: false,
            tabletColumnMode: true,
          },
          {
            iconName: 'icon-four-box',
            iconColor: theme.colors.red,
            content:
              'The home page should show checks, income, expense, inventory information.',
            columnMode: false,
            tabletColumnMode: true,
          },
        ],
      },
      {
        column: windowResizeChanger({
          desktop: '1fr 1fr 1fr 1fr',
          tablet: '1fr',
        }),
        tools: [
          {
            iconName: 'icon-five-box',
            iconColor: theme.colors.red,
            content:
              'The app should have home, reports, inventory, notifications, and reviews sections.',
            columnMode: false,
            tabletColumnMode: true,
          },
          {
            iconName: 'icon-six-box',
            iconColor: theme.colors.red,
            content:
              'Only sign-in is available in the app. And the owner can use his phone number to sign in.',
            columnMode: false,
            tabletColumnMode: true,
          },
          {
            iconName: 'icon-seven-box',
            iconColor: theme.colors.red,
            content:
              'The owner needs to be able to chat with customers if they leave bad reviews.',
            columnMode: false,
            tabletColumnMode: true,
          },
          {
            iconName: 'icon-eight-box',
            iconColor: theme.colors.red,
            content:
              'It should be launched for iOS and Android platforms with dark mode for both.',
            columnMode: false,
            tabletColumnMode: true,
          },
        ],
      },
    ],
  },
  BRAINSTORMING: {
    SECTION: 'brainstorming',
    TITLE: 'Brainstorming',
    CONTENTS: [
      'Once I received the requirements from the client, I started working on the project. First I wanted to sketch out my ideas, in order to be able to send them to the client, I decided to design a low-fi wireframe.',
    ],
    TOOLS: [
      windowResizeChanger({
        desktop: {
          column: '1fr 1fr 1fr 1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.green,
              content:
                'I decided to use bottom navigation for the important sections so that they are visible all the time. Also, hide secondary sections under the hamburger menu.',
              columnMode: false,
              tabletColumnMode: true,
            },
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.green,
              content:
                'The home page will be divided into three categories: restaurant, shop, and pharmacy. In order to see the details, users need to click on any one of the three cards on the home page.',
              columnMode: false,
              tabletColumnMode: true,
            },
            {
              iconName: 'icon-three-box',
              iconColor: theme.colors.green,
              content:
                'On the facility page, I decided to each section in a separate card to indicate that each section is clickable and leads to the corresponding page of details.',
              columnMode: false,
              tabletColumnMode: true,
            },
            {
              iconName: 'icon-four-box',
              iconColor: theme.colors.green,
              content:
                'In case the owner decided to chat with the customers, he/she can do it directly from the review page, or from the chat page by clicking on the new chat button.',
              columnMode: false,
              tabletColumnMode: true,
            },
          ],
        },
        tablet: {
          column: '1fr 1fr',
          tools: [
            {
              iconName: 'icon-one-box',
              iconColor: theme.colors.green,
              content:
                'I decided to use bottom navigation for the important sections so that they are visible all the time. Also, hide secondary sections under the hamburger menu.',
              columnMode: false,
              tabletColumnMode: true,
              mobileRowMode: true,
            },
            {
              iconName: 'icon-two-box',
              iconColor: theme.colors.green,
              content:
                'The home page will be divided into three categories: restaurant, shop, and pharmacy. In order to see the details, users need to click on any one of the three cards on the home page.',
              columnMode: false,
              tabletColumnMode: true,
              mobileRowMode: true,
            },
          ],
          images: [
            importImg('JowiOwnerAppPhone1.jpg'),
            importImg('JowiOwnerAppPhone2.jpg'),
          ],
        },
      }),
      windowResizeChanger({
        desktop: {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone1.jpg'),
            importImg('JowiOwnerAppPhone2.jpg'),
            importImg('JowiOwnerAppPhone3.jpg'),
            importImg('JowiOwnerAppPhone4.jpg'),
          ],
        },
        tablet: {
          column: '1fr 1fr',
          tools: [
            {
              iconName: 'icon-three-box',
              iconColor: theme.colors.green,
              content:
                'On the facility page, I decided to each section in a separate card to indicate that each section is clickable and leads to the corresponding page of details.',
              columnMode: false,
              tabletColumnMode: true,
              mobileRowMode: true,
            },
            {
              iconName: 'icon-four-box',
              iconColor: theme.colors.green,
              content:
                'In case the owner decided to chat with the customers, he/she can do it directly from the review page, or from the chat page by clicking on the new chat button.',
              columnMode: false,
              tabletColumnMode: true,
              mobileRowMode: true,
            },
          ],
          images: [
            importImg('JowiOwnerAppPhone3.jpg'),
            importImg('JowiOwnerAppPhone4.jpg'),
          ],
        },
      }),
      {
        column: '100%',
        contents: [
          'The client’s feedback was not clear. The discussions lasted for days, but still, I could not understand his points and he could not understand my explanations. As a result, I decided to travel to the capital from my hometown to be able to discuss the matters, face to face. Our discussions progressed and decided to do the followings:',
        ],
      },
      {
        column: windowResizeChanger({ desktop: '1fr 1fr', tablet: '1fr' }),
        tools: [
          {
            iconName: 'icon-one-box',
            iconColor: theme.colors.red,
            title: 'Navigation',
            content:
              'We will use only bottom navigation and we will show only the home report, and inventory sections of the app there. The fourth element will be named “more” which opens a menu with other sections of the app.',
          },
          {
            iconName: 'icon-two-box',
            iconColor: theme.colors.red,
            title: 'No confusing service options',
            content:
              'The current app will be for restaurant owners only. We will remove any indication of two other facilities from the app interface. If there will be a request for them, they will handle things with code in the background.',
          },
        ],
      },
    ],
  },
  DESIGN: {
    SECTION: 'design',
    SET_BACKGROUND: true,
    TITLE: 'Design',
    CONTENTS_ALIGN: 'center',
    CONTENTS: [
      'After my trip to meet the client, the progress went faster. I made some changes to the app and implemented the changes to the hi-fi prototype.',
    ],
    TOOLS: windowResizeChanger({
      desktop: [
        {
          column: '1fr 1fr 1fr 1fr',
          tools: [
            {
              content:
                'The new home page shows today’s bills, income, expenses, goods in the warehouse, money in the cashier, debts both in and out, also the list of restaurants owned by the current owner.',
            },
            {
              content:
                'The bills page shows the bills amount and the list of bills registered by staff members. ',
            },
            {
              content:
                'The bills details page shows details of a particular bill registered by the staff member.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone5.jpg'),
            importImg('JowiOwnerAppPhone6.jpg'),
            importImg('JowiOwnerAppPhone7.jpg'),
            importImg('JowiOwnerAppPhone8.jpg'),
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          tools: [
            {
              content:
                'Reports section provides a list of reports available for the selected restaurant.',
            },
            {
              content:
                'Details page provides detailed information of the selected report.',
            },
            {
              content:
                'Inventory sections provide information about the products in the warehouse.',
            },
            {
              content:
                'The details page shows products inside a category. The product amount can be edited.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone9.jpg'),
            importImg('JowiOwnerAppPhone10.jpg'),
            importImg('JowiOwnerAppPhone11.jpg'),
            importImg('JowiOwnerAppPhone12.jpg'),
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          tools: [
            {
              content:
                'The notifications section shows the activity happening in the app also requests from the staff.',
            },
            {
              content:
                'The details page shows the request details and the owner has can reject and accept.',
            },
            {
              content:
                'The reviews section shows a list of reviews left by the customer of the restaurant.',
            },
            {
              content:
                'The owner has an option to chat with a customer through the app by clicking the chat icon.',
            },
          ],
        },
        {
          column: '1fr 1fr 1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone13.jpg'),
            importImg('JowiOwnerAppPhone14.jpg'),
            importImg('JowiOwnerAppPhone15.jpg'),
            importImg('JowiOwnerAppPhone16.jpg'),
          ],
        },
      ],
      mobile: [
        {
          column: '1fr',
          tools: [
            {
              content:
                'The new home page shows today’s bills, income, expenses, goods in the warehouse, money in the cashier, debts both in and out, also the list of restaurants owned by the current owner.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone5.jpg'),
            importImg('JowiOwnerAppPhone6.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'The bills page shows the bills amount and the list of bills registered by staff members. ',
            },
            {
              content:
                'The bills details page shows details of a particular bill registered by the staff member.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone7.jpg'),
            importImg('JowiOwnerAppPhone8.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'Reports section provides a list of reports available for the selected restaurant.',
            },
            {
              content:
                'Details page provides detailed information of the selected report.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone9.jpg'),
            importImg('JowiOwnerAppPhone10.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'Inventory sections provide information about the products in the warehouse.',
            },
            {
              content:
                'The details page shows products inside a category. The product amount can be edited.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone11.jpg'),
            importImg('JowiOwnerAppPhone12.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'The notifications section shows the activity happening in the app also requests from the staff.',
            },
            {
              content:
                'The details page shows the request details and the owner has can reject and accept.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone13.jpg'),
            importImg('JowiOwnerAppPhone14.jpg'),
          ],
        },
        {
          column: '1fr',
          tools: [
            {
              content:
                'The reviews section shows a list of reviews left by the customer of the restaurant.',
            },
            {
              content:
                'The owner has an option to chat with a customer through the app by clicking the chat icon.',
            },
          ],
        },
        {
          column: '1fr 1fr',
          images: [
            importImg('JowiOwnerAppPhone15.jpg'),
            importImg('JowiOwnerAppPhone16.jpg'),
          ],
        },
      ],
    }),
  },
  TEST: {
    TITLE: 'Test',
    CONTENTS_VARIANT: windowResizeChanger({
      desktop: '24/29',
      tablet: '20/22',
    }),
    CONTENTS: [
      'My client owned two restaurants. The testing part was an advantage, I showed him the app and get his feedback a couple of times. The overall design of the app was good. There were minor updates done in the process.',
    ],
  },
  FINALLOOK: {
    SET_BACKGROUND: true,
    TITLE: 'The final look',
    IMG: importImg('JowiOwnerAppFinalLook.jpg'),
  },
  EXPERIENCE: {
    SET_BACKGROUND: false,
    TITLE: windowResizeChanger({
      desktop: 'The benefits of this experience:',
      mobile: 'Benefits from this experience:',
    }),
    TOOLS: [
      {
        iconName: 'icon-one-box',
        title: 'Working with the end user',
        content:
          'I have never worked with a end user directly in a any project. It was compelling, interesting and tricky at the same time. But overall experience was very good.',
      },
      {
        iconName: 'icon-two-box',
        title: 'Turning point',
        content:
          'This project was the turning point with the client. Because with this project I was offered to work on 6 other apps in the near future.',
      },
      {
        iconName: 'icon-three-box',
        title: 'Centralising the assets',
        content:
          'I started the design system of the Jowi projects while working on Jowi Owner app. It helped me a lot while I designed Jowi Shop, Jowi Health and Jowi Club apps. I finished those apps a lot faster than planned.',
      },
      {
        iconName: 'icon-four-box',
        title: 'Meeting clients face to face helps a lot',
        content:
          'I had to travel over 600 miles to meet the client in order to clarify misunderstandings. This decision helped me understand the points I missed, also explain the things the client could not understand.',
      },
      {
        iconName: 'icon-five-box',
        title: 'Facing my fears',
        content:
          'I could not understand some of the requirements listed by the client as I had never worked on a project like this before. When our discussions went into a dead end, I was afraid that I could fail the project. However, I faced my fears and met the client to progress the discussions.',
      },
      {
        iconName: 'icon-six-box',
        title: 'Explored new challenges',
        content:
          'I was aware that there will be several interconnected projects under Jowi brand. During the design process I had to consider that fact as well and rely on simple, clean and beatiful layout in order to be consistent in all other projects I worked on.',
      },
    ],
  },
});
