import styled, { css } from 'styled-components';
import { Typography, media, sizes } from '../../../components';
import { theme } from '../../../const';

const ColorBoxBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 1rem;

  padding: 1.5rem;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows[18]};
  border-radius: 0.5rem;

  ${(props) =>
    props.noBorder &&
    css`
      padding: 0;
      background-color: initial;
      box-shadow: none;
      border-radius: 0;

      ${media.tablet`
        grid-row-gap: 0.5rem;
      `}
    `}

  ${media.mobile`
    grid-row-gap: 0.5rem;
    padding: 1rem;

    ${(props) =>
      props.noBorder &&
      css`
        grid-template-columns: max-content 1fr;
        grid-column-gap: 1.5rem;
        padding: 0;
        background-color: initial;
        box-shadow: none;
        border-radius: 0;
        grid-row-gap: 0.2rem;
      `}
  `}
`;

const Color = styled.div`
  width: 100%;
  height: 10rem;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;

  ${(props) => css`
    background-color: ${props.color};
  `}

  ${(props) =>
    props.noBorder &&
    css`
      height: 8rem;
      margin-bottom: 0;

      ${media.tablet`
        height: 6rem;
        margin-bottom: 0.5rem;
      `}
    `}

  ${(props) =>
    props.border &&
    css`
      border: ${props.border};
    `}

  ${media.mobile`
    height: 8rem;

    ${(props) =>
      props.noBorder &&
      css`
        grid-area: 1 / 1 / 3 / 2;
        width: 8rem;
        height: 4rem;
        margin-bottom: 0;
      `}
  `}
`;

const ColorContent = styled(Typography)`
  @media (max-width: ${sizes.mobile}px) {
    line-height: ${theme.font.lineHeight16};
  }
`;

export const ColorBox = ({ color, border, title, content, noBorder }) => {
  return (
    <ColorBoxBase noBorder={noBorder}>
      <Color color={color} border={border} noBorder={noBorder} />
      <Typography variant={'24/29'} weight={theme.font.weightBold} nowrap>
        {title}
      </Typography>
      {content && (
        <ColorContent
          variant={'20/28'}
          color={noBorder ? theme.colors.darkBlue : theme.colors.gray}
        >
          {content}
        </ColorContent>
      )}
    </ColorBoxBase>
  );
};
