import { useCallback } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Container, Typography, IconSVG, media, sizes } from '../../components';
import { theme, BLOGS } from '../../const';

const BlogsBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 3rem;
  padding: 10rem 0;

  ${media.tablet`
    padding: 3rem 0;
  `}

  ${media.mobile`
    grid-row-gap: 1.5rem;
    padding: 1.5rem 0;
  `}
`;

const BlogsBackground = css`
  background-color: ${theme.colors.light};
`;

const BlogCard = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 1rem;
  align-content: center;

  min-height: 36rem;
  position: relative;
  overflow: hidden;
  padding: 3rem 60rem 3rem 3rem;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows[0]};
  border-radius: 0.5rem;

  ${media.tablet`
    grid-template-rows: repeat(2, max-content) 1fr max-content;
    min-height: 24rem;
    padding: 3rem 41rem 3rem 3rem;
  `}

  ${media.mobile`
    grid-template-rows: repeat(3, max-content);
    min-height: initial;
    padding: 0 1.5rem 1rem; 
  `}
`;

const BlogTitle = styled(Typography)`
  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size16};
    line-height: ${theme.font.lineHeight19};
    color: ${theme.colors.blue};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const BlogContent = styled(Typography)`
  ${media.tablet`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: initial;
  `}

  ${media.mobile`
    display: none;
  `}
`;

const BlogCreated = styled(Typography)`
  ${media.mobile`
    margin-top: -0.6rem;
  `}
`;

const BlogImg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 57rem;
  height: 100%;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.img});

  ${media.tablet`
    width: 38rem;
  `}

  ${media.mobile`
    position: initial;
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    padding-bottom: calc((18.4 / 30) * 100%);
  `}
`;

const BlogPostLink = styled(Link)`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  width: max-content;
  margin-top: 2rem;
  text-decoration: none;
  grid-column-gap: 0.4rem;

  color: ${theme.colors.blue};
  font-size: ${theme.font.size14};
  font-weight: ${theme.font.weight600};
  line-height: ${theme.font.lineHeight16};
  letter-spacing: ${theme.font.letterSpacing007};

  ${media.mobile`
    display: none;
  `}
`;

const BlogPostLinkIcon = styled(IconSVG)`
  width: 1.6rem;
  height: 1.2rem;
  transition: margin 0.3s;

  ${BlogPostLink}:hover > & {
    margin-left: 1rem;
  }
`;

export const Blogs = () => {
  const historyPush = useNavigate();

  const handleTitleClick = useCallback(
    (link) => {
      if (sizes.mobile >= window.innerWidth) {
        historyPush(link);
      }
    },
    [historyPush]
  );

  return (
    <BlogsBase backgroundCSS={BlogsBackground}>
      {BLOGS().map(({ img, title, content, createdAt, link, linkText }, i) => (
        <BlogCard key={i}>
          <BlogImg img={img} />
          <BlogTitle
            variant={'24/29'}
            weight={theme.font.weightBold}
            onClick={() => handleTitleClick(link)}
          >
            {title}
          </BlogTitle>
          <BlogContent variant={'20/28'}>{content}</BlogContent>
          <BlogCreated variant={'14/16'}>{createdAt}</BlogCreated>
          <BlogPostLink to={link}>
            {linkText}
            <BlogPostLinkIcon iconName={'icon-blue-right-arrow2'} />
          </BlogPostLink>
        </BlogCard>
      ))}
    </BlogsBase>
  );
};
