import { Container } from '../../components';
import {
  PathNavigation,
  ScrollToUpButton,
  Header,
  Overview,
  Problem,
  Constraints,
  Audience,
  Process,
  FinalLook,
  Experience,
  Projects,
  AdvancedSection,
} from '../components';
import { Research } from './Research';
import { MEDYEARPRO } from '../../const';

export const MedyearPro = () => {
  return (
    <>
      <PathNavigation paths={MEDYEARPRO().PATHS} />
      <ScrollToUpButton />
      <Container main>
        <Header PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().HEADER} />
        <Overview PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().OVERVIEW} />
        <Problem PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().PROBLEM} />
        <Constraints PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().CONSTRAINTS} />
        <Audience PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().AUDIENCE} />
        <Process PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().PROCESS} />
        <Research PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().RESEARCH} />
        <AdvancedSection
          PAGE={MEDYEARPRO().PAGE}
          {...MEDYEARPRO().BRAINSTORMING}
        />
        <AdvancedSection PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().TEST} />
        <FinalLook PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().FINALLOOK} />
        <Experience PAGE={MEDYEARPRO().PAGE} {...MEDYEARPRO().EXPERIENCE} />
        <Projects showProjects={[0, 2]} otherProjects />
      </Container>
    </>
  );
};
