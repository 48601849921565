import styled, { css } from 'styled-components';
import { Typography, media } from '../../../components';

const AdvancedContentsBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 2rem;
  justify-self: center;
  max-width: 57.5rem;

  ${(props) =>
    props.page === 'driver-app' &&
    (props.section === 'research' || props.section === 'not-work') &&
    css`
      grid-row-gap: 1rem;
    `}

  ${(props) =>
    props.page === 'driver-app' &&
    (props.section === 'test' || props.section === 'not-work') &&
    media.tablet`
      grid-row-gap: 1.5rem;
    `}

  ${(props) =>
    props.page === 'customer-app' &&
    props.section === 'research' &&
    css`
      max-width: 77.5rem;
    `}

  ${(props) =>
    props.page === 'customer-app' &&
    props.section === 'design' &&
    css`
      max-width: 60rem;
    `}

  ${(props) =>
    props.page === 'jowi-owner-app' &&
    props.section === 'design' &&
    css`
      max-width: 77.5rem;
    `}
    
  ${(props) =>
    props.page === 'personal-logo' &&
    props.section === 'research' &&
    css`
      max-width: 100%;
    `}

  ${media.tablet`
    max-width: 52.7rem;

    ${(props) =>
      props.page === 'driver-app' &&
      (props.section === 'test' || props.section === 'not-work') &&
      css`
        grid-row-gap: 1.5rem;
      `}

    ${(props) =>
      props.page === 'customer-app' &&
      props.section === 'test' &&
      css`
        grid-row-gap: 1.5rem;
      `}

    ${(props) =>
      props.page === 'personal-website' &&
      props.section === 'discovered' &&
      css`
        & > * {
          text-align: left;
        }
      `}

    ${(props) =>
      props.page === 'jowi-owner-app' &&
      props.section === 'design' &&
      css`
        & > * {
          text-align: left;
        }
      `}
  `}

  ${media.mobile`
    grid-row-gap: 1rem;
  `}
`;

export const AdvancedContents = ({
  PAGE,
  SECTION,
  CONTENTS,
  CONTENTS_VARIANT,
  CONTENTS_ALIGN,
  children,
}) => {
  return (
    <AdvancedContentsBase page={PAGE} section={SECTION}>
      {CONTENTS &&
        CONTENTS.map((content, i) => (
          <Typography
            key={i + 'content'}
            variant={CONTENTS_VARIANT ? CONTENTS_VARIANT : '20/22'}
            align={CONTENTS_ALIGN}
          >
            {content}
          </Typography>
        ))}
      {children}
    </AdvancedContentsBase>
  );
};
