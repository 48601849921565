import styled from 'styled-components';
import { Typography, media } from '../../../components';
import { theme } from '../../../const';

const OverviewBase = styled.div`
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 5rem;
  justify-items: center;

  ${media.tablet`
    grid-row-gap: 3rem;
  `}

  ${media.mobile`
    grid-row-gap: 2rem;
  `}
`;

const Main = styled.div`
  display: grid;
  grid-template-columns: minmax(max-content, 35rem) minmax(min-content, 77rem);
  grid-template-rows: max-content 1fr;
  grid-gap: 5rem;
  width: 100%;

  & > *:nth-child(3) {
    grid-area: 1 / 2 / 3 / 3;
  }

  ${media.tablet`
    grid-template-columns: minmax(max-content, 1fr) minmax(min-content, 77rem);
    grid-gap: 3rem;
  `}

  ${media.mobile`
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, max-content);
    grid-gap: 2rem;

    & > *:nth-child(3) {
      grid-area: 2 / 1 / 3 / 3;
    }
  `}
`;

const InfoBase = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-row-gap: 1rem;

  & > *:nth-child(n + 3) {
    margin-top: 1rem;
  }

  ${media.tablet`
    & > *:nth-child(n + 3) {
      margin-top: 0.5rem;
    }
  `}

  ${media.mobile`
    grid-row-gap: 0.4rem;

    & > *:nth-child(n + 3) {
      margin-top: 0.6rem;
    }
  `}
`;

const Info = ({ title, children }) => {
  return (
    <InfoBase>
      <Typography variant={'24/29'} weight={theme.font.weightBold}>
        {title}
      </Typography>
      {children}
    </InfoBase>
  );
};

export const Overview = ({
  PAGE,
  TITLE,
  PROJECT_TIMELINE,
  YEARS,
  MY_ROLE_TITLE,
  MY_ROLE,
  CONTEXT_TITLE,
  CONTEXT1,
  CONTEXT2,
  CONTEXT3,
}) => {
  return (
    <OverviewBase>
      <Typography variant={'40/48'} weight={theme.font.weight600}>
        {TITLE}
      </Typography>
      <Main>
        <Info title={PROJECT_TIMELINE}>
          <Typography variant={'20/28'}>{YEARS}</Typography>
        </Info>
        <Info title={MY_ROLE_TITLE}>
          <Typography variant={'20/28'}>{MY_ROLE}</Typography>
        </Info>
        <Info title={CONTEXT_TITLE}>
          <Typography variant={'20/28'}>{CONTEXT1}</Typography>
          <Typography variant={'20/28'}>{CONTEXT2}</Typography>
          <Typography variant={'20/28'}>{CONTEXT3}</Typography>
        </Info>
      </Main>
    </OverviewBase>
  );
};
