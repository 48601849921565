import styled, { css } from 'styled-components';
import { Container, Typography, media, sizes } from '../../../components';
import { theme } from '../../../const';

const ProblemBase = styled(Container)`
  display: grid;
  grid-template-rows: repeat(2, max-content);
  grid-row-gap: 3rem;
  justify-items: center;
  width: 100%;

  ${media.tablet`
    grid-row-gap: 1rem;
  `}
`;

const ProblemBackground = css`
  background-color: ${theme.colors.light};
`;

const ProblemSubtitle = styled(Typography)`
  ${(props) =>
    props.page === 'medypass' &&
    css`
      max-width: 64.5rem;
      text-align: center;

      ${media.tablet`
        max-width: 53.7rem;
        text-align: left;
      `}
    `}

  ${(props) =>
    props.page === 'medyear-pro' &&
    css`
      max-width: 57.5rem;

      ${media.tablet`
        max-width: 53.4rem;
      `}
    `}

  ${(props) =>
    props.page === 'driver-app' &&
    css`
      max-width: 57.5rem;

      ${media.tablet`
        max-width: 53.4rem;
      `}
    `}
    
  ${(props) =>
    props.page === 'personal-website' &&
    css`
      max-width: 56.7rem;
      text-align: center;

      ${media.tablet`
        max-width: 57.5rem;
        text-align: left;
        margin-top: 2rem;
      `}
    `}
    
  ${(props) =>
    props.page === 'jowi-owner-app' &&
    css`
      max-width: 57.5rem;

      ${media.tablet`
        max-width: 52.7rem;
      `}
    `}

  @media (max-width: ${sizes.tablet}px) {
    font-weight: ${theme.font.weightMedium};
  }

  @media (max-width: ${sizes.mobile}px) {
    font-size: ${theme.font.size20};
    line-height: ${theme.font.lineHeight24};

    ${(props) =>
      props.page === 'personal-website' &&
      css`
        margin-top: 0;
      `}
  }
`;

export const Problem = ({ PAGE, TITLE, SUBTITLE }) => {
  return (
    <ProblemBase backgroundCSS={ProblemBackground}>
      <Typography variant={'40/48'} weight={theme.font.weight600}>
        {TITLE}
      </Typography>
      <ProblemSubtitle
        variant={'24/29'}
        weight={theme.font.weightBold}
        page={PAGE}
      >
        {SUBTITLE}
      </ProblemSubtitle>
    </ProblemBase>
  );
};
