import { Container } from '../../components';
import {
  PathNavigation,
  ScrollToUpButton,
  Header,
  Overview,
  Problem,
  Constraints,
  Audience,
  FinalLook,
  Experience,
  Projects,
  AdvancedSection,
} from '../components';
import { Competitors } from './Competitors';
import { Brainstorm } from './Brainstorm';
import { Wireframe } from './Wireframe';
import { PERSONAL_WEBSITE } from '../../const';

export const PersonalWebsite = () => {
  return (
    <>
      <PathNavigation paths={PERSONAL_WEBSITE().PATHS} />
      <ScrollToUpButton />
      <Container main>
        <Header PAGE={PERSONAL_WEBSITE().PAGE} {...PERSONAL_WEBSITE().HEADER} />
        <Overview
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().OVERVIEW}
        />
        <Problem
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().PROBLEM}
        />
        <Constraints
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().CONSTRAINTS}
        />
        <AdvancedSection
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().PROCESS}
        />
        <Competitors
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().COMPETITORS}
        />
        <Audience
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().AUDIENCE}
        />
        <Brainstorm
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().BRAINSTORM}
        />
        <Wireframe
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().WIREFRAME}
        />
        <AdvancedSection
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().DISCOVERED}
        />
        <FinalLook
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().FINALLOOK}
        />
        <Experience
          PAGE={PERSONAL_WEBSITE().PAGE}
          {...PERSONAL_WEBSITE().EXPERIENCE}
        />
        <Projects
          PAGE={PERSONAL_WEBSITE().PAGE}
          showProjects={[3, 5]}
          otherProjects
        />
      </Container>
    </>
  );
};
