import { Container } from '../../components';
import {
  PathNavigation,
  ScrollToUpButton,
  Header,
  Overview,
  Constraints,
  Audience,
  Process,
  FinalLook,
  Experience,
  Projects,
  AdvancedSection,
} from '../components';
import { DesignColorUniqueRows } from './DesignColorUniqueRows';
import { PERSONALLOGO } from '../../const';

export const PersonalLogo = () => {
  return (
    <>
      <PathNavigation paths={PERSONALLOGO().PATHS} />
      <ScrollToUpButton />
      <Container main>
        <Header PAGE={PERSONALLOGO().PAGE} {...PERSONALLOGO().HEADER} />
        <Overview PAGE={PERSONALLOGO().PAGE} {...PERSONALLOGO().OVERVIEW} />
        <AdvancedSection
          PAGE={PERSONALLOGO().PAGE}
          {...PERSONALLOGO().PROBLEM}
        />
        <Constraints
          PAGE={PERSONALLOGO().PAGE}
          {...PERSONALLOGO().CONSTRAINTS}
        />
        <Audience PAGE={PERSONALLOGO().PAGE} {...PERSONALLOGO().AUDIENCE} />
        <Process PAGE={PERSONALLOGO().PAGE} {...PERSONALLOGO().PROCESS} />
        <AdvancedSection
          PAGE={PERSONALLOGO().PAGE}
          {...PERSONALLOGO().RESEARCH}
        />
        <AdvancedSection
          PAGE={PERSONALLOGO().PAGE}
          {...PERSONALLOGO().BRAINSTORM}
        />
        <AdvancedSection
          PAGE={PERSONALLOGO().PAGE}
          {...PERSONALLOGO().DESIGN_SKETCHES}
        />
        <AdvancedSection
          PAGE={PERSONALLOGO().PAGE}
          {...PERSONALLOGO().DESIGN_CONCEPT}
        />
        <AdvancedSection
          PAGE={PERSONALLOGO().PAGE}
          {...PERSONALLOGO().DESIGN_TEST}
        />
        <AdvancedSection
          PAGE={PERSONALLOGO().PAGE}
          UNIQUE_ROWS={DesignColorUniqueRows()}
          {...PERSONALLOGO().DESING_COLOR}
        />
        <AdvancedSection
          PAGE={PERSONALLOGO().PAGE}
          {...PERSONALLOGO().DESIGN_ACCESSIBILITY}
        />
        <FinalLook PAGE={PERSONALLOGO().PAGE} {...PERSONALLOGO().FINALLOOK} />
        <Experience PAGE={PERSONALLOGO().PAGE} {...PERSONALLOGO().EXPERIENCE} />
        <Projects
          PAGE={PERSONALLOGO().PAGE}
          showProjects={[5, 7]}
          otherProjects
        />
      </Container>
    </>
  );
};
