import styled, { css } from 'styled-components';
import { ToolBox } from '../../components';
import { Container, Typography, media, sizes } from '../../../components';
import { theme } from '../../../const';

const ExperienceBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 5rem;

  ${media.tablet`
    grid-row-gap: 3rem;
  `}

  ${media.mobile`
    grid-row-gap: 2rem;
  `}
`;

const ExperienceBackground = css`
  background-color: ${theme.colors.light};
`;

const ToolBoxes = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: max-content;
  grid-column-gap: 3rem;
  grid-row-gap: 5rem;

  ${media.tablet`
    grid-template-columns: 1fr;
    grid-row-gap: 3rem;
  `}

  ${media.mobile`
    grid-row-gap: 2rem;
  `}
`;

const ExperienceNote = styled(Typography)`
  ${(props) =>
    props.page === 'customer-app' &&
    css`
      max-width: 104rem;
    `}

  @media (max-width: ${sizes.tablet}px) {
    ${(props) =>
      props.page === 'customer-app' &&
      css`
        display: initial;
        line-height: 2.25rem;
      `}
  }

  @media (max-width: ${sizes.mobile}px) {
    ${(props) =>
      props.page === 'customer-app' &&
      css`
        line-height: ${theme.font.lineHeight18};
      `}
  }
`;

export const Experience = ({
  PAGE,
  SET_BACKGROUND = true,
  TITLE,
  TOOLS,
  NOTE,
}) => {
  return (
    <ExperienceBase backgroundCSS={SET_BACKGROUND && ExperienceBackground}>
      <Typography
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </Typography>
      <ToolBoxes>
        {TOOLS.map(({ iconName, title, content }, i) => (
          <ToolBox
            key={i}
            iconName={iconName}
            iconColor={theme.colors.green}
            title={title}
            content={content}
            columnMode
          />
        ))}
      </ToolBoxes>
      {NOTE && (
        <ExperienceNote
          variant={'20/28'}
          weight={theme.font.weightBold}
          page={PAGE}
          note
        >
          {NOTE}
        </ExperienceNote>
      )}
    </ExperienceBase>
  );
};
