import styled from 'styled-components';
import { Container, Typography, media } from '../../components';
import { theme } from '../../const';

const PlanBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 2rem;
  width: 57rem;

  & > *:nth-child(2) {
    margin-bottom: 3rem;
  }

  ${media.tablet`
    grid-row-gap: 1.5rem;
    width: 100%;

    & > *:nth-child(2) {
      margin-bottom: 1.5rem;
    }
  `}

  ${media.mobile`
    grid-row-gap: 1rem;

    & > *:nth-child(2) {
      margin-bottom: 1rem;
    }
  `}
`;

const PlanItems = styled.div`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  grid-row-gap: 1rem;
`;

export const Plan = ({
  PAGE,
  TITLE,
  CONTENT1,
  CONTENT2,
  CONTENT3,
  ITEM1,
  ITEM2,
  ITEM3,
  ITEM4,
  ITEM5,
}) => {
  return (
    <PlanBase backgroundLight>
      <Typography
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </Typography>
      <Typography variant={'20/28'}>{CONTENT1}</Typography>
      <Typography variant={'20/28'}>{CONTENT2}</Typography>
      <Typography variant={'20/28'}>{CONTENT3}</Typography>
      <PlanItems>
        <Typography variant={'20/28'} listItem>
          {ITEM1}
        </Typography>
        <Typography variant={'20/28'} listItem>
          {ITEM2}
        </Typography>
        <Typography variant={'20/28'} listItem>
          {ITEM3}
        </Typography>
        <Typography variant={'20/28'} listItem>
          {ITEM4}
        </Typography>
        <Typography variant={'20/28'} listItem>
          {ITEM5}
        </Typography>
      </PlanItems>
    </PlanBase>
  );
};
