import styled, { css } from 'styled-components';
import { Container, Slider, Typography, media } from '../../components';
import { theme } from '../../const';

const WireframeBase = styled(Container)`
  display: grid;
  grid-auto-rows: max-content;
  grid-auto-flow: row;
  justify-items: center;
`;

const WireframeBackground = css`
  background-color: ${theme.colors.light};
`;

const WireframeSubtitle = styled(Typography)`
  max-width: 55rem;
  margin: 1rem 0 5rem;

  ${media.tablet`
    max-width: 48.7rem;
    margin-bottom: 3rem;
  `}

  ${media.mobile`
    margin-bottom: 2rem;
  `}
`;

const WireframeSlider = styled(Slider)`
  justify-self: left;

  ${media.desktop`
    grid-auto-columns: 90rem;
    width: 90rem;
  `}

  ${media.tablet`
    grid-auto-columns: 60.7rem;
    width: 60.7rem;
  `}

  ${media.mobile`
    grid-auto-columns: 32.1rem;
    width: 32.1rem;
  `}
`;

const WireframeImage = styled.img`
  width: 87rem;
  margin-right: 3rem;

  ${media.tablet`
    width: 57.7rem;
  `}

  ${media.mobile`
    width: 30.6rem;
    margin-right: 1.5rem;
  `}
`;

export const Wireframe = ({ PAGE, TITLE, CONTENT, IMAGES, LINK_BUTTON }) => {
  return (
    <WireframeBase backgroundCSS={WireframeBackground} overlay>
      <Typography
        variant={'40/48'}
        weight={theme.font.weight600}
        align={'center'}
      >
        {TITLE}
      </Typography>
      <WireframeSubtitle variant={'20/28'} align={'center'}>
        {CONTENT}
      </WireframeSubtitle>
      <WireframeSlider length={5}>
        {IMAGES.map((img, i) => (
          <WireframeImage key={i} src={img} alt={'Wireframe'} />
        ))}
      </WireframeSlider>
    </WireframeBase>
  );
};
