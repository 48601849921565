import { Container } from '../../components';
import {
  PathNavigation,
  ScrollToUpButton,
  Header,
  Overview,
  Constraints,
  Audience,
  Process,
  FinalLook,
  Experience,
  Projects,
  AdvancedSection,
} from '../components';
import { CUSTOMERAPP } from '../../const';

export const CustomerApp = () => {
  return (
    <>
      <PathNavigation paths={CUSTOMERAPP().PATHS} />
      <ScrollToUpButton />
      <Container main>
        <Header PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().HEADER} />
        <Overview PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().OVERVIEW} />
        <AdvancedSection PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().PROBLEM} />
        <Constraints PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().CONSTRAINTS} />
        <Audience PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().AUDIENCE} />
        <Process PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().PROCESS} />
        <AdvancedSection
          PAGE={CUSTOMERAPP().PAGE}
          {...CUSTOMERAPP().RESEARCH}
        />
        <AdvancedSection
          PAGE={CUSTOMERAPP().PAGE}
          {...CUSTOMERAPP().BRAINSTORMING}
        />
        <AdvancedSection PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().DESIGN} />
        <AdvancedSection PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().TEST} />
        <FinalLook PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().FINALLOOK} />
        <Experience PAGE={CUSTOMERAPP().PAGE} {...CUSTOMERAPP().EXPERIENCE} />
        <Projects showProjects={[2, 4]} otherProjects />
      </Container>
    </>
  );
};
