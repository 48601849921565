import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Navigate,
} from 'react-router-dom';
import {
  Portfolio,
  Medypass,
  MedyearPro,
  DriverApp,
  CustomerApp,
  PersonalWebsite,
  JowiOwnerApp,
  PersonalLogo,
  BrandingProjects,
  About,
  Blog,
  BlogDetails,
  Error,
} from '../pages';
import { ReloadRouterOnResize } from './ReloadRouterOnResize';
import { ScrollToTop } from './ScrollToTop';

export const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <ReloadRouterOnResize>
        {() => (
          <Switch>
            <Route exact path={'/portfolio'} element={<Portfolio />} />
            <Route exact path={'/portfolio/medypass'} element={<Medypass />} />
            <Route
              exact
              path={'/portfolio/medyearpro'}
              element={<MedyearPro />}
            />
            <Route
              exact
              path={'/portfolio/driverapp'}
              element={<DriverApp />}
            />
            <Route
              exact
              path={'/portfolio/customerapp'}
              element={<CustomerApp />}
            />
            <Route
              exact
              path={'/portfolio/personalwebsite'}
              element={<PersonalWebsite />}
            />
            <Route
              exact
              path={'/portfolio/jowiownerapp'}
              element={<JowiOwnerApp />}
            />
            <Route
              exact
              path={'/portfolio/personallogo'}
              element={<PersonalLogo />}
            />
            <Route
              exact
              path={'/portfolio/brandingprojects'}
              element={<BrandingProjects />}
            />
            <Route exact path={'/about'} element={<About />} />
            <Route exact path={'/blog'} element={<Blog />} />
            <Route exact path={'/blog/:id'} element={<BlogDetails />} />
            <Route exact path={'/error'} element={<Error />} />
            <Route
              exact
              path="/"
              element={<Navigate to="/portfolio" replace />}
            />
            <Route path="*" element={<Navigate to="/error" replace />} />
          </Switch>
        )}
      </ReloadRouterOnResize>
    </Router>
  );
};
